import React, { useCallback, useEffect, useState, } from 'react';
import { has } from 'lodash-es'
import { useBlocker, useNavigate } from "react-router-dom";
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Formik, Field as FormikField, FastField as FormikFastField, FieldArray, Form as FormikForm, useFormikContext, getIn, setIn } from 'formik';
import { CheckboxWithLabel, TextField, fieldToTextField } from 'formik-mui';

import ColorPicker from '../ColorPicker'
import SelectWithOther from '../SelectWithOther';
// @ts-ignore: has no exported member 'FilePicker'
import { FilePicker } from 'react-file-picker';
import RichTextField from '../UI/RichTextField'

import { toCamelCase, dashToUnderscore } from '../../lib/utils';
import { useAfterEffect } from '../../lib/hooks';

import { saveWithPayload, getRemoteId, isNew, remote, store } from '../../lib/DataModel'
import { camelize, singularize, underscore } from '@orbit/serializers'
import COLORS from '../../lib/colors'
import { useNotifications } from 'lib/NotificationsProvider';


const useStyles = makeStyles(theme => ({
  container: {
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  row: {
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  column: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  filePickerMessage: {
    fontSize: 10,
  },
  filePickerError: {
    fontSize: 10,
    color: 'red',
    fontWeight: 700,
  },
  textField: {
  },
}));

const getWhichFormikField = function(useFastTextField) {
  if(useFastTextField === false) {
    return FormikField;
  } else {
    return FormikFastField;
  }
}

export function buildRelatedResourceURL(parent, child) {
  const urlBuilder = remote.requestProcessor.urlBuilder

  return [
    urlBuilder.resourceURL(parent.type, parent.id),
    urlBuilder.resourcePath(child.type, child.id),
  ].join('/')
}

// Prevent "`value` prop on `input` should not be null" error by changing null or undefined initial value to empty string (or false, for the case of checkboxes)
export function DefaultInputValueHandler({ innerComponent : Component, field, defaultValue = '', ...props }) {
  if(field.value === undefined || field.value === null) {
    field = { ...field, value: defaultValue }

    if(has(field, 'checked')) {
      field = { ...field, checked: defaultValue }
    }
  }

  return <Component field={field} {...props} />;
}

const filterHackIds = obj => {
  if(obj instanceof Array) {
    return obj.map(child => filterHackIds(child))
  } else if(obj && typeof(obj) === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([k,_v]) => k !== '__id' && k !== '__record')
        .map(([k,v]) => [k, filterHackIds(v)])
    )
  } else {
    return obj
  }
}

const getNestedValuePath = (values, setFieldValue, path, record) => {
  const pathParts = path.split('.')
  const records = []
  records[pathParts.length-1] = record

  for(let i=pathParts.length-2; i>= 0; --i) {
    const pathPart = pathParts[i]
    const associatedType = camelize(singularize(pathPart))
    records[i] = isNew(records[i+1]) ? getIn(records[i+1], `relationships.${associatedType}.data`) : store.cache.query(q => q.findRelatedRecord(records[i+1], associatedType))
  }

  return pathParts.reduce((parentPath, pathPart, index) => {
    const attrPathPart = `${underscore(pathPart)}_attributes`
    const record = records[index]
    if(!record) {
      return `${parentPath}.${attrPathPart}`
    }
    const existingRecords = getIn(values, `${parentPath}.${attrPathPart}`, [])
    let indexInExisting = existingRecords.findIndex(r => r.__id === record.id)
    if(indexInExisting === -1) {
      indexInExisting = existingRecords.length
      setFieldValue(`${parentPath}.${attrPathPart}[${indexInExisting}]`, { __id: record.id, id: getRemoteId(record) })
    }
    return `${parentPath}.${attrPathPart}[${indexInExisting}]`
  }, '_nested')
}

const serializeNestedRecord = (record, options = {}) => {
  const attributes = Object.entries(record.attributes)
    .filter(([attribute]) => !options.attributes || options.attributes.includes(attribute))
  const relationships = Object.entries(record.relationships || {})
    .filter(([relationship]) => options.relationships && options.relationships.includes(relationship))
    .map(([relationship]) => [relationship, isNew(record) ? getIn(record, `relationships.${relationship}.data`) : store.cache.query(q => q.findRelatedRecord(record, relationship))])
  const hasManyRelationships = Object.entries(record.relationships || {})
    .filter(([relationship]) => options.hasManyRelationships && options.hasManyRelationships.includes(relationship))
    .map(([relationship]) => [relationship, (isNew(record) ? getIn(record, `relationships.${relationship}.data`) : store.cache.query(q => q.findRelatedRecords(record, relationship))).map(record => record.id)])

  const remoteId = getRemoteId(record)

  return {
    __id: record.id,
    __record: record,
    id: remoteId,
    ...Object.fromEntries(attributes.map(([attribute, value]) => [underscore(attribute), value])),
    ...Object.fromEntries(relationships.map(([relationship, relatedRecord]) => [`${underscore(relationship)}_id`, relatedRecord && getRemoteId(relatedRecord)])),
    ...Object.fromEntries(hasManyRelationships.map(([relationship, relatedRecords]) => [`${underscore(singularize(relationship))}_ids`, relatedRecords])),
  }
}

const createSetNestedValue = ({values, setFieldValue}) => (path, record, options) => {
  const nestedPath = getNestedValuePath(values, setFieldValue, path, record)
  const existingData = {...getIn(values, nestedPath, {})}
  delete existingData._destroy
  setFieldValue(nestedPath, {...existingData, ...serializeNestedRecord(record, options)})
}

const createDeleteNestedValue = ({values, setFieldValue}) => (path, record) => {
  setFieldValue(getNestedValuePath(values, setFieldValue, path, record), { __id: record.id, id: getRemoteId(record), _destroy: true })
}


// Call `resetForm()` if any of the elements of `watches` changes
function FormResetter({watches, resetForm}) {
  useAfterEffect(() => {
    resetForm()
  }, watches)

  return null
}

export function Prompt({when: isBlocked, message}) {
  const blocker = useBlocker(isBlocked)

  useEffect(() => {
    if(blocker.state === 'blocked') {
      if(isBlocked) {
        if(confirm(message)) {
          blocker.proceed()
        } else {
          blocker.reset()
        }
      } else {
        blocker.reset()
      }
    }
  }, [blocker, isBlocked, message])

  return null
}

const Form = function(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addNotification } = useNotifications()
  const [locationOnNextRender, setLocationOnNextRender] = useState(null)

  useEffect(() => {
    if(locationOnNextRender) {
      setLocationOnNextRender(null)
      navigate(locationOnNextRender[0], { state: locationOnNextRender[1], replace: true })
    }
  }, [navigate, locationOnNextRender])

  const { resource, setResource, relatedResource, customSave, initialValues, relationshipIds, resetOnChange, withoutSubmitButton, validationSchema, validate, backwardsCompatibleMargins = false } = props;

  const relationshipAttributes = {}
  if(relationshipIds && relationshipIds.length) {
    relationshipIds.forEach(relationshipId => {
      const associatedType = relationshipId.replace(/Id$/, '')
      relationshipAttributes[relationshipId] = getIn(resource, `relationships.${associatedType}.data.id`)
    })
  }

  const handleSubmit = ({setResource}) => (values, {setFieldError, resetForm}) => {
    try {
      const result = saveRecord(values, setResource, resetForm);
      if(result instanceof Promise) {
        return result.catch(e => {
          e.data && e.data.errors && e.data.errors.forEach(error => {
            const matches = error.source && error.source.pointer && error.source.pointer.match(/^\/data\/attributes\/(.+)/);
            if(matches) {
              const nestedPath = `_nested.${dashToUnderscore(matches[1].replace(/(?=\[)/, '_attributes'))}`
              const path = toCamelCase(matches[1].replace('.', '.attributes.'));
              setFieldError(path, error.detail || 'is invalid')
              setFieldError(nestedPath, error.detail || 'is invalid')
            }
          });
        });
      }
    } catch(e) {
      console.log(e);
    }
  }

  const deleteRecord = ({resetForm}) => (record) => {
    return remote
      .update(q => q.removeRecord(record))
      .then(() => resetForm())
      .catch((e, _transform) => {
        console.log(e);
        addNotification({variant: 'alert', message: "Error deleting record"});
        throw e;
      })
  };

  const defaultSave = (values, {resetForm}) => {
    const {_nested, ...attributes} = values
    const extra = {}
    if(relationshipIds && relationshipIds.length) {
      extra.relationships = {}
      relationshipIds.forEach(relationshipId => {
        const associatedType = relationshipId.replace(/Id$/, '')
        extra.relationships[associatedType] = { data: { type: associatedType, id: attributes[relationshipId] } }
        delete attributes[relationshipId]
      })
    }
    const url = relatedResource && buildRelatedResourceURL(relatedResource, resource)
    if(_nested) {
      return saveWithPayload(resource, { data: { attributes: attributes, ...extra }, ...filterHackIds(_nested) }, { url: url })
        .then(result => {
          resetForm({isSubmitting: true}) // By default, resetForm() will clear the isSubmitting state! Force isSubmitting to remain true.
          return result
        });
    } else {
      return remote.update(q => q[isNew(resource) ? 'addRecord' : 'updateRecord']({ type: resource.type, id: resource.id, attributes: attributes, ...extra }), { url: url });
    }
  }

  const save = customSave || defaultSave;

  const saveRecord = (values, setResource, resetForm) => {
    return save(values, {resetForm})
      .then((newResource) => {
        return Promise.resolve(props.onSave && props.onSave(newResource, {values})).then(() => {
          addNotification({variant: 'notice', message: "Your changes have been saved."})
          setResource && setResource(newResource)
          if(isNew(resource) && props.redirectAfterSave !== false) {
            const redirectTo = typeof(props.redirectAfterSave) === 'string' ? props.redirectAfterSave : `../${getRemoteId(newResource)}`
            setLocationOnNextRender([redirectTo])
          }
        })
      })
      .catch((e, _transform) => {
        console.log(e)
        addNotification({variant: 'alert', message: "Error saving changes"});
        throw e;
      })
  }

  return (
    <Formik
      initialValues={initialValues || {...resource.attributes, ...relationshipAttributes}}
      onSubmit={handleSubmit({setResource})}
      enableReinitialize={true}
      validate={validate}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({isSubmitting, dirty, values, setFieldValue, resetForm, errors}) => (
        <FormikForm autoComplete="off" className={classes.container}>
          {resetOnChange && (
            <FormResetter resetForm={resetForm} watches={resetOnChange}/>
          )}
          {props.promptOnNavigate !== false && (
            <Prompt
              when={dirty}
              message='You have unsaved changes. Are you sure you want to leave without saving?'
            />
          )}
          <Box sx={ backwardsCompatibleMargins ? { '.MuiTextField-root': { marginTop: '8px', marginBottom: '4px' }, '.MuiCheckbox-root': { marginY: '0px' } } : {}}>
            {typeof(props.children) === 'function' ?
              props.children({isNew: isNew(resource), values, setFieldValue, resetForm, isSubmitting, dirty, errors, deleteRecord: deleteRecord({resetForm}), setNestedValue: createSetNestedValue({values, setFieldValue}), deleteNestedValue: createDeleteNestedValue({values, setFieldValue})}) :
              props.children
            }
          </Box>
          {withoutSubmitButton || (
            <div className={classes.buttons}>
              <Button type="submit" className={classes.button} disabled={isSubmitting || !dirty}>
                { isSubmitting ? 'Please wait...' : 'Save' }
              </Button>
              {isSubmitting && <CircularProgress size={32}/>}
            </div>
          )}
        </FormikForm>
      )}
    </Formik>
  );
};

export function PersistedErrorHandler({name, children}) {
  const { errors, touched, setFieldTouched, getFieldMeta } = useFormikContext()
  const hasError = name && !!getIn(errors, name)
  const isTouched = name && !!getIn(touched, name)
  const isDirty = getFieldMeta(name).initialValue !== getFieldMeta(name).value

  const child = React.Children.only(children)

  function handleFocus() {
    setFieldTouched(name, true);
    child.props.onFocus && child.props.onFocus()
  }

  return React.cloneElement(child, {
    className: clsx(child.props.className, {'persistedError': hasError && !isTouched && !isDirty}),
    error: (child.props.error || hasError) && !isTouched,
    onFocus: handleFocus
  })
}

const FormTextField = function(props) {
  const { setFieldValue } = useFormikContext()
  const classes = useStyles();
  const { useFastTextField, useRichText, name, validateInput, ...otherProps } = props;

  const WhichFormikField = getWhichFormikField(useFastTextField)

  const handleChange = validateInput ? event => {
    if (validateInput(event.target.value)) {
      setFieldValue(name, event.target.value)
    }
  } : null

  return (
    <PersistedErrorHandler name={name}>
      <WhichFormikField
        fullWidth={'fullWidth' in props ? props.fullWidth : true}
        className={classes.textField}
        name={name }
        component={DefaultInputValueHandler}
        innerComponent={useRichText ? FormRichTextField : TextField}
        {...otherProps}
        {...(handleChange ? { onChange: handleChange } : {})}
      />
    </PersistedErrorHandler>
  );
};

const FormRichTextField = function(props) {
  const {
    form: { setFieldValue },
    field: { name },
  } = props

  const onChange = useCallback((value) => {
    setFieldValue(name, value)
  }, [setFieldValue, name])

  return (
    <PersistedErrorHandler name={props.name}>
      <RichTextField {...fieldToTextField(props)} onChange={onChange} />
    </PersistedErrorHandler>
  )
}

function CheckboxWithLabelAndError({error, className, ...props}) {
  return (
    <Box className={className} sx={{ backgroundColor: error ? COLORS.seashell : null }}>
      <CheckboxWithLabel {...props} />
    </Box>
  )
}

const FormCheckbox = function(props) {
  const { name, label, defaultValue = false } = props

  return (
    <>
      <PersistedErrorHandler name={name}>
        <FormikField
          component={DefaultInputValueHandler}
          innerComponent={CheckboxWithLabelAndError}
          defaultValue={defaultValue}
          type="checkbox"
          name={name}
          Label={{ label: label }}
        />
      </PersistedErrorHandler>
    </>
  )
};

const FormSelect = function(props) {
  const { withOther, children, useFastTextField, ...otherProps } = props;

  const WhichFormikField = getWhichFormikField(useFastTextField)
  const component = withOther ? FormSelectWithOther : FormTextField

  return (
    <PersistedErrorHandler name={props.name}>
      <WhichFormikField
        fullWidth={false}
        component={component}
        select
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          displayEmpty: true
        }}
        {...otherProps}
      >
        {children}
      </WhichFormikField>
    </PersistedErrorHandler>
  );
};

const FormSelectWithOther = function({children, ...props}) {
  return (
    <PersistedErrorHandler name={props.name}>
      <SelectWithOther {...fieldToTextField(props)}>{children}</SelectWithOther>
    </PersistedErrorHandler>
  );
};

const FormFilePicker = function({ name, defaultValue = null, filenameFieldName = null, buttonText, extensions, renderPreview, maxSize = 2 }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const [error, setError] = useState();
  const fileValue = values[name] || defaultValue

  const handleChange = file => {
    setError(null)
    setFieldValue(name, null);
    var reader = new FileReader();
    reader.addEventListener('load', () => {
      setFieldValue(name, reader.result.replace(";base64", `;name=${file.name};base64`))
      if(filenameFieldName) {
        setFieldValue(filenameFieldName, file.name)
      }
    }, false);
    reader.readAsDataURL(file);
  };

  const handleError = error => {
    console.log(error);
    setError(error)
    setFieldValue(name, null);
    if(filenameFieldName) {
      setFieldValue(filenameFieldName, null)
    }
  };

  return (
    <div>
      <Grid container alignItems="center" style={{marginTop: '10px'}}>
        <Grid item>
          <FilePicker
            extensions={extensions}
            onChange={handleChange}
            onError={handleError}
            maxSize={maxSize}
          >
            <Button className={classes.button} variant="outlined">{buttonText || 'Select file...'}</Button>
          </FilePicker>
          <div className={classes.filePickerMessage}>
            Valid extensions: {extensions.join(', ')}
          </div>
          {error && (
            <div className={classes.filePickerError}>
              {error}
            </div>
          )}
        </Grid>
        <Grid item>
          {renderPreview(fileValue, values[filenameFieldName])}
        </Grid>
        {(fileValue) && (
          <Grid item>
            <IconButton aria-label="Remove" onClick={() => setFieldValue(name, null)} size="large">
              <DeleteIcon/>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const FormImagePicker = function({ name, label, buttonText, defaultValue = null }) {
  return (
    <FormFilePicker
      name={name}
      buttonText={buttonText}
      extensions={['jpg', 'gif', 'png', 'gif']}
      defaultValue={defaultValue}
      renderPreview={url => (
        <div style={{height: '110px', minWidth: '100px', padding: '4px', marginLeft: '8px', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {url && (
            <img src={url} style={{maxHeight: '100px', background: '#eee url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill-opacity=".10"><rect x="20" width="20" height="20"/><rect y="20" width="20" height="20" /></svg>\')'}}/>
          ) || (
            <Typography color="textSecondary">{label}</Typography>
          )}
        </div>
      )}
    />
  )
}

const FormColorPicker = function(props) {
  const { values, setFieldValue } = useFormikContext();
  const { name, ...otherProps } = props;

  const handleChange = value => {
    setFieldValue(name, value);
  };

  return (
    <ColorPicker
      {...otherProps}
      defaultValue={values[name]}
      onChange={handleChange}
    />
  );
};

const FormFieldArray = function(props) {
  const { values } = useFormikContext();

  const items = getIn(values, props.name) || [];
  const render = props.children;

  const handleRemove = (remove, item, index) => {
    if(props.onRemove) {
      props.onRemove(item, index);
    }
    remove(index);
  };

  return (
    <FieldArray name={props.name}>
      {({remove, push, insert}) => items.map((item, index) => (
        render({item, index, remove: () => handleRemove(remove, item, index), push, insert})
      ))}
    </FieldArray>
  );
};

const SimpleFormFieldArray = function({name, newValue, buttonText, onRemove = (_item, _index) => {}, children: render, component, ...props}) {
  const { values } = useFormikContext();
  const classes = useStyles();

  const items = getIn(values, name) || [];

  const handleRemove = useCallback((remove, item, index) => {
    onRemove(item, index);
    remove(index);
  }, [onRemove]);

  const Component = component

  return (
    <FieldArray name={name}>
      {({remove, push}) => (
        <>
          {items.map((item, index) => (
            <Grid key={index} container className={classes.row}>
              <Grid item className={classes.column}>
                <IconButton onClick={() => handleRemove(remove, item, index)} aria-label="Remove" size="large">
                  <RemoveCircleIcon/>
                </IconButton>
              </Grid>
              <Grid item className={classes.column}>
                {component ? <Component item={item} index={index} {...props}/> : render({item, index})}
              </Grid>
            </Grid>
          ))}
          <div className={classes.buttons}>
            <Button onClick={() => push(newValue())}>
              <AddIcon/>
              {buttonText}
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
}

export {
  FieldArray as FormikFieldArray,
  Form,
  FormCheckbox,
  FormColorPicker,
  FormFieldArray,
  FormFilePicker,
  FormImagePicker,
  FormSelect,
  FormTextField,
  SimpleFormFieldArray,
  createDeleteNestedValue,
  createSetNestedValue,
  getIn,
  getNestedValuePath,
  setIn,
  useFormikContext,
};
