import React, { useContext } from 'react'

const CurrentUserContext = React.createContext({ currentUser: null, currentTeam: null })

export default function CurrentUserProvider({ children, currentUser, currentTeam }) {
  return (
    <CurrentUserContext.Provider value={{ currentUser, currentTeam }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export function useCurrentUser() {
  return useContext(CurrentUserContext)
}
