import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import { generateId, toggleList } from '../lib/utils'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'

import TextField from '@mui/material/TextField';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

const useStyles = makeStyles(_theme => ({
  container: {
    flex: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #c0c0c0',
    borderRadius: 20,
    padding: '2px 4px',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    marginRight: 4,
    padding: '0 6px',
    borderRadius: 20,
    '& $closeIcon': {
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.5,
      },
    },
    whiteSpace: 'nowrap',
    margin: '2px',
  },
  closeIcon: {},
  addFilter: {
    backgroundColor: '#e0e0e0',
    padding: '0 6px',
    borderRadius: 20,
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '14px',
    border: 'none !important',
    outline: 'none !important',
    whiteSpace: 'nowrap',
    margin: '2px',
  },
  filterCategory: {
    fontWeight: 700,
    paddingRight: '4px',
  },
  filterValue: {
    cursor: 'pointer',
    userSelect: 'none',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    flex: 1,
    border: 'none',
    outline: 'none',
  },
}))

function renderValue(field, value) {
  return typeof(field.render) === 'function' ? field.render(value) : (value || '(none)')
}

function FilterModal({filter, open, onClose}) {
  const initialValue = useMemo(() => filter.values.join("\n"), [filter.values])
  const [editingValue, setEditingValue] = useState(initialValue)

  const canSubmit = editingValue.length > 0

  const handleApply = useCallback(() => {
    filter.values = editingValue.match(/[^\s,]+/g) || []
    onClose()
  }, [filter, editingValue, onClose])

  const closeModal = useCallback(() => {
    setEditingValue(initialValue)
    onClose()
  }, [initialValue, onClose])

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Filter By: {filter.field.displayName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Insert the {filter.field.displayName}s that you want to filter by. Values can be separated by spaces, new lines, or commas.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="subid"
          label={filter.field.displayName}
          value={editingValue}
          multiline
          fullWidth
          rows={8}
          onChange={ event => setEditingValue(event.target.value) }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!canSubmit} onClick={handleApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function FilterOptions({anchorEl, filter, open, onClose}) {
  const [values, setValues] = useState(filter.values)

  const closeMenu = () => {
    filter.values = values
    onClose()
  }

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
      {filter.field.values.map(value => (
        <MenuItem key={value} style={{paddingTop: 2, paddingBottom: 2}}>
          <FormControlLabel
            control={
              <Checkbox
                style={{paddingTop: 2, paddingBottom: 2, paddingRight: 4}}
                checked={values.includes(value)}
                onChange={event => { setValues(toggleList(values, value, event.target.checked)) }}
              />
            }
            label={renderValue(filter.field, value)}
          />
        </MenuItem>
      ))}
    </Menu>
  )
}

function Filter({filter, onChange, onRemove}) {
  const classes = useStyles()
  const anchorEl = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if(anchorEl.current && filter.initial) {
      filter.initial = false
      setMenuOpen(true)
    }
  }, [filter, filter.initial])

  const onClose = () => {
    setMenuOpen(false)

    if(filter.values.length === 0) {
      onRemove()
    } else {
      onChange()
    }
  }

  const handleRemove = () => {
    setMenuOpen(false)
    onRemove()
  }

  return (
    <div className={classes.filter} role="button">
      <div className={classes.filterCategory}>
        {filter.field.displayName || filter.field.name}:
      </div>
      <div className={classes.filterValue} onClick={() => setMenuOpen(!menuOpen)} ref={anchorEl}>
        {filter.values.map(renderValue.bind(undefined, filter.field)).join(', ')}
      </div>
      <CloseIcon role="button" aria-label="close" className={classes.closeIcon} fontSize="inherit" onClick={handleRemove}/>

      {filter.field.values && (
        <FilterOptions anchorEl={anchorEl.current} filter={filter} open={menuOpen} onClose={onClose}/>
      ) || (
        <FilterModal filter={filter} open={menuOpen} onClose={onClose}/>
      )}

    </div>
  )
}

function AddFilter({fields, addFilter}) {
  const classes = useStyles()
  const popupState = usePopupState({ variant: 'popover', popupId: 'addFilter' })

  return (
    <>
      <button className={classes.addFilter} {...bindTrigger(popupState)}>
        Add filter
        <KeyboardArrowDownIcon fontSize="inherit"/>
      </button>
      <Menu {...bindMenu(popupState)}>
        {fields.map(field => (
          <MenuItem key={field.name} onClick={() => {addFilter(field); popupState.close()}}>{field.displayName || field.name}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

function mapFromExternal(externalFilters, fields) {
  return externalFilters.map(filter => ({
    id: generateId(),
    field: fields.find(field => field.name === filter.field),
    initial: false,
    values: filter.values,
  })).filter(filter => !!filter.field)
}

function mapToExternal(filters) {
  return filters.filter(filter => filter.values.length).map(filter => ({
    field: filter.field.name,
    values: filter.values,
  }))
}

export default function SweetFilter({fields, filters: initialFilters, setFilters: setExternalFilters}) {
  const classes = useStyles()
  const [filters, setFilters] = useState(() => mapFromExternal(initialFilters, fields))

  const addFilter = field => {
    setFilters([...filters, {id: generateId(), field: field, initial: true, values: []}])
  }

  useEffect(() => {
    setExternalFilters(mapToExternal(filters))
  }, [filters, setExternalFilters])

  const availableFields = fields.filter(field =>
    (!field.values || field.values.length > 0) && !filters.some(filter => filter.values.length && filter.field.name === field.name)
  )

  return (
    <div className={classes.container}>
      {filters.map(filter => (
        <Filter
          key={filter.id}
          filter={filter}
          onChange={() => setFilters([...filters])}
          onRemove={() => setFilters([...filters.filter(f => f !== filter)])}
        />
      ))}
      {availableFields.length > 0 && (
        <AddFilter fields={availableFields} addFilter={addFilter}/>
      )}
    </div>
  )
}
