import { useCallback, useMemo } from 'react'

import Button from '@mui/material/Button'

import DatePickerPopover from '../DatePickerPopover';
import DebouncedTextField from '../DebouncedTextField'
import { arrayDiff, arrayUnion, isPresent, sortBy } from '../../lib/utils'
import Checkbox from '../controls/Checkbox'
import CheckboxMenu from '../controls/CheckboxMenu'

import { useDataManager } from './DataManager'
import useStyles from './styles'

import FilterActiveImage from './assets/filter-active.png'
import FilterInactiveImage from './assets/filter-inactive.png'
import DateActiveImage from './assets/date-active.png'
import DateInactiveImage from './assets/date-inactive.png'

import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { dateRangeToTimeRange, timeRangeToDateRange } from 'lib/time';
import { useTimezone } from 'lib/TimezoneProvider';

function TextFilterField({column}) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const hasFilter = isPresent(dataManager.filters[column.field]?.value?.[0])

  return (
    <DebouncedTextField
      size="small"
      className={`${classes.filterField} ${hasFilter ? classes.filterFieldActive : ''}`}
      value={dataManager.filters[column.field]?.value?.[0] || ''}
      onChange={event => dataManager.setFilter(column.field, { operator: 'contains', value: event.target.value })}
      placeholder="Search..."
    />
  )
}

const EMPTY_ARRAY = []

function matchParentMenuOption(parentColumn, parentMenuOption, key) {
  const keys = parentColumn.subfieldMap[parentMenuOption[0]]
  return keys && keys.includes(key)
}

// TODO: there's some yuck in here; refactor by adding helper methods to DataManager for adding/removing filter values
// (e.g. we should be able to get back a guaranteed array from a dataManager.getFilterValues() method rather than having to guard every time)
function LookupFilterMenu({column, parentColumn, parentMenuOption, ...menuProps}) {
  const dataManager = useDataManager()
  const hasFilter = dataManager.filters[column.field]?.value instanceof Array && isPresent(dataManager.filters[column.field]?.value)
  const selectedKeys = hasFilter ? dataManager.filters[column.field]?.value : EMPTY_ARRAY
  const lookupOptions = useMemo(() => {
    return sortBy(Object.entries(column.lookup).filter(([key, _value]) => parentColumn ? matchParentMenuOption(parentColumn, parentMenuOption, key) : true), ([_key, value]) => value)
  }, [column.lookup])
  const subfieldColumn = useMemo(() => dataManager.columns.find(someColumn => someColumn.field === column.subfield))
  const renderSubmenu = useMemo(() => {
    if(!subfieldColumn) {
      return null
    }
    return ({...subfieldMenuProps}) => (
      <LookupFilterMenu parentColumn={column} column={subfieldColumn} Popover={HoverPopover} {...subfieldMenuProps}/>
    )
  }, [column.lookup, subfieldColumn && subfieldColumn.lookup])
  const renderLookupOption = useCallback(([key, value]) => value)
  const isChecked = useCallback(([key, _value]) => selectedKeys.includes(key), [selectedKeys])
  const onChange = useCallback(([key, _value]) => event => {
    const newFilter = column.filterExclusive ? (event.target.checked ? [key] : []) : (event.target.checked ? arrayUnion(selectedKeys, [key]) : arrayDiff(selectedKeys, [key]))
    dataManager.setFilter(column.field, { operator: 'list', value: newFilter })
    if(subfieldColumn) {
      const subfieldSelectedKeys = dataManager.filters[subfieldColumn.field]?.value || EMPTY_ARRAY
      const subfieldKeys = Object.keys(subfieldColumn.lookup).filter(subfieldKey => matchParentMenuOption(column, [key], subfieldKey))
      dataManager.setFilter(subfieldColumn.field, { operator: 'list', value: event.target.checked ? arrayUnion(subfieldSelectedKeys, subfieldKeys) : arrayDiff(subfieldSelectedKeys, subfieldKeys) })
    }
    if(parentColumn) {
      const parentSelectedKeys = dataManager.filters[parentColumn.field]?.value || EMPTY_ARRAY
      if(event.target.checked) {
        dataManager.setFilter(parentColumn.field, { operator: 'list', value: arrayUnion(parentSelectedKeys, [parentMenuOption[0]]) })
      } else {
        // If every other subfield option was unchecked, then uncheck the parent
        if(!lookupOptions.some(([someKey, _someValue]) => someKey !== key && selectedKeys.includes(someKey))) {
          dataManager.setFilter(parentColumn.field, { operator: 'list', value: arrayDiff(parentSelectedKeys, [parentMenuOption[0]]) })
        }
      }
    }
  }, [selectedKeys, dataManager])

  return lookupOptions.length > 0 && (
    <CheckboxMenu
      {...menuProps}
      menuOptions={lookupOptions}
      render={renderLookupOption}
      isChecked={isChecked}
      onChange={onChange}
      renderSubmenu={renderSubmenu}
    />
  )
}

function CheckboxFilterField({column}) {
  const dataManager = useDataManager()
  const handleChange = useCallback(event => {
    return dataManager.setFilter(column.field, { operator: 'equals', value: [event.target.checked] })
  }, [column, dataManager])

  return (
    <Checkbox
      onChange={handleChange}
      value={dataManager.filters[column.field]?.value?.[0]}
    />
  )
}

function DateFilterMenu({column, ...popoverProps}) {
  const dataManager = useDataManager()
  const { timezone } = useTimezone()
  const handleChange = useCallback(value => {
    dataManager.setFilter(column.field, { operator: 'between', value: value })
  }, [column, dataManager])

  return (
    <DatePickerPopover
      {...popoverProps}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onChange={(timeRange) => handleChange(timeRangeToDateRange(timeRange))}
      value={dateRangeToTimeRange(dataManager.filters[column.field]?.value, timezone)}
    />
  )
}

function FilterButton({column, icon, activeIcon, component: Component}) {
  const classes = useStyles()
  const dataManager = useDataManager()
  const hasFilter = dataManager.hasFilter(column)
  const popupState = usePopupState({ variant: 'popover', popupId: 'filterField' })

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        className={`${classes.filterButton} ${hasFilter ? classes.filterButtonActive : ''}`}
        {...bindTrigger(popupState)}
      >
        <img alt={hasFilter ? "active filter icon" : "inactive filter icon"} src={hasFilter ? activeIcon : icon} width={18} height={18} />
        Filter {column.title}
      </Button>
      <Component {...bindPopover(popupState)} column={column}/>
    </>
  )
}

function LookupFilterField({column}) {
  return (
    <FilterButton column={column} icon={FilterInactiveImage} activeIcon={FilterActiveImage} component={LookupFilterMenu} />
  )
}

function DateFilterField({column}) {
  return (
    <FilterButton column={column} icon={DateInactiveImage} activeIcon={DateActiveImage} component={DateFilterMenu} />
  )
}

export default function FilterField({column}) {
  if(column.lookup) {
    return <LookupFilterField column={column}/>
  } else if(column.type === 'date') {
    return <DateFilterField column={column}/>
  } else if(column.type == 'checkbox') {
    return <CheckboxFilterField column={column}/>
  } else {
    return <TextFilterField column={column}/>
  }
}
