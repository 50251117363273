import { findIndex, isEqual } from "lodash"
import SchoolIcon from '@mui/icons-material/School'
import StopIcon from '@mui/icons-material/Stop'
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import { useLandingPageStateContext } from "../../LandingPageProvider"
import { groupBy } from "lib/utils"
import DropdownCheckbox from "components/UI/DropdownCheckbox/DropdownCheckbox"
import StyledCard, { ErrorWrapper } from "components/UI/StyledCard"
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"
import { getClientNamesFromLandingPage, getDegreeProgramNamesFromLandingPage } from "components/pages/LandingPages/data/helpers"
import EmptyStateSection from "./EmptyStateSection"
import RuleOperator from "./RuleOperator"


function ClientOrProgramListItem({ clientNames, programNames, appliesToId, appliesToType }) {
  const isClientType = appliesToType === "Client"
  const isProgramType = appliesToType === "DegreeProgram"

  return (
    <ListItem disablePadding sx={{ mt: '6px', lineHeight:'18px', alignItems:'baseline' }}>
      <ListItemIcon sx={{ml: '-1px', minWidth: '19px'}}>
        <StopIcon sx={{fontSize:'8px'}} />
      </ListItemIcon>
      <ListItemText sx={{my: 0, lineHeight:'18px'}}>
        <Typography lineHeight='18px'>
          { isClientType && clientNames[appliesToId] }
          { isProgramType && programNames[appliesToId] }
        </Typography>
      </ListItemText>
    </ListItem>
  )
}

function ExclusionRule({ mergedRule, index, questionKey, clientNames, programNames }) {
  const { landingPage } = useLandingPageStateContext()
  const exclusionRule = mergedRule.rule
  const ruleIndex = findIndex(landingPage.implicitLandingPageRules, function(r) { return r.id === exclusionRule.id})

  const ruleAppliesTo = <Box my={2} mx={3}>
    <Typography color="info.main" variant="body2">
      Rule pulled from the following client(s) and/or program(s)
    </Typography>
    <List disablePadding>
      {mergedRule.appliesTo.map(clientOrProgram =>
        <ClientOrProgramListItem clientNames={clientNames} programNames={programNames} appliesToId={clientOrProgram.id} appliesToType={clientOrProgram.__typename} key={`client_or_program_names_${clientOrProgram.id}`}/>
      )}
    </List>
  </Box>

  return (
    <ErrorWrapper>
      <Grid container spacing={2} marginBottom={1} role="listitem" aria-label={`Rule ${index+1}`} aria-roledescription="Exclusion Rule">
        <Grid item xs={12}>
          <SubsectionTitle title={`Rule ${index + 1}`} controls={[
              { icon:  <SchoolIcon/>, label: 'Rule Applies To', popoverContent: ruleAppliesTo},
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            defaultValue={questionKey}
            label='Question'
            fullWidth
            disabled
            id={`exclusion_question_${Math.random()}`}
          >
            <MenuItem value={questionKey}>{questionKey}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <RuleOperator path={`implicitLandingPageRules[${ruleIndex}].operator`} disabled />
        </Grid>
        <Grid item xs={4}>
          <DropdownCheckbox
            collection={exclusionRule.value}
            selected={exclusionRule.value}
            label='Exclusion Value'
            isPreset={true}
            disableMenuItems={true}
          />
        </Grid>
      </Grid>
    </ErrorWrapper>
  )
}

export default function ClientAndProgramRules() {
  const { landingPage } = useLandingPageStateContext()
  const clientNames = getClientNamesFromLandingPage(landingPage)
  const programNames = getDegreeProgramNamesFromLandingPage(landingPage)
  const questions = landingPage.steps.flatMap(s => s.questions)

  const mergedExclusionRules = groupBy(
    landingPage.implicitLandingPageRules,
    rule => [rule.questionId, rule.operator, rule.value],
    { comparator: isEqual,
      asEntries: true,
    }).map(([_key, rules]) => { return {rule: rules[0], appliesTo: rules.map(mr => mr.exclusionRule.appliesTo)} })

  return (
    <StyledCard
      title='Client & Program Rules'
      note='Leads filtered from clients and programs and fed to next ranked result. '
      isSubsection
      hasStickyHeader
      role="group"
    >
      <Stack
        direction="column"
        divider={<Divider light flexItem sx={{ mx: 4 }}/>}
        role="list"
        aria-roledescription="Client And Program Rules"
      >
        {mergedExclusionRules.map((mergedRule, index) =>
          <ExclusionRule
            mergedRule={mergedRule}
            index={index}
            questionKey={questions.find(q => q.id === mergedRule.rule.questionId)?.key || ''}
            clientNames={clientNames}
            programNames={programNames}
            key={`exclusion_${index}`}
          />
        )}
        {mergedExclusionRules.length === 0 &&
          <EmptyStateSection message="No client or program exclusion rules." />
        }
      </Stack>
    </StyledCard>
  )
}
