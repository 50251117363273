import { createContext, useContext, useEffect, useState } from 'react'

import moment from './moment'
import { getStorage, setStorage } from './utils'

const timezoneContext = createContext({ timezone: getInitialTimezone(), setTimezone: (_value) => {} })

function getInitialTimezone() {
  return getStorage('timezone.tz') || moment.tz.guess()
}

export default function TimezoneProvider({ children }) {
  const [timezone, setTimezone] = useState(getInitialTimezone)
  useEffect(() => {
    setStorage('timezone.tz', timezone)
  }, [timezone])

  return (
    <timezoneContext.Provider value={{ timezone, setTimezone }}>
      {children}
    </timezoneContext.Provider>
  )
}

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export function useTimezone() {
  return useContext(timezoneContext)
}
