import { useEffect, useState, useRef, useMemo } from "react";
import Dialog from '@mui/material/Dialog';

import { DialogTitle, DialogContent } from '../../dialogs';
import SimpleTable from '../../SimpleTable';
import { remote } from '../../../lib/DataModel'
import { failOnHttpError } from '../../../lib/utils'
import { loadTableData } from '../../table-utils';
import { Form, FormCheckbox, FormTextField } from '../../Formik/forms';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check'
import { useParams } from "react-router-dom"
import MainContainer from '../../../components/layout/MainContainer/MainContainer'
import { useNotifications } from "lib/NotificationsProvider";

const COLUMNS = [
  { title: 'ID', field: 'id', type: 'numeric', width: '0px', visible: true },
  { title: 'Name', field: 'name', visible: true },
  { title: 'Email', field: 'email', visible: true },
  { title: 'Team Admin?', field: 'teamAdmin', render: teamAdmin => teamAdmin ? <CheckIcon/> : null, visible: true },
]

const AddUserModal = function(props) {
  const [user, setUser] = useState(props.user);

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={props.onClose}>
      <DialogTitle id="modal-title" onClose={props.onClose}>
        {user.id ? 'Edit' : 'Add'} user
      </DialogTitle>
      <DialogContent>
        <Form
          resource={user}
          setResource={setUser}
          relatedResource={props.team}
          onSave={props.onSave}
          redirectAfterSave={false}
          promptOnNavigate={false}
          backwardsCompatibleMargins={true}
        >
          <FormTextField name="name" label="Name"/>
          <FormTextField name="email" label="Email" inputProps={{autoComplete: 'new-password'}}/>
          <FormTextField name="password" label="Password" type="password" inputProps={{autoComplete: 'new-password'}}/>
          <FormCheckbox name="teamAdmin" label="Team admin?"/>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default function UserTable() {
  const { teamId } = useParams()
  const { addNotification } = useNotifications()
  const [userForModal, setUserForModal] = useState<null | {type: null | string, id: null | string, attributes: null | {name: null | string, email: null | string, teamAdmin: null | boolean, password: null | string}}>()
  const [team, setTeam] = useState();
  const [first, setFirst] = useState(true);
  const dataManagerRef = useRef(null);

  useEffect(() => {
    remote.query(q => q.findRecord({type: "team", id: teamId}))
      .then(team => {
        setTeam(team)
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to load team.'});
      })
  }, [addNotification, teamId]);

  const refreshTable = () => {
    dataManagerRef.current && dataManagerRef.current.reload();
  };

  useEffect(() => {
    if(first) {
      setFirst(false)
    } else {
      refreshTable()
    }
  }, [first, teamId])

  const handleDelete = (_event, rowData) => {
    if(!confirm(`Are you sure you want to delete the user ${rowData['email']}?`)) {
      return;
    }

    fetch(`/api/v1/teams/${teamId}/users/${rowData.id}`, { method: 'DELETE' })
      .then(failOnHttpError)
      .then(() => {
        addNotification({variant: 'notice', message: 'Deleted user.'});
        refreshTable();
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to delete user.'});
      });
  };

  const handleEdit = (_event, rowData) => {
    setUserForModal({type: 'user', id: rowData.id, attributes: {name: rowData.name, email: rowData.email, teamAdmin: rowData.teamAdmin, password: ''}});
  };

  const handleAdd = (_event, _rowData) => {
    setUserForModal({type: 'user', id: null, attributes: {name: '', email: '', password: '', teamAdmin: false}});
  };

  const closeModal = () => {
    setUserForModal(null);
  };

  const afterAddUser = (_user) => {
    closeModal();
    refreshTable();
  };

  const dataFn = useMemo(() => {
    if(team) {
      return loadTableData(q => q.findRelatedRecords(team, 'users'), addNotification)
    } else {
      return []
    }
  }, [addNotification, team])

  return (
    <MainContainer>
      <div style={{ maxWidth: "100%" }}>
        <SimpleTable
          dataManagerRef={dataManagerRef}
          columns={COLUMNS}
          padding="dense"
          data={dataFn}
          options={{
            paginated: true,
            pageSize: 10,
            columnsButton: true,
          }}
          title={`Users for ${team && team.attributes.name || ''}`}
          rowActions={[
            {
              icon: () => <DeleteOutlineIcon/>,
              tooltip: 'Delete User',
              onClick: handleDelete,
            },
            {
              icon: () => <EditIcon/>,
              tooltip: 'Edit user',
              onClick: handleEdit,
            },
          ]}
          actions={[
            {
              icon: () => <AddCircleIcon/>,
              isFreeAction: true,
              tooltip: 'Add new user',
              onClick: handleAdd,
            },
          ]}
        />
      </div>
      {userForModal && (
        <AddUserModal
          user={userForModal}
          team={team}
          onClose={closeModal}
          onSave={afterAddUser}
        />
      )}
    </MainContainer>
  )
}
