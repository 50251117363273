import { useState, useEffect, useCallback, useMemo, useRef, useImperativeHandle } from 'react'
import * as yup from 'yup'
import makeStyles from '@mui/styles/makeStyles';
import moment from '../../lib/moment'
import { generateRange, sum, toFormattedNumber } from '../../lib/utils'
import { Form, getIn, useFormikContext } from '../Formik/forms'
import { store, getRemoteId } from '../../lib/DataModel'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import DailyCapsResource from '../../resources/DailyCapsResource'
import { useNotifications } from 'lib/NotificationsProvider';

const useStyles = makeStyles({
  footer: {
    marginTop: 24,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerTitle: {
    marginRight: 10,
    fontWeight: 700,
  },
  footerValue: {
    marginRight: 10,
  },
  footerRight: {
    '& button': {
      marginLeft: 24,
    },
  },
  dailyCapTextField: {
    width: 72,
  },
  dailyCapTableCell: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    '&:first-child': {
      paddingLeft: '0px',
    },
    '&:last-child': {
      paddingRight: '0px',
    },
  },
})

function mapToWeeks(year, month) {
  const weeks = []
  const daysInMonth = moment([year, month-1]).daysInMonth()

  let week

  [...generateRange(1, daysInMonth)].forEach(day => {
    const weekday = moment([year, month-1, day]).weekday()
    if(weekday === 0 || !week) {
      week = weeks[weeks.length] = [...generateRange(1,7)].map(() => null)
    }
    week[weekday] = day
  })

  return weeks
}

function DailyCapTextField({value, day, onChange, disabled}) {
  const inputRef = useRef()
  const { errors } = useFormikContext()
  const classes = useStyles()

  const hasError = day in errors
  // Determine if this field is the first error for autoFocus
  const isFirstError = useMemo(() => Object.keys(errors)[0] == day, [day, errors])

  useEffect(() => {
    if(isFirstError && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isFirstError])

  return (
    <TextField
      size="small"
      className={classes.dailyCapTextField}
      value={value}
      label={day}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={onChange}
      disabled={disabled}
      error={hasError}
      inputRef={inputRef}
      sx={{
        '.MuiOutlinedInput-root': {
          backgroundColor: hasError ? 'rgba(205, 120, 101, 0.2)' : '',
        }
      }}
    />
  )
}

function DailyCapTextFieldContainer({day, onChangeDailyCap}) {
  const { values } = useFormikContext()
  const [dailyCap, setDailyCap] = useState({})
  const value = isNaN(dailyCap?.limit) ? '' : dailyCap?.limit

  useEffect(() => {
    setDailyCap(getDailyCapAttributesFromFormValues(values, day))
  }, [day, values, setDailyCap])

  const handleChange = useCallback(event => {
    onChangeDailyCap({day: day, limit: event.target.value})
  }, [day, onChangeDailyCap])

  return (
    <DailyCapTextField
      value={value}
      day={day}
      onChange={handleChange}
    />
  )
}

function DailyCap({day, onChange}) {
  const handleChange = useCallback(dailyCap => {
    onChange(dailyCap)
  }, [onChange])

    return (
      <DailyCapTextFieldContainer
        onChangeDailyCap={handleChange}
        day={day}
      />
    )
}

function DailyCapsCalendar({year, month, clientCampaignGroup, onChange, isSubmitting}) {
  const classes = useStyles()
  const weeks = useMemo(() => mapToWeeks(year, month), [year, month])

  return (
    <Table size="small" sx={{ width: 582 }}>
      <TableHead>
        <TableRow>
          {[...generateRange(0,6)].map(weekday => (
            <TableCell key={weekday} padding="none" className={classes.dailyCapTableCell}>
              {moment().weekday(weekday).format('ddd')}
            </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {weeks.map((week, weekIndex) => (
          <TableRow key={weekIndex}>
            {week.map((day, dayIndex) => (
              <TableCell key={dayIndex} padding="none" className={classes.dailyCapTableCell}>
                {day && (
                  <DailyCap
                    disabled={isSubmitting}
                    clientCampaignGroup={clientCampaignGroup}
                    year={year}
                    month={month}
                    day={day}
                    onChange={onChange}
                  />)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function getDailyCapAttributesFromFormValues(values, day) {
  return (getIn(values, '_nested.daily_caps_attributes') || []).find(attrs => attrs.day === day)
}

function setDailyCapLimit(setNestedValue, dailyCap, limit) {
    dailyCap.attributes.limit = limit
    setNestedValue('dailyCaps', dailyCap, {relationships: ['clientCampaignGroup']})
}

function TotalDailyCap() {
  const { values } = useFormikContext()

  const limits = (getIn(values, '_nested.daily_caps_attributes') || []).map(dailyCap => {
    return dailyCap.limit
  })

  return toFormattedNumber(sum(limits))
}

function DailyCapsFormBody({
  onCancel,
  dirty,
  campaignCaps,
  clientCampaignGroup,
  dailyCaps,
  year,
  month,
  resetForm,
  setFormDirty,
  setNestedValue,
  isSubmitting,
  initialEmpty,
  setCapsEmpty,
  forwardedRef
}) {
  const { values, errors } = useFormikContext()
  const classes = useStyles()

  const valuesEmpty = useMemo(() => {
    const dailyCaps = getIn(values, '_nested.daily_caps_attributes')
    return dailyCaps.reduce((count, dailyCap) => dailyCap.limit && dailyCap.limit !== '' ? count + 1 : count, 0) === 0
  }, [values])

  const totalMonthlyCap = useMemo(() => {
    if(!clientCampaignGroup) {
      return null
    }

    const campaignType = getIn(clientCampaignGroup, 'attributes.campaignType')
    const clientCampaignIds = getIn(clientCampaignGroup, 'attributes.clientCampaignIds') || []

    return sum(campaignCaps.filter(row => {
      if(campaignType) {
        return getIn(row.clientCampaign, 'attributes.campaignType') === campaignType
      } else {
        return clientCampaignIds.some(id => String(id) === getRemoteId(row.clientCampaign))
      }
    }), 'cap')
  }, [clientCampaignGroup, campaignCaps])

  const handleCancel = useCallback(() => {
    if(dirty) {
      resetForm()
    } else {
      onCancel()
    }
  }, [dirty, onCancel, resetForm])

  useEffect(() => {
    setFormDirty(dirty)
  }, [setFormDirty, dirty])

  useEffect(() => {
    setCapsEmpty(valuesEmpty)
  }, [setCapsEmpty, valuesEmpty])

  useImperativeHandle(forwardedRef, () => ({
    clearCaps: () => {
      dailyCaps.forEach(dailyCap => {
        setDailyCapLimit(setNestedValue, dailyCap, '')
      })
      if(initialEmpty) {
        resetForm(values)
      }
    }
  }))

  const handleChangeDailyCap = useCallback(({day, limit}) => {
    const dailyCap = dailyCaps.find(dailyCap => dailyCap.attributes.day === day)
    if(dailyCap) {
      if(errors?.[day]) {
        delete errors[day]
      }
      setDailyCapLimit(setNestedValue, dailyCap, limit)
    }
  }, [setNestedValue, dailyCaps, errors])

  return(
    <>
      <DailyCapsCalendar
        year={year}
        month={month}
        clientCampaignGroup={clientCampaignGroup}
        onChange={handleChangeDailyCap}
        isSubmitting={isSubmitting}
      />
      <div className={classes.footer}>
        <div className={classes.footerLeft}>
          <span className={classes.footerTitle}>
            Total monthly cap:
          </span>
          <span className={classes.footerValue}>
            {toFormattedNumber(totalMonthlyCap, { fallback: '—' })}
          </span>

          <span className={classes.footerTitle}>
            Total daily cap:
          </span>
          <span className={classes.footerValue}>
            <TotalDailyCap/>
          </span>
        </div>
        <div className={classes.footerRight}>
          <Button
            disableElevation={true}
            variant="text"
            color='secondary'
            onClick={handleCancel}
            disabled={isSubmitting}
          >Cancel</Button>
          <Button disableElevation type="submit" disabled={isSubmitting || !dirty}>
            {isSubmitting ? 'Please wait...' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default function DailyCapForm ({
  setFormDirty,
  clientCampaignGroup = {},
  setClientCampaignGroup,
  dailyCaps = [],
  dailyCapsResource,
  setDailyCapsResource,
  campaignCaps,
  onCancel,
  setCapsEmpty,
  forwardedRef
}) {
  const { addNotification } = useNotifications()

  const handleSave = useCallback(() => {
    // TODO: rather than having to reload the entire DailyCapsResource here, it
    // would be preferable to have the response contain all of the updated
    // dailyCap records, and have the existing DailyCapsResource update itself
    // from the store. This does pose a complication when daily caps are
    // deleted on the backend (by virtue of being set to blank on the
    // frontend), as these would simply be omitted from the response.
    const newResource = new DailyCapsResource({contract: dailyCapsResource.contract, year: dailyCapsResource.year, month: dailyCapsResource.month})
    setDailyCapsResource(newResource)
    return store.update(q => store.cache.query(q => q.findRelatedRecords(clientCampaignGroup, 'dailyCaps')).map(record => q.removeRecord(record)))
                .then(() => newResource.promise)
  }, [setDailyCapsResource, dailyCapsResource, clientCampaignGroup])


  const initialValues = useMemo(() => {
    return {
      ...clientCampaignGroup.attributes,
      '_nested': {
        'daily_caps_attributes': dailyCaps.map(dailyCap => {
          return {
            __id: dailyCap.id,
            id: getRemoteId(dailyCap),
            client_campaign_group_id: getRemoteId(clientCampaignGroup),
            ...dailyCap.attributes
          }
        })
      }
    }
  }, [clientCampaignGroup, dailyCaps])

  const dailyCapSchema = yup.object().shape({
    limit: yup.number().min(0)
  })

  const validate = (values) => {
    const errors = {}
    const dailyCapAttrs = values['_nested']?.['daily_caps_attributes'] || []
    dailyCapAttrs.forEach(dailyCap => {
      if(!dailyCapSchema.isValidSync(dailyCap)) {
        errors[dailyCap.day] = 'Missing cap limit'
      }
    })

    // Return ok if they are all empty
    if(Object.keys(errors).length === dailyCapAttrs.length) {
      return
    } else if (Object.keys(errors).length > 0) {
      const errorMessage = `Clear or complete daily caps for "${clientCampaignGroup.attributes.name}" campaign group`
      addNotification({variant: 'alert', message: errorMessage})
      return errors
    }
  }

  const initialEmpty = useMemo(() => dailyCaps.reduce((count, dailyCap) => dailyCap.attributes.limit && dailyCap.attributes.limit !== '' ? count + 1 : count, 0) === 0, [dailyCaps])

  return (
    <Form
      resource={clientCampaignGroup || {type: 'clientCampaignGroup'}}
      setResource={setClientCampaignGroup}
      relatedResource={dailyCapsResource.contract}
      withoutSubmitButton
      onSave={handleSave}
      validate={validate}
      initialValues={initialValues}
    >
      {({resetForm, setNestedValue, isSubmitting, dirty}) => (
        <DailyCapsFormBody
          onCancel={onCancel}
          dirty={dirty}
          clientCampaignGroup={clientCampaignGroup}
          dailyCaps={dailyCaps}
          year={dailyCapsResource.year}
          month={dailyCapsResource.month}
          resetForm={resetForm}
          setFormDirty={setFormDirty}
          setNestedValue={setNestedValue}
          isSubmitting={isSubmitting}
          campaignCaps={campaignCaps}
          initialEmpty={initialEmpty}
          setCapsEmpty={setCapsEmpty}
          forwardedRef={forwardedRef}
        />
      )}
    </Form>
  )
}
