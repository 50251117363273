import { standardInflectors } from '@orbit/serializers'
import { RawTableSettings, TableSettings } from './types'
import { Column } from '../utils'
import { getStorage } from 'lib/utils'
const { dasherize } = standardInflectors

function validateVisibleColumns(visibleColumns, columns, isAdmin) {
  if(visibleColumns === null) {
    visibleColumns = ['leadCount', 'goodLeadCount', 'goodLeadRate', 'outlay'].concat(isAdmin ? ['clientId'] : ['programGroupId', 'degreeProgramId'])
  }

  const filteredColumnList = visibleColumns.filter(field => columns.map(c => c.field).includes(field))
  if(filteredColumnList.length !== visibleColumns.length) {
    visibleColumns = filteredColumnList
  }

  // At least one groupable column has to be selected
  if(!columns.some(column => column.groupable && visibleColumns.includes(column.field))) {
    visibleColumns = [...visibleColumns, columns.find(column => column.groupable).field]
  }

  return visibleColumns
}

function validateSubtotalColumns(subtotalColumns, columns) {
  if(subtotalColumns === null) {
    subtotalColumns = []
  }

  const filteredColumnList = subtotalColumns.filter(field => columns.map(c => c.field).includes(field))
  if(filteredColumnList.length !== subtotalColumns.length) {
    subtotalColumns = filteredColumnList
  }

  return subtotalColumns
}

function validateSortColumn(sortColumn, columns) {
  if(sortColumn === null) {
    sortColumn = 'billableLeadCount'
  }

  if(!columns.map(c => c.field).includes(sortColumn)) {
    sortColumn = null
  }

  return sortColumn
}

function validateRowFilters(rowFilters, columns, isAdmin, vendorId) {
  if(rowFilters === null) {
    rowFilters = (isAdmin && vendorId) ? [{"field":"vendor-id","values":[`${vendorId}`]}] : []
  }

  const validRowFilters = rowFilters.filter(rowFilter => columns.map(c => dasherize(c.field)).includes(rowFilter.field))
  if(validRowFilters.length !== rowFilters.length) {
    rowFilters = validRowFilters
  }

  return rowFilters
}

function validateColumnFilters(columnFilters, visibleColumns) {
  if(columnFilters === null) {
    columnFilters = {}
  }

  const validColumnFilters = Object.entries(columnFilters).filter(([columnField, _filter]) => visibleColumns.includes(columnField))
  if(validColumnFilters.length !== Object.entries(columnFilters).length) {
    columnFilters = validColumnFilters
  }

  return columnFilters
}

export default function validateTableSettings(tableSettings: RawTableSettings, columns: Column[], isAdmin: boolean, vendorId: string): TableSettings {
  const visibleColumns = validateVisibleColumns(tableSettings.visibleColumns, columns, isAdmin)
  if(visibleColumns !== tableSettings.visibleColumns) {
    tableSettings = {...tableSettings, visibleColumns}
  }

  const subtotalColumns = validateSubtotalColumns(tableSettings.subtotalColumns, columns)
  if(subtotalColumns !== tableSettings.subtotalColumns) {
    tableSettings = {...tableSettings, subtotalColumns}
  }

  const sortColumn = validateSortColumn(tableSettings.sortColumn, columns)
  if(sortColumn !== tableSettings.sortColumn) {
    tableSettings = {...tableSettings, sortColumn}
  }

  const sortOrder = tableSettings.sortOrder ?? -1
  if(sortOrder !== tableSettings.sortOrder) {
    tableSettings = {...tableSettings, sortOrder}
  }

  const reportingMode = tableSettings.reportingMode ?? getStorage('PerformanceTable.reportingMode') ?? 'cohort'
  if(reportingMode !== tableSettings.reportingMode) {
    tableSettings = {...tableSettings, reportingMode}
  }

  const rowFilters = validateRowFilters(tableSettings.rowFilters, columns, isAdmin, vendorId)
  if(rowFilters !== tableSettings.rowFilters) {
    tableSettings = {...tableSettings, rowFilters}
  }

  const columnFilters = validateColumnFilters(tableSettings.columnFilters, tableSettings.visibleColumns)
  if(columnFilters !== tableSettings.columnFilters) {
    tableSettings = {...tableSettings, columnFilters}
  }

  return tableSettings as TableSettings
}
