import { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import Suspenseful from '../../Suspenseful'
import MTDPacingTable from '../../analytics/MTDPacingTable'
import MTDPacingChart from '../../analytics/MTDPacingChart'
import CheckboxMenu from '../../controls/CheckboxMenu'
import { getIn } from '../../Formik/forms'
import { getRemoteId, store } from '../../../lib/DataModel'
import { useTimezone } from '../../../lib/TimezoneProvider'
import CurrentMonthPacingResource from '../../../resources/CurrentMonthPacingResource'
import DailyCountsResource from '../../../resources/DailyCountsResource'
import VendorsResource from '../../../resources/VendorsResource'
import ProgramGroupsResource from '../../../resources/ProgramGroupsResource'
import { sortBy, sum, titlecase } from '../../../lib/utils'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'

import FilterInactiveImage from 'filter-inactive.png'
import FilterActiveImage from 'filter-active.png'
import { CAMPAIGN_TYPES_VALUES } from '../LandingPages/data/constants';
import { useParams } from 'react-router-dom'
import { useRefreshable } from 'lib/hooks';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  container: {
    padding: 30,
    marginTop: 50,
  },
  settings: {
    fontSize: 18,
    fontWeight: 600,
    color: '#00000080',
  },
  button: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
  },
  setting: {
    display: 'inline-block',
    '$setting + &': {
      marginLeft: 32,
    },
  },
  filterIcon: {
    width: 12,
    height: 8.5,
    marginLeft: 12,
  },
})

function CustomDropDown({label, options, value, setValue, renderValue}) {
  const classes = useStyles()
  const popupState = usePopupState({ variant: 'popover', popupId: 'dropDown' })

  const renderedValue = useMemo(() => renderValue(value), [renderValue, value])
  const allOptions = useMemo(() => [null, ...options], [options])

  const isChecked = useCallback(val => (!val && !value) || (value === val), [value])
  const handleChange = useCallback(val => _event => {
    setValue(val)
    popupState.close()
  }, [popupState, setValue])

  return (
    <div className={classes.setting}>
      {label}:
      <Button variant="text" {...bindTrigger(popupState)} className={clsx({[classes.button]: true, [classes.buttonActive]: popupState.isOpen})} aria-label={`Select ${label}`}>
        <span className={classes.currentRange}>
          {renderedValue}
        </span>
        <img alt='filter' src={popupState.isOpen ? FilterActiveImage : FilterInactiveImage} className={classes.filterIcon}/>
      </Button>
      <CheckboxMenu
        {...bindPopover(popupState)}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        menuOptions={allOptions}
        reorderable={false}
        isChecked={isChecked}
        render={renderValue}
        onChange={handleChange}
      />
    </div>
  )
}

function BreakdownPacingChart({dailyCountsResource, currentMonthPacingResource, vendorsResource}) {
  const classes = useStyles()

  const currentMonthPacing = currentMonthPacingResource.get()
  const { year, month, currentDay } = dailyCountsResource
  const [vendor, setVendor] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [campaignType, setCampaignType] = useState(null)

  const monthlyCap = useMemo(() => (
    sum(currentMonthPacing
      .filter(pacingRow => !vendor || pacingRow.vendorId === getRemoteId(vendor))
      .filter(pacingRow => !selectedRow || pacingRow.clientCampaignId === selectedRow.clientCampaignId)
      .filter(pacingRow => !campaignType || pacingRow.campaignType === campaignType)
    , 'monthlyCap')
  ), [currentMonthPacing, vendor, selectedRow, campaignType])

  const vendors = useMemo(() => sortBy(vendorsResource.get(), 'attributes.name'), [vendorsResource])

  const dailyCounts = useMemo(() => (
    dailyCountsResource.get()
      .filter(dailyCount => !vendor || getIn(store.cache.query(q => q.findRelatedRecord(store.cache.query(q => q.findRelatedRecord(store.cache.query(q => q.findRelatedRecord(dailyCount, 'clientCampaign')), 'contract')), 'vendor')), 'id') === vendor.id)
      .filter(dailyCount => !selectedRow || String(getRemoteId(store.cache.query(q => q.findRelatedRecord(dailyCount, 'clientCampaign')))) === String(selectedRow.clientCampaignId))
      .filter(dailyCount => !campaignType || getIn(store.cache.query(q => q.findRelatedRecord(dailyCount, 'clientCampaign')), 'attributes.campaignType') === campaignType)
  ), [dailyCountsResource, vendor, selectedRow, campaignType])

  const renderVendor = useCallback(vendor => vendor ? vendor.attributes.name : 'All', [])
  const renderClientCampaign = useCallback(selectedRow => selectedRow ? selectedRow.name : 'All', [])
  const renderCampaignType = useCallback(campaignType => titlecase(campaignType) || 'All', [])

  return (
    <>
      <div className={classes.settings}>
        <CustomDropDown label="Vendor" value={vendor} renderValue={renderVendor} setValue={setVendor} options={vendors}/>
        <CustomDropDown label="Campaign" value={selectedRow} renderValue={renderClientCampaign} setValue={setSelectedRow} options={sortBy(currentMonthPacing, 'name')}/>
        <CustomDropDown label="Campaign Type" value={campaignType} renderValue={renderCampaignType} setValue={setCampaignType} options={CAMPAIGN_TYPES_VALUES}/>
      </div>

      <MTDPacingChart
        year={year}
        month={month}
        currentDay={currentDay}
        dailyCounts={dailyCounts}
        monthlyCap={monthlyCap}
        height={300}
      />
    </>
  )
}

export default function PacingView() {
  const classes = useStyles()
  const { clientId } = useParams()
  const [currentMonthPacingResource, refreshCurrentMonthPacingResource] = useRefreshable(() => new CurrentMonthPacingResource({clientId}), [clientId])
  const { timezone } = useTimezone()
  const dailyCountsResource = useMemo(() => new DailyCountsResource({clientId, timezone}), [clientId, timezone])
  const [vendorsResource] = useState(() => new VendorsResource())
  const [programGroupsResource] = useState(() => new ProgramGroupsResource(clientId && {type: 'client', id: clientId}))
  const vendorMapPromise = useMemo(() => vendorsResource.promise.then(vendors => Object.fromEntries(vendors.map(vendor => [getRemoteId(vendor), vendor.attributes.name]))), [vendorsResource])
  const groupingColumns = useMemo(() => [
    { title: 'Vendor', field: 'vendorId', lookup: vendorMapPromise, summary: { text: 'Total' }, visible: true },
  ], [vendorMapPromise])

  return (
    <>
      <Typography variant="h2" sx={{ mt: 4, mb: 3}}>Pacing</Typography>

      <MTDPacingTable
        groupingColumns={groupingColumns}
        currentMonthPacingResource={currentMonthPacingResource}
        refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource}
      />

      <Paper className={classes.container}>
        <Suspenseful
          component={BreakdownPacingChart}
          vendorsResource={vendorsResource}
          programGroupsResource={programGroupsResource}
          currentMonthPacingResource={currentMonthPacingResource}
          dailyCountsResource={dailyCountsResource}
        />
      </Paper>
    </>
  )
}
