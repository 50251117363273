import { remote } from '../lib/DataModel'

import PromisedResource from './PromisedResource'

function getOptions(year, month) {
  return {
    sources: {
      remote: {
        settings: {
          params: {
            year: year,
            month: month,
          }
        }
      }
    }
  }
}

export default class AllocationsResource extends PromisedResource {
  constructor({contractOrClient, month, year}) {
    if(contractOrClient) {
      super(remote.query(q => q.findRelatedRecords(contractOrClient, 'allocations'), getOptions(year, month)))
    } else {
      super(remote.query(q => q.findRecords('allocation'), getOptions(year, month)))
    }
  }
}
