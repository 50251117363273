import { useCallback, useMemo } from 'react'
import { getIn, getNestedValuePath, createDeleteNestedValue, createSetNestedValue, useFormikContext } from '../Formik/forms'

import Button from '@mui/material/Button'

export default function ForEachNestedValue({path, buttonText, newValue, component: Component}) {
  const { values, setFieldValue } = useFormikContext()
  const setNestedValue = useMemo(() => createSetNestedValue({values, setFieldValue}), [values, setFieldValue])
  const deleteNestedValue = useMemo(() => createDeleteNestedValue({values, setFieldValue}), [values, setFieldValue])
  const valuesPath = useMemo(() => getNestedValuePath(values, setFieldValue, path), [values, setFieldValue, path])
  const nestedValues = useMemo(() => getIn(values, valuesPath) || [], [values, valuesPath])

  const handleAdd = useCallback(() => {
    setNestedValue(path, newValue())
  }, [setNestedValue, path, newValue])

  const handleRemove = useCallback((item) => {
    deleteNestedValue(path, item.__record)
  }, [deleteNestedValue, path])

  return (
    <>
      {nestedValues.map((item, index) => (
        !item._destroy && (
          <Component
            key={index}
            item={item}
            path={`${valuesPath}[${index}]`}
            onRemove={() => handleRemove(item)}
          />
        )
      ))}
      <Button onClick={handleAdd} sx={{mt: 2}}>{buttonText || 'Add'}</Button>
    </>
  )
}
