import { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import DeleteIcon from '@mui/icons-material/Delete'
import PeopleIcon from '@mui/icons-material/People';

import Accordion from '../../Accordion'
import AccordionPanel from '../../AccordionPanel'
import InputMaskTextField from '../../InputMaskTextField'
import Suspenseful from '../../Suspenseful'
import VendorResource from '../../../resources/VendorResource'
import { STATES } from '../../../lib/states'
import { getIn, Form, FormSelect, FormTextField } from '../../Formik/forms'
import MainContainer from 'components/layout/MainContainer/MainContainer'
import { useNotifications } from 'lib/NotificationsProvider'

function VendorEdit({resource}) {
  const { addNotification } = useNotifications()
  const [vendor, setVendor] = useState(resource.get())
  const navigate = useNavigate()

  const deleteVendor = ({deleteRecord}) => () => {
    if (!confirm(`Are you sure you want to delete ${vendor.attributes.name}?`)) {
      return
    }
    deleteRecord(vendor).then(() => {
      addNotification({variant: 'notice', message: `Deleted vendor ${vendor.attributes.name}`})
      navigate(`/vendors`)
    })
  }

  return (
    <MainContainer>
      <Form resource={vendor} setResource={setVendor} backwardsCompatibleMargins={true}>
        {({deleteRecord, isNew}) => (
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  {isNew ? 'New' : 'Edit'} vendor
                </Typography>
              </Grid>
              <Grid item>
                {!isNew && (
                  <Tooltip title="Delete this vendor">
                    <IconButton aria-label="Delete this vendor" onClick={deleteVendor({deleteRecord})} size="large">
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                )}
                {getIn(vendor, 'relationships.team.data.id') && (
                  <Tooltip title="Manage team members">
                    <IconButton aria-label="Manage team members" onClick={() => navigate(`/teams/${getIn(vendor, 'relationships.team.data.id')}/users`)} size="large">
                      <PeopleIcon/>
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <Accordion defaultPanelId="panel1">
              <AccordionPanel label="Basic details" panelId="panel1">
                <div>
                  <FormTextField
                    label="Name"
                    name="name"
                  />
                  <FormTextField
                    label="Contact Name"
                    name="contactName"
                  />
                  <FormTextField
                    label="Contact Email"
                    name="contactEmail"
                  />
                  <FormTextField
                    label="Address"
                    name="streetAddress"
                  />
                  <FormTextField
                    label="City"
                    name="city"
                  />
                  <FormSelect
                    label="State"
                    name="state"
                  >
                    <MenuItem value={''}></MenuItem>
                    {STATES.map(state => (
                      <MenuItem value={state.abbreviation} key={state.abbreviation}>{state.abbreviation}</MenuItem>
                    ))}
                  </FormSelect>
                  <FormTextField
                    label="ZIP"
                    name="zipCode"
                  />
                  <FormTextField
                    label="Phone Number"
                    name="phone"
                    InputProps={{
                      inputComponent: InputMaskTextField,
                      inputProps: {
                        mask: '(299) 299-9999',
                        definitions: {
                          '2': {
                            validator: '[2-9]',
                          }
                        }
                      },
                    }}
                  />
                </div>
              </AccordionPanel>
            </Accordion>
          </>
        )}
      </Form>
    </MainContainer>
  );
}

export default function VendorEditWrapper() {
  const { vendorId } = useParams()
  const resource = useMemo(() => new VendorResource(vendorId), [vendorId])
  return (
   <Suspenseful
     component={VendorEdit}
     resource={resource}
    />
  )
}
