import { useCallback, useEffect } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function Dropdown({
  className,
  label,
  collection,
  item,
  setItem,
  renderItem,
  selectFirstByDefault,
  disabled = false,
  loading = false
}) {
  const handleChange = useCallback(event => {
    setItem(collection.find(i => i.id === event.target.value))
  }, [collection, setItem])

  useEffect(() => {
    const itemExists = !!item
    if(selectFirstByDefault && collection && !itemExists) {
      setItem(collection[0])
    }
  }, [item, collection, selectFirstByDefault, setItem])

  return (
    <FormControl className={className} fullWidth>
      <InputLabel id="resource-item-select">{label}</InputLabel>
      <Select
        labelId="resource-item-select"
        label={label}
        value={item && item.id || ''}
        onChange={handleChange}
        disabled={disabled}
        sx={{
          minWidth: '251px',
          maxWidth: '251px',
          minHeight: '50px',
          maxHeight: '50px',
          fontSize: '18px',
        }}
      >
        { loading && (
          <MenuItem value="-">
            Please wait...
          </MenuItem>
        )}

        {!loading && collection && collection.map(itemOption => (
          <MenuItem key={itemOption.id} value={itemOption.id}>
            { renderItem(itemOption) }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
