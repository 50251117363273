import { useSuspenseQuery } from "@apollo/client"
import { useMemo } from "react"
import { uniq, omit, map } from 'lodash-es'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY, GET_FILTERED_LEADS_CLIENTS_QUERY } from 'components/pages/LandingPages/data/queries'
import Suspenseful from 'components/Suspenseful'
import {  ErrorWrapper } from 'components/UI/StyledCard'
import { getIn, PersistedErrorHandler, useFormikContext } from 'components/Formik/forms'
import { generateNewRecordId } from "components/pages/LandingPages/data/utils"
import { getRemoteId } from "lib/DataModel"
import { useCurrentUser } from "lib/CurrentUserProvider"
import useId from '@mui/utils/useId'
import SubsectionTitle from "components/UI/SubsectionTitle.jsx/SubsectionTitle"
import Stack from "@mui/material/Stack"


function ClientSelection({ filteredLeadsClients, selectedFilteredLeadsClient }) {
  const textFieldId = useId()

  return (
    <TextField
      select
      value={selectedFilteredLeadsClient.id}
      label='Client'
      fullWidth
      id={textFieldId}
      disabled
    >
      {filteredLeadsClients.map((client) => (
        <MenuItem value={client.id} key={`filtered_campaign_client_${client.id}`}>{client.internalName || client.name}</MenuItem>
      ))}
    </TextField>
  )
}

function getAllCampaignsForDegreeProgram(degreeProgram, allClientCampaignsWithDegreePrograms) {
  if(!degreeProgram) { return null }

  const clientCampaignsWithDegeeProgramsForProgram = allClientCampaignsWithDegreePrograms.filter(entry => entry.degreePrograms.find(dp => dp.id === degreeProgram.id))
  if(clientCampaignsWithDegeeProgramsForProgram.length === 0) {
    return null
  }

  return clientCampaignsWithDegeeProgramsForProgram.map(entry => omit(entry, entry.degreePrograms))
}

function CampaignSelection({allClientCampaignsForClient, allClientCampaignsWithDegreePrograms}) {
  const { values, setFieldValue } = useFormikContext()
  const fallbackLeadTarget = getIn(values, 'fallbackLeadTarget')

  const selectableClientCampaigns = getAllCampaignsForDegreeProgram(fallbackLeadTarget?.degreeProgram, allClientCampaignsWithDegreePrograms) || allClientCampaignsForClient

  const handleOnChangeCampaign = event => {
    const selectedCampaign = event.target.value
    if(!selectedCampaign) {
      if(fallbackLeadTarget?.degreeProgram) {
        setFieldValue('fallbackLeadTarget.clientCampaign', null)
      } else {
        setFieldValue('fallbackLeadTarget', null)
      }

      return
    }

    if(values.fallbackLeadTarget) {
      setFieldValue('fallbackLeadTarget.clientCampaign', selectedCampaign)
    } else {
      const newFallbackLeadTarget = {
        id: generateNewRecordId(),
        clientCampaign: selectedCampaign,
        degreeProgram: null,
      }
      setFieldValue('fallbackLeadTarget', newFallbackLeadTarget)
    }
  }

  return (
    <PersistedErrorHandler name="fallbackLeadTarget.clientCampaign">
      <TextField
        select
        value={selectableClientCampaigns.find(c => c.id === fallbackLeadTarget?.clientCampaign?.id) || ''}
        onChange={handleOnChangeCampaign}
        label='Campaign'
        fullWidth
        id='filtered_campaign_selection_campaign'
      >
        <MenuItem value={''} key={`filtered_campaign_campaign_none`}>None</MenuItem>
        {selectableClientCampaigns.map((campaign) => (
          <MenuItem value={campaign} key={`filtered_campaign_campaign_${campaign.id}`}>{campaign.name}</MenuItem>
        ))}
      </TextField>
    </PersistedErrorHandler>
  )
}

function getSelectableDegreePrograms(fallbackLeadTarget, allClientCampaignsWithDegreePrograms, allDegreeProgramsForClient) {
  if(fallbackLeadTarget?.clientCampaign) {
    const selectableDegreePrograms = allClientCampaignsWithDegreePrograms.find(campaign => campaign.id === fallbackLeadTarget.clientCampaign.id)?.degreePrograms
    if(selectableDegreePrograms) { return selectableDegreePrograms }
  }

  return allDegreeProgramsForClient
}

function ProgramSelection({allDegreeProgramsForClient, allClientCampaignsWithDegreePrograms}) {
  const { values, setFieldValue } = useFormikContext()
  const fallbackLeadTarget = getIn(values, 'fallbackLeadTarget')

  const selectableDegreePrograms = getSelectableDegreePrograms(fallbackLeadTarget, allClientCampaignsWithDegreePrograms, allDegreeProgramsForClient)

  const handleOnChangeProgram = event => {
    const selectedProgram = event.target.value
    if(!selectedProgram) {
      if(fallbackLeadTarget?.clientCampaign) {
        setFieldValue('fallbackLeadTarget.degreeProgram', null)
      } else {
        setFieldValue('fallbackLeadTarget', null)
      }

      return
    }

    if(values.fallbackLeadTarget) {
      setFieldValue('fallbackLeadTarget.degreeProgram', {...selectedProgram})
    } else {
      const newFallbackLeadTarget = {
        id: generateNewRecordId(),
        clientCampaign: null,
        degreeProgram: {...selectedProgram},
      }
      setFieldValue('fallbackLeadTarget', newFallbackLeadTarget)
    }
  }

  return (
    <PersistedErrorHandler name='fallbackLeadTarget.degreeProgram'>
      <TextField
        select
        value={(fallbackLeadTarget?.degreeProgram && selectableDegreePrograms.find(dp => dp.id === fallbackLeadTarget.degreeProgram?.id)) || ''}
        onChange={handleOnChangeProgram}
        label='Program'
        fullWidth
        id='filtered_campaign_selection_program'
      >
        <MenuItem value={''} key={`filtered_campaign_campaign_none`}>None</MenuItem>
        {selectableDegreePrograms.map((program) => (
          <MenuItem value={program} key={`filtered_campaign_campaign_${program.id}`}>{program.name}</MenuItem>
        ))}
      </TextField>
    </PersistedErrorHandler>
  )
}

function FilteredCampaignSelection({ selectedFilteredLeadsClient, filteredLeadsClients }) {
  const { currentTeam } = useCurrentUser()
  const vendorId = getRemoteId(getIn(currentTeam, 'relationships.vendor.data'))
  const { data: { clientCampaigns: allClientCampaignsForClient } } = useSuspenseQuery(GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY, { variables: { programGroupIds: map(selectedFilteredLeadsClient.programGroups, 'id'), vendorId: vendorId }, fetchPolicy: 'network-only'})

  const allClientCampaignsWithDegreePrograms = allClientCampaignsForClient.map(campaign => {
    const degreePrograms = selectedFilteredLeadsClient.programGroups.find(group => group.id === campaign.programGroup.id).degreePrograms
    return {...campaign, degreePrograms: degreePrograms}
  })

  const allDegreeProgramsForClient = uniq(allClientCampaignsWithDegreePrograms.flatMap(entry => entry.degreePrograms))

  return (
    <Stack direction='row' spacing={2} marginBottom={1} role="listitem" aria-roledescription="Filtered Campaign Selection">
      <ClientSelection
        filteredLeadsClients={filteredLeadsClients}
        selectedFilteredLeadsClient={selectedFilteredLeadsClient}
      />
      <ProgramSelection
        allDegreeProgramsForClient={allDegreeProgramsForClient}
        allClientCampaignsWithDegreePrograms={allClientCampaignsWithDegreePrograms}
      />
      <CampaignSelection
        allClientCampaignsForClient={allClientCampaignsForClient}
        allClientCampaignsWithDegreePrograms={allClientCampaignsWithDegreePrograms}
      />
    </Stack>
  )
}

function FilteredCampaignSelectionQuery() {
  const { data: clientData } = useSuspenseQuery(GET_FILTERED_LEADS_CLIENTS_QUERY, { variables: {}, fetchPolicy: 'network-only'})
  const filteredLeadsClients = clientData.filteredLeadsClients
  const { initialValues } = useFormikContext()
  const selectedFilteredLeadsClient = useMemo(() => {
    const fallbackLeadTarget = getIn(initialValues, 'fallbackLeadTarget')
    return filteredLeadsClients.find(client => client.id === fallbackLeadTarget?.clientCampaign?.programGroup.client.id) || filteredLeadsClients[0]
  }, [initialValues, filteredLeadsClients])

  return (
    <ErrorWrapper>
      <Stack direction='column' spacing={2} aria-label={'Filtered Campaign Selection'}>
        <SubsectionTitle title='Filtered Campaign Selection' />
        {selectedFilteredLeadsClient && (
          <FilteredCampaignSelection
            selectedFilteredLeadsClient={selectedFilteredLeadsClient}
            filteredLeadsClients={filteredLeadsClients}
          />
        )}
      </Stack>
    </ErrorWrapper>
  )
}

export default function FilteredCampaignSelectionWrapper() {
  return (
    <Suspenseful component={FilteredCampaignSelectionQuery} />
  )
}
