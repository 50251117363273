import { getIn } from 'formik'
import { Base64 } from 'js-base64'
import { isEqualWith } from 'lodash-es'
import sortBy from './utils/sort-by'

export function toCamelCase(str: string) {
  return str.replace(/[-_]([a-z0-9])/g, function(match) { return match[1].toUpperCase() })
}

export function dashToCamelCase(str: string) {
  return str.replace(/-([a-z])/g, function(match) { return match[1].toUpperCase() })
}

export function dashToUnderscore(str: string) {
  return str.replace(/-/g, '_')
}

export function bareInfinitive(word?: string | null) {
  if(typeof(word) !== 'string') {
    return ''
  }

  // Leave phrases unmodified; only modify word if it's a single word
  if(word.match(/[^a-zA-Z]/)) {
    return word
  }

  if(word === 'Applied') {
    return 'Apply'
  }
  else if(word === 'Qualified') {
    return 'Qualify'
  }
  else if(word === 'Admitted') {
    return 'Admit'
  }

  return word.replace(/ed$/, '')
}

export async function failOnHttpError(response: Response): Promise<Response> {
  if(response.status < 400) {
    return response
  }

  const json = await response.json()
  throw new Error(json.message || `Failed with HTTP status ${response.status}`)
}

export function getStorage(key: string) {
  const item = localStorage.getItem(key)
  if(typeof(item) !== 'string') {
    return
  }

  try {
    return JSON.parse(item)
  } catch(e) {
    return
  }
}

export function setStorage<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.addEventListener('error', error => reject(error))
    reader.addEventListener('abort', () => reject('Upload aborted'))
    reader.readAsText(file)
  })
}

export function stripHTML(str?: string) {
  if(!str) {
    return ''
  }
  return str.replace(/<.*?>/g, '')
}

export function setPath(obj : Record<string,unknown>, path: string, value: unknown) {
  let currentObj = obj
  path.split('.').slice(0,-1).forEach(key => {
    if(!(key in currentObj) || typeof(currentObj[key]) !== 'object' || currentObj[key] === null) {
      const nextObj = {}
      currentObj[key] = nextObj
    }
    currentObj = currentObj[key] as Record<string,unknown>
  })
  currentObj[path.split('.').slice(-1)[0]] = value
}

export function groupBy<Item,GroupKey>(
  array : Item[],
  grouper : (item: Item) => GroupKey, {
    comparator = (a : GroupKey, b : GroupKey) => a === b,
    transform = (item : Item) => item,
    asEntries = false
  } = {}
) {
  if(typeof(grouper) === 'string') {
    const key = grouper
    grouper = value => getIn(value, key)
  }

  const entries = array.reduce((groups: [GroupKey, Item[]][], item: Item) => {
    const group = grouper(item)
    const groupAndItems : [GroupKey, Item[]] = groups.find(([otherGroup, _]) => comparator(otherGroup, group)) || [group, []]
    if(groupAndItems[1].length === 0) {
      groups.push(groupAndItems)
    }
    groupAndItems[1].push(transform(item))
    return groups
  }, [])

  if(asEntries) {
    return entries
  } else {
    return Object.fromEntries(entries)
  }
}

export function invert(object: Record<string,string>) {
  return Object.fromEntries(Object.entries(object).map(([k, v]) => [v, k]))
}

export function difference<T>(array1: T[], array2: T[]) {
  return array1.filter(element => !array2.includes(element))
}

export function* generateRange(start: number, end: number) {
  for(let i=start; i<=end; ++i) {
    yield i
  }
}

export function humanize(str?: string | null) {
  if(!str) {
    return ""
  }

  return str.toString().replace(/[_-]/g, ' ')
}

export function titlecase(str?: string | null) {
  if(!str) {
    return ""
  }

  return humanize(str).replace(/\b[a-z]/g, char => char.toUpperCase())
}

export function constantize(str?: string | null) {
  return titlecase(str).replace(/ /g, '')
}

export function pluralize(str?: string | null) {
  if(typeof(str) !== 'string') {
    return ''
  }

  if(str.match(/s$/)) {
    return str
  }

  return `${str}s`
}

export function toSentence(list: string[], joinWith: string, oxfordComma = true) {
  switch(list.length) {
    case 0:
      return ''
    case 1:
      return list[0]
    default:
      {
        const rest = [...list]
        const last = rest.pop()
        return rest.join(', ') + (list.length > 2 && oxfordComma ? ', ' : '') + ` ${joinWith} ${last}`
      }
  }
}

export function toPercent(num : number | string, decimals = 2) {
  if(typeof(num) === 'string') {
    num = parseFloat(num)
  }

  if(isFinite(num)) {
    return `${(num * 100).toFixed(decimals)}%`
  }
  else {
    return ''
  }
}

export function toDollars(cents?: string | number | null, options = { style: 'currency', currency: 'USD' }) {
  const parsed = parseNumber(cents, { fallback: null })
  if(parsed === null) {
    return ''
  }

  return (parsed / 100).toLocaleString('en-US', options)
}

interface ToFormattedNumberOptions {
  maximumFractionDigits?: number
  fallback?: string
}

export function toFormattedNumber(num?: string | number | null, options: ToFormattedNumberOptions = { maximumFractionDigits: 0, fallback: '' }) {
  const parsed = parseNumber(num, { fallback: null })
  if(parsed === null) {
    return options.fallback
  }

  return parsed.toLocaleString('en-US', options)
}


export function parseNumber<T=number>(num?: string | number | null, { fallback = 0 } : { fallback? : number | T } = {}) : number | T {
  if(typeof(num) !== 'string' && typeof(num) !== 'number') {
    return fallback
  }

  const parsed = typeof(num) === 'number' ? num : parseFloat(num)
  return isFinite(parsed) ? parsed : fallback
}

export function sum(rows: Record<string,string | number>[], column: string): number {
  return rows.map(row => (getIn(row, column) || 0) as number).reduce((a, b) => parseNumber(a)+parseNumber(b), 0)
}

let uniqueId = 0

export function generateId() {
  return ++uniqueId
}

export function toggleList<Item>(list: Item[], item: Item, include?: boolean) {
  if(typeof(include) !== 'boolean') {
    include = !list.includes(item)
  }

  if(include) {
    if(!list.includes(item)) {
      return [...list, item]
    }
    else {
      return list
    }
  }
  else {
    return list.filter(entry => entry !== item)
  }
}

export function buildQueryString(queryParams: Record<string,string|string[]|Record<string,string>>) {
  const parts: string[] = []
  Object.entries(queryParams).forEach(([key, value]) => {
    if(value === undefined || value === null) {
      return
    }

    if(value instanceof Array) {
      value.forEach(item => {
        parts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
      })
    } else if(typeof(value) === 'object') {
      Object.entries(value).forEach(([subkey, item]) => {
        parts.push(`${encodeURIComponent(key)}[${subkey}]=${encodeURIComponent(item)}`)
      })
    } else {
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  })
  return parts.join('&')
}

export function download(url: string, filename: string) {
  const link = document.createElement('a')
  link.target = "_blank"
  link.href = url
  link.download = filename
  link.click()
}

export function downloadData(content: string, filename: string, mime_type: string) {
  const dataUrl = `data:${mime_type};base64,${Base64.encode(content)}`
  download(dataUrl, filename)
}

export function isPresent(value: unknown) {
  if(value instanceof Array) {
    return value.length !== 0
  } else if(typeof(value) === 'string') {
    return value.trim() !== ''
  } else if(value instanceof Object) {
    return Object.keys(value).length !== 0
  } else {
    return value !== null && value !== undefined && value !== false
  }
}

export function isBlank(value: unknown) {
  return !isPresent(value)
}

export function arrayDiff<T>(array1: T[], array2: T[]) {
  return array1.filter(value => !array2.includes(value))
}

export function arrayUnion<T>(array1: T[], array2: T[]) {
  return [...arrayDiff(array1, array2), ...array2]
}

export function roundEstimate(value?: number) {
  if(typeof(value) !== 'number' || !isFinite(value)) {
    return 'N/A'
  }

  if(value < 0) {
    return `-${roundEstimate(-value)}`
  }

  value = Math.round(value)
  if(value < 1e3) {
    return value.toFixed(0)
  } else if(value < 1e5) {
    return `${(value/1e3).toFixed(1)}k`
  } else if(value < 1e6) {
    return `${(value/1e3).toFixed(0)}k`
  } else if(value < 1e8) {
    return `${(value/1e6).toFixed(1)}m`
  } else {
    return `${(value/1e6).toFixed(0)}m`
  }
}

export function isPromise(object: unknown) {
  return typeof(object) === 'object' && object !== null && 'then' in object && typeof(object.then) === 'function'
}

export function spliceCopy<T>(array: T[], index: number, numToDelete: number, ...replacement: T[]) {
  if(index < 0) {
    index = array.length
  }


  return [...array.slice(0, index), ...replacement, ...array.slice(index + numToDelete)]
}

export function replaceElement<T>(array: T[], element: T, ...replacement: T[]) {
  return spliceCopy(array, array.indexOf(element), 1, ...replacement)
}

export function multiplyOrNull(a?: string | number | null, b?: string | number | null) {
  const parsedA = parseNumber(a, { fallback: null })
  const parsedB = parseNumber(b, { fallback: null })

  if(parsedA === null || parsedB === null) {
    return null
  }

  return parsedA * parsedB
}

export function partition<T>(list: T[], numberOfPartitions: number, { minimumItemsPerPartition = 0 } = {}) {
  const partitions: T[][] = []
  const remainingItems: T[] = [...list]

  while(remainingItems.length > 0) {
    const remainingPartitions = numberOfPartitions - partitions.length
    const partitionSize = Math.max(Math.ceil(remainingItems.length / remainingPartitions), minimumItemsPerPartition)
    partitions.push(remainingItems.splice(0, partitionSize))
  }

  return partitions
}

export function delay<T>(promise: Promise<T>) {
  return new Promise(resolver => {
    promise.then(result => {
      console.log("delaying result")
      setTimeout(() => resolver(result), 2000)
    })
  })
}

export { sortBy }

export function deepEqual(obj1: unknown, obj2: unknown) {
  if(obj1 === obj2) return true

  if(typeof obj1 !== 'object' ||
     typeof obj2 !== 'object' ||
     obj1 == null ||
     obj2 == null
    ) { return false }

  const keysA = Object.keys(obj1)
  const keysB = Object.keys(obj2)

  if(keysA.length !== keysB.length) { return false }

  let result = true

  keysA.forEach((key) => {
    if(!keysB.includes(key)) {
        result = false
    }

    if( typeof obj1[key] === 'function' ||
        typeof obj2[key] === 'function'
    ) {
        if(obj1[key].toString() !== obj2[key].toString()) { result = false }
    }

    if (!deepEqual(obj1[key], obj2[key])) { result = false }
  })

  return result
}

export function isShallowEqual(obj1: unknown, obj2: unknown, maxDepth = 1) {
  return isEqualWith(obj1, obj2, (left, right, _index, _obj, _otherObj, stack) => {
    const depth = (stack?.size || 0) / 2
    if(depth >= maxDepth) {
      return left === right
    }
  })
}

export function isTruthy<T> (value: T | undefined | null | false): value is T {
  return !!value
}

export function notNullish<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}
