import styled from "@mui/system/styled"
import Select from '@mui/material/Select'
import ArrowDropDownIcon from './icons/ArrowDropDownIcon'

const SmallArrowDropDownIcon = styled(ArrowDropDownIcon)({
  fontSize: 18,
  top: 'calc(50% - 9px)',
})

export default function SmallSelect({ sx, ...props }) {
  return <Select
    variant="standard"
    IconComponent={SmallArrowDropDownIcon}
    sx={{
      fontSize: 14,
      lineHeight: '24px',
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
      ...sx
    }}
    {...props}
  />
}
