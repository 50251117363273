import { useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from "react-router-dom";
import Accordion from '../../Accordion';
import AccordionPanel from '../../AccordionPanel';
import InputMaskTextField from '../../InputMaskTextField'
import Button from '@mui/material/Button';
import { Form, FormCheckbox, FormTextField, FormFilePicker, FormImagePicker, FormColorPicker, FormSelect } from '../../Formik/forms';
import ContractManagement from '../../ContractManagement'
import ContractsResource from '../../../resources/ContractsResource'
import Suspenseful from '../../Suspenseful'

import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';

import { STATES } from '../../../lib/states'
import { download } from '../../../lib/utils'
import { getRemoteId, isNew, remote, newRecord } from '../../../lib/DataModel'
import { useNotifications } from 'lib/NotificationsProvider';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  breadcrumbPaper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  grayPaper: {
    backgroundColor: '#eee',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  clientRow: {
    backgroundColor: '#f0f0f0',
  },
  programRow: {
    backgroundColor: '#f8f8f8',
  },
  clientName: {
    fontWeight: 700,
  },
  programName: {
    width: 40,
  },
  tableBody: {
    '&::before': {
      borderLeft: '1px solid transparent',
      content: '""',
      display: 'table-row',
      height: theme.spacing(3),
    },
    border: '1px solid #eee',
  },
}));

function fetchClient(id) {
  return remote.query(q => q.findRecord({type: "client", id: id}))
}

function newClient() {
  return newRecord({
    type: "client",
    attributes: {
      responseTime: 'five_minutes',
      implicitTcpa: false,
      aor: false,
      timeZone: 'America/New_York',
    }
  })
}

export default function ClientEdit() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addNotification } = useNotifications()
  const [client, setClient] = useState();
  const [complyedClients, setComplyedClients] = useState();
  const [loading, setLoading] = useState(true);
  const [hack, setHack] = useState({}) // TODO: remove once we've switched to useRefreshableResource
  const refreshContractsResource = useCallback(() => { setHack({}) }, [setHack]) // TODO: switch to useRefreshableResource
  const { clientId } = useParams()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contractsResource = useMemo(() => clientId ? new ContractsResource({clientId: clientId}) : null, [clientId, hack])

  useEffect(() => {
    const promises = [
      clientId ? fetchClient(clientId) : newClient(),
      remote.query(q => q.findRecords('complyedClient')),
    ];

    Promise.all(promises).then(([client, complyedClients]) => {
      setClient(client);
      setComplyedClients(complyedClients);
      setLoading(false);
    }).catch(_error => {
      addNotification({variant: 'alert', message: 'Error loading client'})
      setLoading(false);
    })
  }, [addNotification, clientId]);

  if(loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    const deleteClient = ({deleteRecord}) => () => {
      if (!confirm(`Are you sure you want to delete ${client.attributes.name}?`)) {
        return;
      }
      deleteRecord(client).then(() => {
        addNotification({variant: 'notice', message: `Deleted client ${client.attributes.name}`})
        navigate(`/`)
      })
    };

    return (
      <Form resource={client} setResource={setClient} backwardsCompatibleMargins={true}>
        {({values, deleteRecord}) => (
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h2" sx={{ mt: 4, mb: 3 }}>
                  {isNew(client) ? 'New Client' : 'Client Information'}
                </Typography>
              </Grid>
              <Grid item>
                {!isNew(client) && (
                  <Tooltip title="Delete this client">
                    <IconButton aria-label="Delete this client" onClick={deleteClient({deleteRecord})} size="large">
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Manage integrations">
                  <IconButton aria-label="Manage integrations" onClick={() => navigate(`/clients/${getRemoteId(client)}/ad-campaign-sets`)} size="large">
                    <SettingsInputCompositeIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Accordion defaultPanelId="panel1">
              <AccordionPanel label="Basic details" panelId="panel1">
                <div className={classes.container}>
                  <FormTextField
                    label="Name"
                    name="name"
                  />
                  <FormTextField
                    label="Internal Name"
                    name="internalName"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Same as name"
                  />
                  <FormTextField
                    label="Time zone"
                    name="timeZone"
                  />
                </div>
              </AccordionPanel>
              <AccordionPanel label="Appearance" panelId="panel2">
                <div className={classes.container}>
                  <FormImagePicker
                    name="logo"
                    label="Logo"
                    buttonText="Select logo"
                  />
                  <FormTextField
                    label="TCPA Disclosure"
                    name="tcpaDisclosure"
                    placeholder="Use default TCPA disclosure"
                    InputLabelProps={{ shrink: true }}
                    multiline
                  />
                  <FormCheckbox
                    label='Omit TCPA checkbox'
                    name="implicitTcpa"
                  />
                  <FormImagePicker
                    name="complianceLogo"
                    label={<>Compliance<br/>Image</>}
                    buttonText="Select compliance image"
                  />
                  <FormColorPicker
                    name="primaryColor"
                    label="Primary color"
                    placeholder="#00355f"
                  />
                  <FormColorPicker
                    name="secondaryColor"
                    label="Secondary color"
                    placeholder="#00355f"
                  />
                </div>
              </AccordionPanel>
              <AccordionPanel label="AOR" panelId="panelAOR">
                <div className={classes.container}>
                  <FormCheckbox
                    label="AOR Client"
                    name="aor"
                  />
                  {values.aor && (
                    <>
                      <Typography variant="h6">
                        Contact Information
                      </Typography>
                      <Grid container alignItems="stretch" spacing={1}>
                        <Grid item style={{flex: 1}}>
                          <FormTextField
                            size="small"
                            margin="dense"
                            label="Contact Name"
                            name="contactName"
                          />
                        </Grid>
                        <Grid item style={{flex: 1}}>
                          <FormTextField
                            size="small"
                            margin="dense"
                            label="Contact Email"
                            name="contactEmail"
                          />
                        </Grid>
                      </Grid>
                      <FormTextField
                        size="small"
                        margin="dense"
                        label="Address"
                        name="contactAddress"
                      />
                      <Grid container alignItems="stretch" spacing={1}>
                        <Grid item style={{flex: 1}}>
                          <FormTextField
                            size="small"
                            margin="dense"
                            label="City"
                            name="contactCity"
                          />
                        </Grid>
                        <Grid item>
                          <FormSelect
                            size="small"
                            margin="dense"
                            label="State"
                            name="contactState"
                          >
                            <MenuItem value={''}></MenuItem>
                            {STATES.map(state => (
                              <MenuItem value={state.abbreviation} key={state.abbreviation}>{state.abbreviation}</MenuItem>
                            ))}
                          </FormSelect>
                        </Grid>
                        <Grid item>
                          <FormTextField
                            size="small"
                            margin="dense"
                            label="ZIP"
                            name="contactZip"
                          />
                        </Grid>
                      </Grid>
                      <FormTextField
                        size="small"
                        margin="dense"
                        label="Phone Number"
                        name="contactPhone"
                        InputProps={{
                          inputComponent: InputMaskTextField,
                          inputProps: {
                            mask: '(299) 299-9999',
                            definitions: {
                              '2': {
                                validator: '[2-9]',
                              },
                            }
                          },
                        }}
                      />

                      <Typography variant="h6" style={{marginTop: 20}}>
                        Brand Guidelines
                      </Typography>

                      <FormFilePicker
                        name="brandGuidelinesDocument"
                        filenameFieldName="brandGuidelinesDocumentFilename"
                        label="Brand Guidelines"
                        buttonText="Select Brand Guidelines Document"
                        extensions={['doc', 'docx', 'pdf']}
                        maxSize={20}
                        renderPreview={(url, filename) => (
                          url && (
                            <Button
                              startIcon={<CloudDownloadOutlinedIcon/>}
                              onClick={() => download(url, filename)}
                            >
                              {filename}
                            </Button>
                          )
                        )}
                      />

                      <Typography variant="h6" style={{marginTop: 20}}>
                        Vendors
                      </Typography>

                      {contractsResource && (
                        <Suspenseful
                          component={ContractManagement}
                          resource={contractsResource}
                          refreshResource={refreshContractsResource}
                          client={client}
                        />
                      ) || (
                        <Typography variant="p">
                          Vendors can be associated with the client once the client has been created.
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </AccordionPanel>
              <AccordionPanel label="Conversion page" panelId="panel4">
                <div className={classes.container}>
                  <FormTextField
                    label="Email sender"
                    name="lpEmailSender"
                    placeholder={values.name}
                  />
                  <FormTextField
                    label="Area codes"
                    name="lpAreaCodes"
                    placeholder="a 212 area code"
                  />
                  <FormTextField
                    label="Continue button URL"
                    name="lpContinueUrl"
                  />
                  <FormSelect
                    label='Response time'
                    name="responseTime"
                  >
                    <MenuItem value="five_minutes">will be calling within the next 5 minutes / will be calling within one business day</MenuItem>
                    <MenuItem value="shortly">will be calling shortly</MenuItem>
                    <MenuItem value="none">(none)</MenuItem>
                  </FormSelect>
                </div>
              </AccordionPanel>
              <AccordionPanel label="Creatives" panelId="panel5">
                <div className={classes.container}>
                  <FormSelect
                    fullWidth={true}
                    name="complyedClientId"
                    label="Client (from ComplyEd)"
                  >
                    <MenuItem value={''}>(None)</MenuItem>
                    {complyedClients.map(complyedClient => (
                      <MenuItem value={complyedClient.id} key={complyedClient.id}>{complyedClient.attributes.name}</MenuItem>
                    ))}
                  </FormSelect>
                </div>
              </AccordionPanel>
              <AccordionPanel label="Notes" panelId="panel7">
                <div className={classes.container}>
                  <FormTextField
                    label="Notes"
                    name="notes"
                    multiline
                    rows={8}
                  />
                </div>
              </AccordionPanel>
            </Accordion>
          </>
        )}
      </Form>
    );
  }
}
