import { useMemo, useEffect, useCallback, useState } from 'react'
import { useFormikContext, Formik, Form, getIn } from 'formik'
import { useSuspenseQuery } from '@apollo/client'
import makeStyles from '@mui/styles/makeStyles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import COLORS from '../../../../../lib/colors'
import { stripHTML } from '../../../../../lib/utils'
import Box from '@mui/material/Box'
import Suspenseful from '../../../../Suspenseful'
import DialogContentWithBookends from './DialogContentWithBookends'
import { GET_CLIENT_CAMPAIGNS_QUERY, GET_PROGRAM_GROUPS_QUERY } from '../../data/queries'
import { getRemoteId } from 'lib/DataModel'
import { useCurrentUser } from 'lib/CurrentUserProvider'


const useStyles = makeStyles({
  root: {
    marginLeft: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'start',
    margin: '0 0 8px -6px',
    marginRight: 0,
  },
  labelInner: {
    lineHeight: 1,
  },
  checkbox: {
    padding: '2px 4px',
    marginTop: -6,
    marginRight: 5,
  },
  checkboxChecked: {
    color: COLORS.frenchBlue,
  },
  column: {
    width: (792 - 2*24 - 1) / 2,
  },
  columnDivider: {
    borderLeft: '1px solid #f0f3f5',
    paddingLeft: '36px',
    marginLeft: '10px',
    '&::before': {
      boxSizing: 'content-box'
    },
    '&::after': {
      boxSizing: 'content-box'
    }
  },
})

function LeadTargetClient({clientId, setErrorClientIds, hasError=false}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSuspenseQuery(GET_PROGRAM_GROUPS_QUERY, { variables: { clientId: clientId }})

  return (
    <>
      <Box
        sx={{mb: 2,
            fontSize: '16px',
            color: hasError ? COLORS.copperRed : COLORS.charcoal,
            backgroundColor: hasError ? COLORS.mistyRose : null,
        }}
      >
      <Box sx={{fontWeight: 'bold',}}>{data.programGroups[0].client.internalName || data.programGroups[0].client.name}</Box>
        {data.programGroups.map((group, index) => (
          <ClientCampaigns
            key={group + index.toString()}
            programGroup={group}
            hasError={hasError}
            setErrorClientIds={setErrorClientIds}
          />
        ))}
      </Box>
    </>
  )
}

function ClientCampaigns({programGroup, setErrorClientIds, hasError=false}) {
  const { values, setFieldValue } = useFormikContext()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentTeam } = useCurrentUser()
  const { data } = useSuspenseQuery(GET_CLIENT_CAMPAIGNS_QUERY, { variables: { programGroupIds: [programGroup.id], vendorId: getRemoteId(getIn(currentTeam, 'relationships.vendor.data')) }})

  const selectedLeadTargets = useMemo(() => (
    getIn(values, 'leadTargets') || []
  ), [values])

  const findIndex = useCallback((clientCampaign, degreeProgram) => (
    selectedLeadTargets.findIndex(leadTarget => leadTarget.clientCampaign.id === clientCampaign.id && leadTarget.degreeProgram.id === degreeProgram.id)
  ), [selectedLeadTargets])

  const isChecked = useCallback((clientCampaign, degreeProgram) => (
    findIndex(clientCampaign, degreeProgram) !== -1
  ), [findIndex])

  const handleChange = useCallback((clientCampaign, degreePrograms, checked) => {
    const leadTargets = [...selectedLeadTargets]
    degreePrograms.forEach(degreeProgram => {
      const index = leadTargets.findIndex(leadTarget => leadTarget.clientCampaign.id === clientCampaign.id && leadTarget.degreeProgram.id === degreeProgram.id)
      if(checked && index === -1) {
        leadTargets.push({id: null, clientCampaign: clientCampaign, degreeProgram: degreeProgram})
      } else if(!checked && index !== -1) {
        leadTargets.splice(index, 1)
      }
    })
    setErrorClientIds([])
    setFieldValue('leadTargets', leadTargets)
  }, [selectedLeadTargets, setFieldValue, setErrorClientIds])

  const tree = useMemo(() => (
    data.clientCampaigns.map(clientCampaign => ({
      label: clientCampaign.name,
      onChange: checked => handleChange(clientCampaign, programGroup.degreePrograms, checked),
      hasError: hasError,
      children: programGroup.degreePrograms.map(degreeProgram => ({
        label: stripHTML(degreeProgram.name),
        checked: isChecked(clientCampaign, degreeProgram),
        onChange: checked => handleChange(clientCampaign, [degreeProgram], checked),
      }))
    }))
  ), [data.clientCampaigns, handleChange, hasError, isChecked, programGroup.degreePrograms])

  return (
    <>
      <Box sx={{mt: '6px', mb: '6px',}}>{programGroup.description}</Box>
      {tree.map((item, index) => (
        <NestedCollectionCheckbox
          key={index}
          {...item}
        />
      ))}
    </>
  )
}

function isSubtreeChecked({ checked, children = null}) {
  if(children !== null) {
    return children.some(child => isSubtreeChecked(child))
  } else {
    return Boolean(checked)
  }
}

function NestedCollectionCheckbox({label, checked = null, onChange = null, children = null, disabled = false, hasError = false}) {
  const classes = useStyles()

  const computedChecked = useMemo(() => (
    isSubtreeChecked({checked, children})
  ), [checked, children])

  const handleChange = useCallback((event) => {
    if(typeof(onChange) === 'function') {
      onChange(event.target.checked)
    }
  }, [onChange])

  return (
    <>
      <FormControlLabel
        control={<Checkbox
          sx={{'& .MuiSvgIcon-root': { color: hasError ? COLORS.copperRed : null, } }}
          classes={{root: classes.checkbox, checked: classes.checkboxChecked}}
          checked={computedChecked}
          onChange={handleChange}
          disabled={disabled}
        />}
        label={label}
        classes={{root: classes.label, label: classes.labelInner}}
      />
      {children !== null && (
        <Box sx={{'& .MuiSvgIcon-root': { color: hasError ? COLORS.copperRed : null, } }} ml={4}>
          {children.map((child, index) => (
            <NestedCollectionCheckbox
              key={index}
              {...child}
            />
          ))}
        </Box>
      )}
    </>
  )
}

function LeadTargetFormBody({clientIds, setLandingPage, setErrorClientIds, errorClientIds}) {
  const { values } = useFormikContext()
  const classes = useStyles()

  useEffect(() => {
    setLandingPage(landingPage => ({...landingPage, leadTargets: (getIn(values, "leadTargets") || [])}))
  }, [setLandingPage, values])

  let columns = []
  if (clientIds.lentgh == 1) {
    columns = [clientIds]
  } else {
    let mid = Math.round(clientIds.length / 2)
    let col1 = clientIds.slice(0,mid)
    let col2 = clientIds.slice(mid)
    columns = [col1, col2]
  }

  return (
    <>
      <Box
        sx={{display: 'flex',
        flexDirection: 'row',
        }}
      >
        {columns.map((column, index) => (
          <div key={index}>
            <div className={`${(index > 0) ? classes.columnDivider : ''} ${classes.column}`}>
              {column.map((clientId) => (
                <LeadTargetClient
                  key={clientId}
                  clientId={clientId}
                  hasError={errorClientIds.includes(clientId)}
                  setErrorClientIds={setErrorClientIds}
                />
              ))}
            </div>
          </div>
        ))}
      </Box>
    </>
  )
}

export default function LeadTargetForm({clientIds, landingPage, setLandingPage,
  onClose, isSubmitting, stepNumber, onClickBackButton, onClickSelectButton, modalTitle,
  warningText, setErrorClientIds, errorClientIds}) {

  const [showNextButton, setShowNextButton] = useState(false)
  const modalSubTitle = <>Select the <b>Campaigns</b> and <b>Programs</b> to include as recipients of leads for this landing page.</>

  useEffect(() => {
    setShowNextButton(landingPage.leadTargets.length > 0)
  }, [landingPage.leadTargets.length, setShowNextButton])

  return (
    <DialogContentWithBookends
      onClose={onClose}
      showBackButton={stepNumber > 0}
      onClickBackButton={onClickBackButton}
      onClickSelectButton={onClickSelectButton}
      onCancel={onClose}
      isSubmitting={isSubmitting}
      modalTitle={modalTitle}
      buttonText='Next'
      modalSubTitle={modalSubTitle}
      disabled={!showNextButton}
      warningText={warningText}
      hasErrors={errorClientIds.length > 0}
    >
      <Formik
        initialValues={{leadTargets: landingPage.leadTargets}}
        enableReinitialize
        onSubmit={() => { /* noop */ }}
      >
        <Form>
          <Suspenseful
            component={LeadTargetFormBody}
            clientIds={clientIds}
            setLandingPage={setLandingPage}
            setErrorClientIds={setErrorClientIds}
            errorClientIds={errorClientIds}
          />
        </Form>
      </Formik>
    </DialogContentWithBookends>
  )
}
