import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Accordion from '../../Accordion'
import AccordionPanel from '../../AccordionPanel'
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Form, FormTextField, getIn } from '../../Formik/forms';
import { stripHTML } from '../../../lib/utils'

import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { remote, saveWithPayload } from '../../../lib/DataModel'
import { useNotifications } from 'lib/NotificationsProvider';
import { PageSection } from 'components/UI/Structure/PageSection';
import SectionTitle from 'components/UI/SectionTitle';


function fetchProgram(id) {
  return remote.query(q => q.findRecord({type: "degreeProgram", id: id}))
}

function newProgram(clientId, programGroupId) {
  return Promise.resolve({
    type: "degreeProgram",
    attributes: {
    },
    relationships: {
      client: {
        data: { type: 'client', id: clientId },
      },
      programGroup: {
        data: { type: 'programGroup', id: programGroupId },
      },
    },
  })
}

export default function DegreeProgramEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { addNotification } = useNotifications()
  const { clientId, degreeProgramId } = useParams()
  const [program, setProgram] = useState();
  const [_client, setClient] = useState();
  const [loading, setLoading] = useState(true);
  const isAdmin = !!clientId;

  useEffect(() => {
    const promises = [
      degreeProgramId ? fetchProgram(degreeProgramId) : newProgram(clientId, new URLSearchParams(location.search).get('programGroupId')),
      clientId ? remote.query(q => q.findRecord({type: 'client', id: clientId})) : undefined,
    ];
    Promise.all(promises).then(([program, client]) => {
      setProgram(program);
      setClient(client);
      setLoading(false);
    }).catch(error => {
      console.log(error)
      addNotification({variant: 'alert', message: "Error loading degree program"});
      setLoading(false);
    })
  }, [addNotification, clientId, degreeProgramId, location.search]);

  if(loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    const deleteProgram = ({deleteRecord}) => () => {
      if (!confirm(`Are you sure you want to delete ${stripHTML(program.attributes.name)}?`)) {
        return;
      }
      deleteRecord(program).then(() => {
        addNotification({variant: 'notice', message: `Deleted degree program ${stripHTML(program.attributes.name)}`})
        navigate(clientId ? `/clients/${clientId}/degree-programs` : '/manage/degree-programs')
      })
    };

    const save = (programAttributes) => {
      return saveWithPayload(program, {
        data: {
          attributes: {
            ...remote.requestProcessor.serializer.serializeRecord({type: 'degreeProgram', id: degreeProgramId, attributes: programAttributes}).attributes,
            'client-id': getIn(programAttributes, 'relationships.client.data.id') || null,
            'program-group-id': getIn(programAttributes, 'relationships.programGroup.data.id') || null,
          }
        },
      });
    };

    return (
      <PageSection>
        <SectionTitle title='Degree Programs' />
        <Form
          resource={program}
          setResource={setProgram}
          customSave={save}
          initialValues={{...program.attributes, relationships: program.relationships}}
          redirectAfterSave={clientId ? true : '/manage/degree-programs'}
          withoutSubmitButton={!isAdmin}
          backwardsCompatibleMargins={true}
        >
          {({deleteRecord}) => (
            <>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">
                    {program.id ? 'Edit' : 'New'} degree program
                  </Typography>
                </Grid>
                {program.id && isAdmin && (
                  <Grid item>
                    <Tooltip title="Delete this degree program">
                      <IconButton aria-label="Delete this degree program" onClick={deleteProgram({deleteRecord})} size="large">
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit exclusion rules">
                      <IconButton aria-label="Edit exclusion rules" onClick={() => navigate(`./exclusion-rules`)} size="large">
                        <AssignmentIcon/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              {isAdmin && (
                <Accordion defaultPanelId="panel1">
                  <AccordionPanel label="Landing page" panelId="panel1">
                    <FormTextField
                      label="Name"
                      name="name"
                    />
                    <FormTextField
                      label="Program Code"
                      name="programCode"
                    />
                    <FormTextField
                      label="Continue button URL"
                      name="lpContinueUrl"
                    />
                  </AccordionPanel>
                </Accordion>
              ) || (
                <>
                  <FormTextField
                    label="Name"
                    name="name"
                    disabled={true}
                  />
                  <FormTextField
                    label="Program Code"
                    name="programCode"
                    disabled={true}
                  />
                </>
              )}
            </>
          )}
        </Form>
      </PageSection>
    );
  }
}
