import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CollapseIcon from '@mui/icons-material/MenuOpen'
import ExpandIcon from '../icons/ExpandIcon'
import { useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import COLORS from '../../lib/colors'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Drawer from '../UI/Drawer/Drawer'
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { getStorage, groupBy, setStorage } from 'lib/utils';
import { SETTINGS_SECTIONS } from 'components/MetricsTable';
import { xor } from 'lodash-es';
import { useDataManager } from 'components/MetricsTable/DataManager';


const SIDEBAR_PADDING = 36
const SIDEBAR_ICON_SIZE = 30
const DRAWER_WIDTH = 254
const DRAWER_WIDTH_CLOSED = SIDEBAR_PADDING * 2 + SIDEBAR_ICON_SIZE

function UnlabeledCheckbox({column, onChange}) {
  const dataManager = useDataManager()
  const handleChange = useCallback(event => onChange(event, column), [column, onChange])

  return (
    <Checkbox
      disabled={!column}
      aria-label={column?.title || ''}
      size='medium'
      checked={dataManager.tableSettings.visibleColumns.includes(column?.field)}
      onChange={column && handleChange}
      sx={{
        p: '4px',
        '&.Mui-disabled': {
          color: COLORS.veryLightGray,
          fill: COLORS.veryLightGray,
        },
      }}
    />
  )
}

function LabeledCheckbox({column, onChange}) {
  const dataManager = useDataManager()
  const handleChange = useCallback(event => onChange(event, column), [column, onChange])

  return (
    <FormControlLabel
      key={column.field}
      control={<Checkbox size='medium' checked={dataManager.tableSettings.visibleColumns.includes(column.field)} onChange={handleChange} sx={{ p: '4px', ml: 0,  mr: '4px' }} disableRipple />}
      label={column.title}
      sx={{ height: '28px', px: 3, mx: 0, fontSize: '14px',
        '&:hover': {
          backgroundColor: COLORS.aliceBlue,
        }
      }}
      componentsProps={{ typography: {variant:'body2', sx:{ textOverflow: 'ellipsis', width: '160px', overflow: 'hidden', whiteSpace: 'nowrap' }}}}
    />
  )
}

function CollapsibleFilterList({title, columns, onChange, initiallyExpanded=true}) {
  const [openList, setOpenList] = useState(initiallyExpanded)

  const handleDrawerToggle = () => {
    setOpenList(!openList)
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        height="28px"
        onClick={handleDrawerToggle}
      >
        <Typography variant="subtitle1" pl={4} sx={{ whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
        <Box
          onClick={handleDrawerToggle}
          sx={{ p: 0, color: COLORS.slateGray, height: '20px' }}
        >
          {openList && (
            <ArrowDropUpIcon fontSize='small'/>
          ) || (
            <ArrowDropDownIcon fontSize='small'/>
          )}
        </Box>
      </Stack>
      <Collapse orientation="vertical" in={openList} unmountOnExit>
        <FormGroup>
          {columns.map((column, index) => (
            <LabeledCheckbox key={`${column.field}_${index}` || `${column.title}_${index}`} column={column} onChange={onChange}/>
          ))}
        </FormGroup>
      </Collapse>
    </>
  )
}

function TableSettingSection({title, open, icon, children}) {
  const [anchorEl, setAnchorEl] = useState(null)

  function handleMouseOver(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleMouseOff() {
    setAnchorEl(null);
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1.5}
        sx={{ mt: 3.5, mb: .5, mx: 3.5, '.MuiCollapse-root': { width: '100% !important' } }}
      >
        <Box sx={{ color: COLORS.slateGray, height: '24px' }} onMouseOver={!open ? handleMouseOver : undefined}>{icon}</Box>
        {/* Category Header */}
        <Collapse orientation="horizontal" in={open} sx={{ '.MuiCollapse-root': {width: '100%'}, '.MuiCollapse-wrapperInner': { width: '100%' } }} unmountOnExit>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body1" sx={{ color: COLORS.slateGray, fontSize: 14, fontWeight: 600 }}>{title}</Typography>
            <Divider sx={{ width: '100%', height: '1px', my: 'auto !important', borderColor: COLORS.mediumGray }} />
          </Stack>
        </Collapse>
        {/* END Category Header */}
      </Stack>
      {/* Sub Categories */}
      <Collapse orientation="horizontal" in={open} sx={{ '.MuiCollapse-wrapperInner': { width: '100%' } }} unmountOnExit>
        {children}
      </Collapse>
      {/* END Sub Categories */}

      {/* Hover Menu */}
      <Menu
        id={`${title}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMouseOff}
        MenuListProps={{ onMouseLeave: handleMouseOff, sx:{ ml: '-6px' }  }}
        anchorOrigin={{vertical: -12, horizontal: -4}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: { width: 240, textOverflow: 'ellipsis', overflowX: 'hidden', overflowY: 'auto', whiteSpace: 'nowrap' },
        }}
      >
        <Stack direction="row" spacing={1.5}>
          <Typography variant="body1" sx={{ color: COLORS.slateGray, fontSize: 14, fontWeight: 600, pl: 4, pt: 1, pb: 1 }}>{title}</Typography>
          <Divider sx={{ width: '100%', height: '1px', my: 'auto !important', borderColor: COLORS.mediumGray }} />
        </Stack>
        {children}
      </Menu>
      {/* END Hover Menu */}
    </>
  )
}

function CheckboxColumnLayout({titles, columns, onChange}) {
  const groupedColumns = groupBy(columns, 'subCategory', { asEntries: true })
  const [labelTitle, ...columnTitles] = titles

  return (
    <Box sx={{ px: 4 }}>
      <Grid container sx={{ height: '18px', overflow: 'hidden', alignItems: 'center'}}>
        <Grid item sx={{ mr: 'auto' }}>
          <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
            {labelTitle}
          </Typography>
        </Grid>
        {columnTitles.map((title) => (
          <Grid item key={`${title}`} sx={{ pl: '8px' }}>
            <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
              {title}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {groupedColumns.map(([subCategory, columns]) => {
        return(
          <Grid container key={`${subCategory}`} height="28px" alignItems='center' sx={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }} >
            <Grid item sx={{ mr: 'auto', justifyContent: 'flex-start' }}>
              <Typography variant="body2" sx={{ maxWidth: '94px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {subCategory}:
              </Typography>
            </Grid>
            {columnTitles.map((title, index) => {
              const isLast = index === columnTitles.length - 1

              return(
                <Grid item sx={{ mr: isLast ? '-7px' : undefined }}  key={`${subCategory}_${title}`}>
                  <UnlabeledCheckbox column={columns.find(c => c.fieldType == title.toLowerCase())} onChange={onChange}/>
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </Box>
  )
}

export default function PerformanceSidebar({ initiallyExpanded=null }) {
  const dataManager = useDataManager()
  const storageOpen = getStorage('PerformanceSidebar.open')
  const [open, setOpen] = useState((initiallyExpanded !== null ? initiallyExpanded : storageOpen !== undefined ? storageOpen : true))

  useEffect(() => {
    setStorage('PerformanceSidebar.open', open)
  }, [open])

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const handleChange = useCallback((event, column) => {
    const visibleColumns = xor(dataManager.tableSettings.visibleColumns, [column.field])
    dataManager.setTableSettings({...dataManager.tableSettings, visibleColumns: visibleColumns})
  }, [dataManager])

  const drawer = (
    <Box sx={{ pb: 2 }}>
      <Tooltip title={open ? 'Collapse' : 'Expand'} >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            color: COLORS.slateGray,
            width: SIDEBAR_ICON_SIZE,
            height: SIDEBAR_ICON_SIZE,
            display: 'flex',
            alignItems: 'center',
            transform: 'rotateY(180deg)',
            position: 'absolute',
            top: "46px",
            right: "35.5px"
          }}
        >
          {open && (
            <CollapseIcon/>
          ) || (
            <ExpandIcon/>
          )}
        </IconButton>
      </Tooltip>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={'-3px'}
        p={'48px 24px 0 24px'}
      >
        <Collapse orientation="horizontal" in={open}>
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            Table Settings
          </Typography>
        </Collapse>
      </Stack>
      {SETTINGS_SECTIONS.map(({ title: sectionTitle, lists, icon: IconComponent }) => (
        <TableSettingSection key={sectionTitle} title={sectionTitle} open={open} icon={<IconComponent/>}>
          {lists.map(({ key, title: listTitle }) => {
            const filteredColumns = dataManager.columns.filter((c: Column) => c.category === key)
            if(filteredColumns.length === 0) {
              return null
            }
            else if (key === "metrics" || key === "performance") {
              return <CheckboxColumnLayout key={key} titles={listTitle} columns={filteredColumns} onChange={handleChange}/>
            }
            else {
              return <CollapsibleFilterList key={key} title={listTitle} columns={filteredColumns} onChange={handleChange}/>
            }
          })}
        </TableSettingSection>
      ))}
    </Box>
  )

  return(
    <Drawer
      variant='permanent'
      anchor='right'
      open={open}
      openWidth={DRAWER_WIDTH}
      closedWidth={DRAWER_WIDTH_CLOSED}
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'overlay',
        }
      }}
    >
      {drawer}
    </Drawer>
  )
}
