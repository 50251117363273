import { useCallback, useMemo, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormTextField, getIn, buildRelatedResourceURL } from '../Formik/forms'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import DeleteIcon from '@mui/icons-material/Delete'

import { isNew, remote } from '../../lib/DataModel'
import FormCollectionSelector from '../UI/FormCollectionSelector'
import Suspenseful from '../Suspenseful'
import { Button } from '@mui/material'

import { getRemoteId } from '../../lib/DataModel'
import { useFormikContext } from 'formik'
import { useNotifications } from 'lib/NotificationsProvider';

const useStyles = makeStyles(() => ({
  footer: {
    marginTop: 24,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 24,
    },
  },
  campaignsHeader: {
    fontWeight: 700,
    fontSize: '16px',
  },
  campaignsCaption: {
    marginBottom: '5px',
    fontSize: '12px',
  },
  nameAndDeleteIcon: {
    marginBottom: '20px',
  },
  clientCampaignGroupName: {
    marginTop: 0,
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
  buttonContainer: {
    marginLeft: 6,
  },
}))

function DeleteButton({onClick, disabled}) {
  const classes = useStyles()

  return (
    <Grid item className={classes.buttonContainer}>
      <Tooltip title="Delete campaign group">
        <IconButton aria-label="Delete campaign group" onClick={onClick} disabled={disabled} size="large">
          <DeleteIcon/>
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

function FormBody({clientCampaignGroup, clientCampaigns, wasNew, isDeleting, onDelete, onCancel}) {
  const { isSubmitting, dirty } = useFormikContext()

  const classes = useStyles()

  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.nameAndDeleteIcon}>
        <Grid item md={6}>
          <FormTextField
            className={classes.clientCampaignGroupName}
            label="Campaign Group Name"
            name="name"
            margin="dense"
          />
        </Grid>
        {!wasNew && (
          <DeleteButton clientCampaignGroup={clientCampaignGroup} onClick={onDelete} disabled={isSubmitting || isDeleting}/>
        )}
      </Grid>
      <div className={classes.campaignsHeader}>
        Campaigns
      </div>
      <div className={classes.campaignsCaption}>
        <Typography variant="body1">Select campaigns to be included in this daily cap group.</Typography>
      </div>
      <FormCollectionSelector
        collection={clientCampaigns}
        name="clientCampaignIds"
        render={clientCampaign => getIn(clientCampaign, 'attributes.name')}
        numColumns={2}
        minItemsPerColumn={4}
        disabled={isSubmitting || isDeleting}
        getItemId={getRemoteId}
      />

      <Divider light className={classes.divider} />

      <div className={classes.footer}>
        <Button disableElevation onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || isDeleting || !dirty}
        >Save</Button>
      </div>
    </>
  )
}

function deleteClientCampaignGroup(contract, clientCampaignGroup) {
  const url = buildRelatedResourceURL(contract, clientCampaignGroup)
  return remote.update(q => q.removeRecord(clientCampaignGroup), { url: url })
}

function ClientCampaignGroupForm({
  contract,
  clientCampaignsResource,
  clientCampaignGroup,
  onSaveOrDelete,
  onCancel,
}) {
  const { addNotification, clearNotifications } = useNotifications()

  const clientCampaigns = clientCampaignsResource.get()

  const [isDeleting, setIsDeleting] = useState(false)

  const wasNew = useMemo(() => isNew(clientCampaignGroup), [clientCampaignGroup])

  const handleClickDelete = useCallback(() => {
    if(!confirm(`Are you sure you want to remove the client campaign group ${getIn(clientCampaignGroup, 'attributes.name')}? This action cannot be undone.`)) {
      return
    }

    clearNotifications()
    setIsDeleting(true)
    deleteClientCampaignGroup(contract, clientCampaignGroup).then(() => {
      addNotification({variant: 'notice', message: 'Deleted client campaign group.'})
      setIsDeleting(false)
      onSaveOrDelete()
    }).catch(() => {
      setIsDeleting(false)
    })
  }, [clientCampaignGroup, clearNotifications, contract, addNotification, onSaveOrDelete])

  return (
    <Form
      resource={clientCampaignGroup}
      relatedResource={contract}
      onSave={onSaveOrDelete}
      withoutSubmitButton
      backwardsCompatibleMargins={true}
    >
      <FormBody
        clientCampaignGroup={clientCampaignGroup}
        clientCampaigns={clientCampaigns}
        wasNew={wasNew}
        isDeleting={isDeleting}
        onDelete={handleClickDelete}
        onCancel={onCancel}
      />
    </Form>
  )
}

export default function ClientCampaignGroupFormWrapper(props) {
  return (
    <Suspenseful
      component={ClientCampaignGroupForm}
      {...props}
    />
  )
}
