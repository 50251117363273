import { useCallback, useEffect, useMemo, useState } from "react"
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { Calendar, DateRange } from 'react-date-range'
import moment from '../lib/moment'
import { TimeRangeBuilder, isMidnight } from '../lib/time'
import { useTimezone } from '../lib/TimezoneProvider'
import { generateRange } from '../lib/utils'
import tinycolor from 'tinycolor2'
import SmallSelect from './SmallSelect'

import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import { DateField } from '@mui/x-date-pickers/DateField';
import Box from "@mui/material/Box";
import { bindPopover } from "material-ui-popup-state";
import { Moment } from "moment-timezone";
import useStateRef from "react-usestateref";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import COLORS from 'lib/colors';
import useId from '@mui/utils/useId'
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(theme => ({
  /* Work around the fact that react-date-range highlights ALL days when the range is empty. See https://github.com/hypeserver/react-date-range/issues/360 */
  emptyRange: {
    '& .rdrSelected, & .rdrInRange, & .rdrStartEdge, & .rdrEndEdge': {
      background: '#fff',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span': {
      color: 'black',
      '&:after': {
        background: theme.palette.primary.main,
      },
    },
  },
  dateRange: {
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span': {
      color: 'black',
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: theme.palette.primary.main,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    margin: '18px 24px 12px 24px',
  },
  predefinedValuesContainer: {
    margin: '0 6px',
    borderBottom: '1px solid #f0f3f5',
  },
  formGroup: {
    maxWidth: 'fit-content',
    margin: '0 14px 12px 14px',
  },
  monthAndYearWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 12px',
  },
  monthAndYearDivider: {
    display: 'inline-block',
    width: 12,
  },
  prevNextButton: {
    color: theme.palette.action.active,
  },
}))

function valueToDateRange(value : TimeRange) : DateRange {
  if(value && value.length == 2 && value[0] && value[1]) {
    return {startDate: new Date(value[0].year(), value[0].month(), value[0].date()), endDate: new Date(value[1].year(), value[1].month(), value[1].date()), key: 'selection'}
  } else {
    return {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  }
}

function setYearMonthDay(moment : Moment, date : Date) : Moment {
  return moment.clone().set({year: date.getFullYear(), month: date.getMonth(), date: date.getDate() })
}

export function dateRangeToValue(range : DateRange, currentValue : TimeRange, timezone : string) : TimeRange {
  if(range && range.startDate !== null && range.endDate !== null) {
    if(currentValue === null) {
      return [
        moment.tz([range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate()], timezone).startOf('day'),
        moment.tz([range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate()], timezone).endOf('day'),
      ]
    } else {
      return [
        setYearMonthDay(currentValue[0], range.startDate),
        setYearMonthDay(currentValue[1], range.endDate),
      ]
    }
  } else {
    return null
  }
}

function MonthAndYearSelector({
  focusedDate,
  changeShownDate,
  showMonthArrow,
  minDate,
  maxDate,
  showMonthAndYearPickers,
}) {
  const classes = useStyles()
  const monthNames = useMemo(() => [...generateRange(0,11)].map(month => moment().month(month).format('MMMM')), [])

  const handleMouseUp = useCallback(e => e.stopPropagation(), [])
  const handleClickPrev = useCallback(() => changeShownDate(-1, 'monthOffset'), [changeShownDate])
  const handleClickNext = useCallback(() => changeShownDate(+1, 'monthOffset'), [changeShownDate])
  const handleChangeMonth = useCallback(e => changeShownDate(e.target.value, 'setMonth'), [changeShownDate])
  const handleChangeYear = useCallback(e => changeShownDate(e.target.value, 'setYear'), [changeShownDate])

  const upperYearLimit = (maxDate || Calendar.defaultProps.maxDate).getFullYear();
  const lowerYearLimit = (minDate || Calendar.defaultProps.minDate).getFullYear();

  return (
    <div onMouseUp={handleMouseUp} className={classes.monthAndYearWrapper}>
      {showMonthArrow ? (
        <IconButton onClick={handleClickPrev} size="small" className={classes.prevNextButton}>
          <ChevronLeftIcon fontSize="small"/>
        </IconButton>
      ) : null}
      {showMonthAndYearPickers ? (
        <span className={classes.monthAndYearPickers}>
          <span className={classes.monthPicker}>
            <SmallSelect
              value={focusedDate.getMonth()}
              onChange={handleChangeMonth}
              aria-label="Month"
            >
              {monthNames.map((monthName, i) => (
                <MenuItem key={i} value={i}>
                  {monthName}
                </MenuItem>
              ))}
            </SmallSelect>
          </span>
          <span className={classes.monthAndYearDivider} />
          <span className={classes.yearPicker}>
            <SmallSelect
              value={focusedDate.getFullYear()}
              onChange={handleChangeYear}
              aria-label="Year"
            >
              {new Array(upperYearLimit - lowerYearLimit + 1)
                .fill(upperYearLimit)
                .map((val, i) => {
                  const year = val - i
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                })}
            </SmallSelect>
          </span>
        </span>
      ) : (
        <span className={classes.monthAndYearPickers}>
          {monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
        </span>
      )}
      {showMonthArrow ? (
        <IconButton onClick={handleClickNext} size="small" className={classes.prevNextButton}>
          <ChevronRightIcon fontSize="small"/>
        </IconButton>
      ) : null}
    </div>
  )
}

function ToggleButton({ onClick, icon, toggleOn}) {
  return(
    <Box onClick={onClick} sx={{ width: '30px', height: '30px', borderRadius: '50%', padding: '3px', backgroundColor: toggleOn ? COLORS.frenchBlue : 'white', color: toggleOn ? 'white' : COLORS.frenchBlue }}>
      {icon}
    </Box>
  )
}

function generatePredefinedTimeRanges(timezone) : PredefinedRange[] {
  const timeRangeBuilder = new TimeRangeBuilder({ timezone: timezone })

  return [
    { label: 'Today',         timeRange: timeRangeBuilder.today() },
    { label: 'Yesterday',     timeRange: timeRangeBuilder.yesterday() },
    { label: 'This Week',     timeRange: timeRangeBuilder.weekToDate() },
    { label: 'Last Week',     timeRange: timeRangeBuilder.lastWeek() },
    { label: 'This Month',    timeRange: timeRangeBuilder.monthToDate() },
    { label: 'Last Month',    timeRange: timeRangeBuilder.lastMonth() },
    { label: 'This Quarter',  timeRange: timeRangeBuilder.quarterToDate() },
    { label: 'Last Quarter',  timeRange: timeRangeBuilder.lastQuarter() },
    { label: 'This Year',     timeRange: timeRangeBuilder.yearToDate() },
    { label: 'Last Year',     timeRange: timeRangeBuilder.lastYear() },
    { label: 'Lifetime',      timeRange: null },
  ]
}

export type TimeRange = null | [Moment, Moment]

type PopoverProps = ReturnType<typeof bindPopover>

type DataPickerPopoverProps = {
  value: TimeRange,
  onChange: (value: TimeRange) => void,
  timeInputsEnabled: boolean,
  showTimeInitial: boolean,
} & PopoverProps

type DateRange =  {
  startDate: null | Date,
  endDate: null | Date,
  color?: string,
  key: string,
  autoFocus?: boolean,
  disabled?: boolean,
  showDateDisplay?: boolean,
}

type PredefinedRangeLabel = 'Today' | 'Yesterday' | 'This Week' | 'Last Week' | 'This Month' | 'Last Month' | 'This Quarter' | 'Last Quarter' | 'This Year' | 'Last Year' | 'Lifetime'
type PredefinedRange = { label: PredefinedRangeLabel, timeRange: TimeRange }

function isStartOfDayEndOfDay(timeRange) {
  return timeRange[0].hour() === 0 && timeRange[0].minute() === 0 && timeRange[1].hour() === 23 && timeRange[1].minute() === 59
}

function isSameTimeRange(range1: TimeRange, range2: TimeRange) {
  if(range1 && range2) {
    return range1[0].isSame(range2[0]) && range1[1].isSame(range2[1])
  }
  else {
    return range1 === null && range2 === null
  }
}

function TimeSelector({currentValue, setCurrentValue}) {
  const startHourId = useId()
  const endHourId = useId()

  const changeStartHour = useCallback((value) => {
    const newValue = moment(currentValue?.[0]).set('hour', Number(value[0]))
    setCurrentValue(prevValue => [newValue, prevValue?.[1] ?? newValue] )
  }, [currentValue, setCurrentValue])

  const changeEndHour = useCallback((value) => {
    const newValue = value == 24 ? moment(currentValue?.[1]).endOf('day') : moment(currentValue?.[1]).set('hour', Number(value[0])).set('minute', 0)
    setCurrentValue(prevValue => [prevValue?.[0] ?? newValue, newValue] )
  }, [currentValue, setCurrentValue])

  return (
    <Stack direction='row' gap={2} sx={{mx: 4}}>
      <Box sx={{ flex: '2' }} />
      <TextField
        select
        value={currentValue?.[0].hours() || 0}
        onChange={event => changeStartHour([event.target.value])}
        label={currentValue?.[0]?.format("MMM D")}
        id={startHourId}
        sx={{ flex: '1' }}
      >
        <MenuItem value={0} key={`start_time_range_hour_options_0`}>{moment(0, 'HH').format('h A')}</MenuItem>
        {[...generateRange(1,23)].map(i => i.toString()).map(option => (
          <MenuItem value={option} key={`start_time_range_hour_options${option}`}>{moment(option, 'HH').format('h A')}</MenuItem>
        ))}
        <MenuItem disabled value={''} key={`start_time_range_hour_options_placeholder`}>--</MenuItem>
      </TextField>

      <TextField
        select
        value={isMidnight(currentValue?.[1]) ? 24 : moment(currentValue?.[1])?.add(1, 'minute')?.hours() || 24}
        onChange={event => changeEndHour([event.target.value])}
        label={currentValue?.[1]?.format("MMM D")}
        id={endHourId}
        sx={{ flex: '1' }}
      >
        <MenuItem disabled value={''} key={`end_time_range_hour_options_placeholder`}>--</MenuItem>
        {[...generateRange(1,23)].map(i => i.toString()).map(option => (
          <MenuItem value={option} key={`end_time_range_hour_options${option}`}>{moment(option, 'HH').format('h A')}</MenuItem>
        ))}
        <MenuItem value={24} key={`end_time_range_hour_options_0`}>Midnight</MenuItem>
      </TextField>
    </Stack>
  )
}

export default function DatePickerPopover({
  value: initialValue,
  onChange = () => undefined,
  timeInputsEnabled = false,
  ...popoverProps
} : DataPickerPopoverProps) {
  const classes = useStyles()
  const theme = useTheme()
  const rangeColors = useMemo(() => [tinycolor(theme.palette.primary.main).setAlpha(0.1).toRgbString()], [theme.palette.primary.main])
  const [currentValue, setCurrentValue, valueRef] = useStateRef(initialValue)
  const { timezone } = useTimezone()
  const predefinedValues = useMemo(() => generatePredefinedTimeRanges(timezone), [timezone])
  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState<PredefinedRangeLabel | null>(null)
  const maxDate = useMemo(() => moment.tz(timezone).toDate(), [timezone])
  const isEmpty = currentValue === null
  const [showTime, setShowTime] = useState(initialValue ? !isStartOfDayEndOfDay(initialValue) : false)
  const uniqueId = useId()

  const handleClose = useCallback((value) => {
    if(!isSameTimeRange(initialValue, valueRef.current)) {
      onChange(value)
    }
    popoverProps.onClose()
  }, [initialValue, onChange, popoverProps, valueRef])

  const handleSelect = useCallback(({selection}) => {
    setCurrentValue(prevValue => {
      return dateRangeToValue(selection, prevValue, timezone)
    })
  }, [setCurrentValue, timezone])

  const handleRangeFocusChange = useCallback(focusRange => {
    if(focusRange[0] === 0 && focusRange[1] === 0) {
      handleClose(valueRef.current)
    }
  }, [handleClose, valueRef])

  const handleSelectPredefined = event => {
    const label = event.target.value
    setSelectedPredefinedRange(label)
    const newValue = predefinedValues.find(p => p.label === label)?.timeRange as TimeRange
    setCurrentValue(newValue)
    handleClose(newValue)
  }

  useEffect(() => {
    if(initialValue === null) {
      setSelectedPredefinedRange('Lifetime')
    }
    setCurrentValue(initialValue)
  }, [initialValue, setCurrentValue])

  const renderMonthAndYear = useCallback((focusedDate, changeShownDate, props) => (
    <MonthAndYearSelector focusedDate={focusedDate} changeShownDate={changeShownDate} {...props} />
  ), [])

  return (
    <Popover
      {...popoverProps}
      onClose={() => { handleClose(valueRef.current) }}
    >
      <div className={classes.root}>
        <div className={classes.title}>
          <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            Select Date Range
            <Stack direction='row' gap={1}>
              {timeInputsEnabled &&
                <ToggleButton onClick={() => setShowTime(prev => !prev)} icon={<AccessTimeIcon />} toggleOn={showTime} />
              }
            </Stack>
          </Stack>
        </div>

        <Stack direction='column' gap={2}>
          <Stack direction='row' gap={2} sx={{mx: 4}}>
            <FormControl sx={{ flex: '2' }}>
              <InputLabel id={`date-range-label_${uniqueId}`}>Date Range</InputLabel>
              <Select
                labelId={`date-range-label_${uniqueId}`}
                id={`date-range_${uniqueId}`}
                value={selectedPredefinedRange || "Custom"}
                label="Date Range"
                onChange={handleSelectPredefined}
                data-testid='predefinedRange'
              >
                {predefinedValues.map((predefinedValue, index) => (
                  <MenuItem key={index} value={predefinedValue.label}>{predefinedValue.label}</MenuItem>
                ))}
                <MenuItem disabled value={'Custom'}>Custom</MenuItem>
              </Select>
            </FormControl>
            <DateField value={currentValue?.[0]} label="Date" onChange={(value) => setCurrentValue(prevValue => [value as Moment, prevValue?.[1] ?? value as Moment] )} sx={{ flex: '1' }} />
            <DateField value={currentValue?.[1]} label="Date" onChange={(value) => setCurrentValue(prevValue => [prevValue?.[0] ?? value as Moment, value as Moment] )} sx={{ flex: '1' }} />
          </Stack>

          {timeInputsEnabled && showTime &&
            <TimeSelector currentValue={currentValue} setCurrentValue={setCurrentValue} />
          }
        </Stack>
        <Divider light sx={{mt: 2, mx: 2, mb: .5}}/>
        <DateRange
          className={clsx(classes.dateRange, {[classes.emptyRange]: isEmpty})}
          dateDisplayFormat="MM/dd/yyyy"
          onChange={handleSelect}
          onRangeFocusChange={handleRangeFocusChange}
          ranges={[valueToDateRange(currentValue)]}
          showDateDisplay={false}
          moveRangeOnFirstSelection={false}
          color={theme.palette.primary.main}
          rangeColors={rangeColors}
          navigatorRenderer={renderMonthAndYear}
          maxDate={maxDate}
          months={2}
          direction="horizontal"
          calendarFocus="backwards"
          preventSnapRefocus
        />
      </div>
    </Popover>
  )
}
