import { MockedProvider } from "@apollo/client/testing";
import { JSONAPISerializers } from "@orbit/jsonapi";
import CurrentUserProvider from "lib/CurrentUserProvider";
import { remote } from "lib/DataModel";
import HostnameProvider from "lib/HostnameProvider";
import {
  GET_LANDING_PAGE_QUERY,
  GET_CONTRACTS_QUERY,
  GET_FILTERED_LEADS_CLIENTS_QUERY,
  GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY,
} from "../../data/queries";
import LandingPageEdit from "./LandingPageEdit";
import SampleLandingPage from "../../data/SampleLandingPage";
import SampleContracts from "../../data/SampleContracts";
import SampleFilteredLeadsClients from "../../data/SampleFilteredLeadsClients";
import OutletContainer from "components/layout/OutletContainer/OutletContainer";

export default {
  title: "Pages/LandingPageEditMulti",
  component: LandingPageEdit,
};

const Template = (args, { parameters }) => {
  const currentUser = remote.requestProcessor
    .serializerFor(JSONAPISerializers.ResourceDocument)
    .deserialize({ data: { type: "users", id: "1" } }).data;
  const currentTeam = remote.requestProcessor
    .serializerFor(JSONAPISerializers.ResourceDocument)
    .deserialize({
      data: {
        type: "teams",
        id: "1",
        relationships: { vendor: { data: { type: "vendor", id: "1" } } },
      },
    }).data;

  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <HostnameProvider
        portalHostname={"dev.yourdreamdegree.com"}
      >
        <CurrentUserProvider
          currentUser={currentUser}
          currentTeam={currentTeam}
        >
          <OutletContainer>
            <LandingPageEdit {...args} />
          </OutletContainer>
        </CurrentUserProvider>
      </HostnameProvider>
    </MockedProvider>
  );
};

export const Main = Template.bind({});
Main.args = {};

Main.parameters = {
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9" },
    browserPath: "/landing-pages/d5a493a9/main",
  },
  mocks: [
    {
      request: {
        query: GET_LANDING_PAGE_QUERY,
        variables: {
          id: "d5a493a9",
        },
      },
      result: {
        data: {
          landingPage: SampleLandingPage,
        },
      },
    },
    {
      request: {
        query: GET_CONTRACTS_QUERY,
        variables: {
          clientIds: ["188", "29", "184", "195"],
          vendorId: "1",
        },
      },
      result: {
        data: {
          contracts: SampleContracts,
        },
      },
    },
    {
      request: {
        query: GET_FILTERED_LEADS_CLIENTS_QUERY,
        variables: {},
      },
      result: {
        data: {
          filteredLeadsClients: SampleFilteredLeadsClients,
        },
      },
    },
    {
      request: {
        query: GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY,
        variables: { programGroupIds: ["240"], vendorId: "1" },
      },
      result: {
        data: {
          clientCampaigns: [
            {
              __typename: "ClientCampaign",
              id: "a9ba99e5",
              name: "YDD Filtered Campaign",
              campaignType: "shared",
              status: "active",
              programGroup: {
                __typename: "ProgramGroup",
                id: "240",
                description: "YDD Filtered ProgramGroup",
              },
            },
          ],
        },
      },
    },
  ],
};

export const Questions = Template.bind({});
Questions.args = {};
Questions.parameters = {
  ...Main.parameters,
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9" },
    browserPath: "/landing-pages/d5a493a9/questions",
  },
};

export const Rules = Template.bind({});
Rules.args = {};
Rules.parameters = {
  ...Main.parameters,
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9" },
    browserPath: "/landing-pages/d5a493a9/rules",
  },
};

export const Tracking = Template.bind({});
Tracking.args = {
  id: "d5a493a9",
  router: ["/landing-pages/:id/tracking"],
};
Tracking.parameters = {
  ...Main.parameters,
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9" },
    browserPath: "/landing-pages/d5a493a9/tracking",
  },
};
