import { useMemo, useState } from 'react'
import { getRemoteId, store } from '../../../lib/DataModel'
import { useTimezone } from '../../../lib/TimezoneProvider'
import { useRefreshable } from 'lib/hooks'

import VendorsResource from '../../../resources/VendorsResource'
import Dashboard from '../Dashboard/Dashboard'
import CurrentMonthPacingResource from '../../../resources/CurrentMonthPacingResource'
import DailyCountsResource from '../../../resources/DailyCountsResource'
import { getIn } from '../../Formik/forms'
import { TimeRangeBuilder, timeRangeToDateRange } from '../../../lib/time'

export default function ClientDashboard() {
  const [vendorsResource] = useState(() => new VendorsResource())
  const [currentMonthPacingResource, refreshCurrentMonthPacingResource] = useRefreshable(() => new CurrentMonthPacingResource(), [])
  const { timezone } = useTimezone()
  const initialDateRange = useMemo(() => timeRangeToDateRange(new TimeRangeBuilder({ timezone }).monthToDate()), [timezone])
  const dailyCountsResource = useMemo(() => new DailyCountsResource({timezone}), [timezone])
  const vendorMapPromise = useMemo(() => vendorsResource.promise.then(vendors => Object.fromEntries(vendors.map(vendor => [getRemoteId(vendor), vendor.attributes.name]))), [vendorsResource])
  const campaignMapPromise = useMemo(() => currentMonthPacingResource.promise.then(() => (
    Object.fromEntries(store.cache.query(q => q.findRecords('clientCampaign')).map(record => [getRemoteId(record), getIn(record, 'attributes.name')]))
  )), [currentMonthPacingResource])
  const groupingColumns = useMemo(() => [
    { title: 'Vendor', field: 'vendorId', lookup: vendorMapPromise, summary: { text: 'Total' }, visible: false },
    { title: 'Campaign', field: 'clientCampaignId', lookup: campaignMapPromise, summary: { text: 'Total' }, visible: true },
  ], [campaignMapPromise, vendorMapPromise])

  return (
    <Dashboard
      groupingColumns={groupingColumns}
      dailyCountsResource={dailyCountsResource}
      currentMonthPacingResource={currentMonthPacingResource}
      refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource}
      dashboardUrl="/api/v1/client/dashboard"
      initialDateRange={initialDateRange}
    />
  )
}
