import { useCallback, useMemo } from 'react'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import makeStyles from '@mui/styles/makeStyles';

import PerformanceTable from 'components/PerformanceTable/PerformanceTable';
import PerformanceCharts from '../../analytics/PerformanceCharts'

import { useContract } from '../../../lib/ContractProvider'

import MapIcon from '@mui/icons-material/Map'
import TableChartIcon from '@mui/icons-material/TableChart'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import { getDefaultDateRange } from 'components/PerformanceTable/utils'
import Box from '@mui/material/Box';
import { useTimezone } from 'lib/TimezoneProvider';
import moment from 'lib/moment';


const useStyles = makeStyles(theme => ({
  container: {
  },
  tabs: {
    marginBottom: theme.spacing(2),
    minHeight: '72px',
  },
}))

export const tabs = {
  QUALITY: 'quality',
  HEATMAP: 'heatmap',
}

export default function Performance({currentTeam}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const match = useMatch({ path: '/performance/:tab', end: false })
  const selectedTab = match?.params?.tab || tabs.QUALITY
  const { contract } = useContract()
  const { timezone } = useTimezone()
  const [dateRangeQueryString, setDateRangeQueryString] = useQueryParam('dateRange', withDefault(JsonParam, getDefaultDateRange(timezone)))

  const dateRange = useMemo(() => {
    if(dateRangeQueryString.length > 0) {
      return [
        moment.tz(dateRangeQueryString[0], timezone),
        moment.tz(dateRangeQueryString[1], timezone),
      ]
    } else {
      return null
    }
  }
  ,[dateRangeQueryString, timezone])

  const setDateRange = useCallback(newValue => {
    if(typeof(newValue) === 'function') {
      newValue = newValue(dateRange)
    }

    if(newValue === null){
      setDateRangeQueryString([])
      return
    }
    setDateRangeQueryString([newValue[0].startOf('hour'), newValue[1].subtract(30,'minute').endOf('hour')])
  }, [setDateRangeQueryString, dateRange])

  const handleNavigation = useCallback(newValue => {
    const searchString = `dateRange=${encodeURIComponent(JsonParam.encode(dateRange ?? []))}`
    navigate({
      pathname: `/performance/${newValue}`,
      search: searchString
    })
  },[dateRange, navigate])

  return (
    <Box sx={{
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      px: 3,
    }}>
      <Tabs
        value={selectedTab}
        onChange={(_event, newValue) => handleNavigation(newValue)}
        className={classes.tabs}
      >
        <Tab sx={{ minWidth: 92 }} icon={<TableChartIcon/>} label="Quality" value={tabs.QUALITY} />
        <Tab sx={{ minWidth: 92 }} icon={<MapIcon/>} label="Heat Map" value={tabs.HEATMAP} />
      </Tabs>

      <Routes>
        <Route path='quality' element={<PerformanceTable currentTeam={currentTeam} contract={contract} dateRange={dateRange} setDateRange={setDateRange}/>}/>
        <Route path='heatmap' element={<PerformanceCharts currentTeam={currentTeam} contract={contract} dateRange={dateRange} setDateRange={setDateRange}/>}/>
        <Route path='*' element={<Navigate to="quality" replace />}/>
      </Routes>
    </Box>
  )
}
