import { useCallback, useEffect, useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';

import HoverPopover from 'material-ui-popup-state/HoverPopover'

import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import ActionsInactiveIcon from './assets/actions-inactive.png'
import ActionsActiveIcon from './assets/actions-active.png'

const useStyles = makeStyles(theme => ({
  popover: {
    width: 70,
    padding: '30px 30px 30px 20px',
  },
  rowActionIcon: {
    width: 20,
    height: 20,
  },
  paper: {
    border: 'none',
    background: 'transparent',
    boxShadow: 'none',
  },
  button: {
    backgroundColor: 'white',
    '$button + &': {
      marginTop: 10,
    },
  },
}))

function RowAction({rowAction, row}) {
  const classes = useStyles()
  const handleClick = useCallback(event => {
    rowAction.onClick(event, row)
  }, [rowAction, row])

  return (
    <Tooltip title={rowAction.tooltip}>
      <Fab size="small" className={classes.button} onClick={handleClick}>
        {typeof(rowAction.icon) === 'string' && (
          <img src={rowAction.icon} className={classes.rowActionIcon} />
        ) || (
          rowAction.icon()
        )}
      </Fab>
    </Tooltip>
  )
}

function Actions({row, rowActions, onMouseEnter, onMouseLeave, ...popoverProps}) {
  const classes = useStyles()

  return (
    <HoverPopover
      {...popoverProps}
      anchorOrigin={{horizontal: 'center', vertical: 'center'}}
      transformOrigin={{horizontal: 'left', vertical: 'center'}}
      marginThreshold={0}
      PaperProps={{
        className: classes.paper,
      }}
    >
      <div className={classes.popover} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {rowActions.map((rowAction, index) => (
          <RowAction key={index} rowAction={rowAction} row={row}/>
        ))}
      </div>
    </HoverPopover>
  )
}

export default function RowActionsButton({row, rowActions, isHoveringRow}) {
  const [isHovering, setHovering] = useState(false)
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)

  const handleMouseEnter = useCallback(event => {
    setHovering(true)
  }, [setHovering])

  const handleMouseLeave = useCallback(event => {
    setHovering(false)
  }, [setHovering])

  useEffect(() => {
    if(!isHoveringRow && !isHovering) {
      setOpen(false)
    }
    if(isHovering) {
      setOpen(true)
    }
  }, [isHoveringRow, isHovering])

  return (
    <div>
      <IconButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} aria-label="Actions" size="large">
        <img alt='Row actions' ref={buttonRef} src={isHovering ? ActionsActiveIcon : ActionsInactiveIcon} width={25} height={5} />
      </IconButton>
      <Actions
        open={open}
        anchorEl={buttonRef.current}
        row={row}
        rowActions={rowActions}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
}
