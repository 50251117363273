import { getIn, PersistedErrorHandler, useFormikContext } from 'components/Formik/forms'
import { FormTextField } from "components/Formik/forms"
import FreeTextMultiSelect from 'components/UI/FreeTextMultiSelect/FreeTextMultiSelect'
import DropdownCheckbox from 'components/UI/DropdownCheckbox/DropdownCheckbox'
import { isEmpty } from 'lodash-es'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import useId from '@mui/utils/useId'
import moment from 'lib/moment'


function HourRuleValue({ path, setValue, options, value }) {
  const id = useId()

  return (
    <PersistedErrorHandler name={`${path}.value[0]`}>
      <TextField
        select
        value={value || ''}
        onChange={event => setValue([event.target.value])}
        label='Value'
        id={id}
        fullWidth
        required
      >
        {options.map(option => (
          <MenuItem value={option} key={`rule_value_${option}`}>{moment(option, 'HH').format('h:mmA')}</MenuItem>
        ))}
      </TextField>
    </PersistedErrorHandler>
  )
}

function NumericRuleValue({ path }) {
  return (
    <FormTextField
      validateInput={(value) => (isFinite(value) && parseInt(value).toString() === value) || value === ""}
      sx={{ margin: 0 }}
      label='Value'
      name={`${path}.value[0]`}
      required
      useFastTextField={false}
    />
  )
}

function TextRuleValue({ selectedAttribute, selectedValues, setValue, path}) {
  return (
    <FreeTextMultiSelect
      key={selectedAttribute?.id}
      label={selectedValues.length > 1 ? 'Values' : 'Value'}
      value={selectedValues}
      setValue={setValue}
      path={`${path}.value`}
      required={true}
    />
  )
}

function SelectRuleValue({ path, collection, selectedValues, setValue, inputPrefix, renderItem }) {
  return (
    <PersistedErrorHandler name={`${path}.value`}>
      <DropdownCheckbox
        collection={collection}
        selected={selectedValues}
        label={selectedValues.length > 1 ? 'Values' : 'Value'}
        setSelected={setValue}
        inputPrefix={inputPrefix}
        isPreset={true}
        InputLabelProps={{ shrink: true }}
        required={true}
        renderItem={renderItem}
      />
    </PersistedErrorHandler>
  )
}
export default function RuleValue({ path, selectedAttribute }) {
  const { values, setFieldValue } = useFormikContext()
  const operatorGreaterOrLessThan = ['greater_than','less_than'].includes(getIn(values, `${path}.operator`))
  const selectedValues = getIn(values, `${path}.value`) || []

  const handleAnswerSelect = selected => {
    setFieldValue(`${path}.value`, selected)
  }

  return (
    <>
      {operatorGreaterOrLessThan && (
        selectedAttribute?.key == 'hour' && (
          <HourRuleValue
            path={path}
            value={selectedValues[0]}
            setValue={handleAnswerSelect}
            options={selectedAttribute.options}
          />
        ) || (
          <NumericRuleValue path={path}/>
        )
      ) || isEmpty(selectedAttribute?.options) && (
        <TextRuleValue
          selectedAttribute={selectedAttribute}
          path={path}
          selectedValues={selectedValues}
          setValue={handleAnswerSelect}
        />
      ) || (
        <SelectRuleValue
          path={path}
          collection={selectedAttribute.options}
          selectedValues={selectedValues}
          setValue={handleAnswerSelect}
          inputPrefix={selectedAttribute?.key}
          renderItem={selectedAttribute?.key == 'hour' ? (hour) => moment(hour, 'HH').format('h:mmA') : (item) => item}
        />
      )}
    </>
  )
}
