import { createContext, useCallback, useMemo, useState } from "react"
import { useMatch, useNavigate, useBlocker, Blocker } from "react-router-dom"
import InfoIcon from '@mui/icons-material/Info'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'
import FooterBar from '../../../../../UI/FooterBar'
import { useBookends } from "lib/BookendsProvider"
import { humanize } from "lib/utils"
import MainContainer from 'components/layout/MainContainer/MainContainer'
import Tabs from "@mui/material/Tabs"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { useLandingPageStateContext } from "components/pages/LandingPages/ui/LandingPageProvider"
import { MAX_ERRORS, SCHEMAS, tabs } from "components/pages/LandingPages/data/constants"
import { validatePageSchema } from "components/pages/LandingPages/data/yupSchemas"
import { CancelOrConfirmDialog } from "components/pages/LandingPages/shared/CancelOrConfirmDialogs"
import HeaderTab from "components/UI/HeaderTab/HeaderTab"
import { getUniqueErrors, stringFromArray } from "components/Formik/utils"


export function HeaderTabs({ navigate, selectedTab, formErrors, persistedErrors, landingPageId }) {
  return (
    <Tabs
      value={selectedTab}
      onChange={(_event, newValue) => navigate(`/landing-pages/${landingPageId}/${newValue}`)}
      sx={{ marginTop: '9px' }}
    >
      <HeaderTab
        icon={<InfoIcon />}
        value={tabs.MAIN}
        formErrors={formErrors}
        persistedErrors={persistedErrors}
        badgeOffsetLeft='-14px'
      />

      <HeaderTab
        disabled={!landingPageId}
        icon={<QuestionAnswerIcon />}
        value={tabs.QUESTIONS}
        formErrors={formErrors}
        persistedErrors={persistedErrors}
        badgeOffsetLeft='-18px'
        sx={{ width: 114 }}
      />

      <HeaderTab
        disabled={!landingPageId}
        icon={<RuleOutlinedIcon />}
        value={tabs.RULES}
        formErrors={formErrors}
        persistedErrors={persistedErrors}
        badgeOffsetLeft='-16px'
      />

      <HeaderTab
        disabled={!landingPageId}
        icon={<IntegrationInstructionsOutlinedIcon />}
        value={tabs.TRACKING}
        formErrors={formErrors}
        persistedErrors={persistedErrors}
        badgeOffsetLeft='-16px'
      />
    </Tabs>
  )
}

export const NavigationContext = createContext({ showPrompt: () => Promise.resolve() })

export default function LandingPageEditNavigation({ children }) {
  const match = useMatch({ path: '/landing-pages/:landingPageId/:tab', end: false })
  const selectedTab = match?.params?.tab || tabs.MAIN
  const { bookends } = useBookends()
  const { landingPage } = useLandingPageStateContext()
  const navigate = useNavigate()
  const pageTitle = `${humanize(landingPage.template?.name || "")} Landing Page`
  const [confirmableCallback, setConfirmableCallback] = useState<(() => void) | null>(null)
  const blocker : Blocker = useBlocker(bookends.state?.form?.dirty || !landingPage.id)

  const handleShowPrompt = useCallback(() => {
    return new Promise((resolve, _reject) => {
      if(!landingPage.id) {
        navigate('/landing-pages')
      } else if(bookends?.state?.form?.dirty) {
        setConfirmableCallback(_old => () => { resolve(null) })
      }
    })
  }, [bookends?.state?.form?.dirty, landingPage.id, navigate])

  const formErrors = useMemo(() => getUniqueErrors(bookends.state?.form?.errors), [bookends.state?.form?.errors])

  const persistedErrors = useMemo(() => Object.fromEntries(Object.entries(SCHEMAS).map(([name, schema]) =>
    [name, validatePageSchema(landingPage, schema)]
  )), [landingPage])

  const errorMessage = useMemo(() => {
    if (persistedErrors) {
      const uniqueErrorList = getUniqueErrors(persistedErrors[selectedTab])
      return stringFromArray(uniqueErrorList, MAX_ERRORS)
    }
  }, [persistedErrors, selectedTab])

  return (
    <NavigationContext.Provider value={{ showPrompt: handleShowPrompt }}>
      <MainContainer>
        <Paper
          sx={{
            padding: '18px 24px',
            boxShadow: 0,
          }}
        >
          <Typography variant='h1' sx={{ textTransform: 'capitalize' }}>
            {pageTitle}
          </Typography>

          <HeaderTabs
            navigate={navigate}
            selectedTab={selectedTab}
            formErrors={formErrors}
            persistedErrors={persistedErrors}
            landingPageId={landingPage.id}
          />

          {children}
        </Paper>
      </MainContainer>

      <FooterBar errorMessage={errorMessage}/>

      <CancelOrConfirmDialog
        confirmableCallback={confirmableCallback}
        setConfirmableCallback={setConfirmableCallback}
        blocker={blocker}
      />
    </NavigationContext.Provider>
  )
}
