import { useCallback, useMemo } from 'react'
import Field from '../../FieldsEdit/Field'
import FieldsResource from '../../../resources/FieldsResource'
import { Form, setIn, createSetNestedValue } from '../../Formik/forms'
import ForEachNestedValue from '../../forms/ForEachNestedValue'
import { newRecord } from '../../../lib/DataModel'
import { useRefreshable } from '../../../lib/hooks'
import Suspenseful from '../../Suspenseful'
import { useParams } from 'react-router-dom'
import { PageSection } from 'components/UI/Structure/PageSection'
import SectionTitle from 'components/UI/SectionTitle'

function FieldsEdit({client, fieldsResource, refreshFieldsResource}) {
  const fields = fieldsResource.get()

  const newField = useCallback(() => {
    return newRecord({
      type: 'field',
      attributes: {fieldType: 'custom_select', name: '', description: '', required: true},
      relationships: { client: client }
    })
  }, [client])

  const initialValues = useMemo(() => {
    let values = {}

    fields.forEach(field => {
      createSetNestedValue({values, setFieldValue: (path, value) => { values = setIn(values, path, value) } })('fields', field)
    })

    return values
  }, [fields])

  return (
    <PageSection>
      <SectionTitle title='Fields' />
      <Form
        resource={client}
        initialValues={initialValues}
        onSave={refreshFieldsResource}
        backwardsCompatibleMargins={true}
      >
        <ForEachNestedValue
          path="fields"
          buttonText="Add field"
          newValue={newField}
          component={Field}
        />
      </Form>
    </PageSection>
  )
}

export default function FieldsEditWrapper({ clientId: clientIdFromProps = null }) {
  const { clientId: clientIdFromParams } = useParams()
  const clientId = clientIdFromProps || clientIdFromParams
  const client = useMemo(() => ({type: 'client', id: clientId}), [clientId])
  const [fieldsResource, refreshFieldsResource] = useRefreshable(clientId => new FieldsResource({type: 'client', id: clientId}), [clientId])

  return (
    <Suspenseful
      component={FieldsEdit}
      client={client}
      fieldsResource={fieldsResource}
      refreshFieldsResource={refreshFieldsResource}
    />
  )
}
