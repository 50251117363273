import { Formik, Form as FormikForm } from "formik";
import { userEvent, screen, within } from "@storybook/test";
import LandingPageExclusionRules from "./LandingPageExclusionRules";
import SampleLandingPage from "../../../data/SampleLandingPage";
import LandingPageProvider from "../../LandingPageProvider";

export default {
  title: 'Composites/LandingPageExclusionRules',
  component: LandingPageExclusionRules,
};

const Template = (args, { parameters }) => {
  const landingPage = {
    ...SampleLandingPage,
    landingPageRules: parameters.updatedLandingPageRules,
  };

  return (
    <LandingPageProvider initialState={landingPage} isRefetching={false}>
      <Formik
        initialValues={landingPage}
      >
        <FormikForm
          autoComplete="off"
        >
          <LandingPageExclusionRules {...args} />
        </FormikForm>
      </Formik>
    </LandingPageProvider>
  );
};

export const Default = Template.bind({});
Default.args = {};
Default.parameters = {
  updatedLandingPageRules: [...SampleLandingPage.landingPageRules],
};

export const RedirectUrl = Template.bind({});
RedirectUrl.args = {};
RedirectUrl.parameters = {
  updatedLandingPageRules: [...SampleLandingPage.landingPageRules],
};
RedirectUrl.play = async ({ canvasElement }) => {
  await userEvent.click(
    within(canvasElement).getByLabelText("Redirect or Exclude Programs"),
  );
  const listbox = await screen.findByRole("listbox");
  await userEvent.click(within(listbox).getByText("Redirect URL"));
};

export const EmptyState = Template.bind({});
EmptyState.args = {};
EmptyState.parameters = {
  updatedLandingPageRules: [],
};

export const GreaterOrLessOperator = Template.bind({});
GreaterOrLessOperator.args = {};
GreaterOrLessOperator.parameters = {
  ...Default.parameters,
};
GreaterOrLessOperator.play = async ({ canvasElement }) => {
  await userEvent.click(within(canvasElement).getByLabelText("Operator"));
  const listbox = await screen.findByRole("listbox");
  await userEvent.click(within(listbox).getByText("is less than"));
};

export const AttributeDropDownMenu = Template.bind({});
AttributeDropDownMenu.args = {};
AttributeDropDownMenu.parameters = {
  ...Default.parameters,
};
AttributeDropDownMenu.play = async ({ canvasElement }) => {
  await userEvent.click(within(canvasElement).getByLabelText("Attribute *"));
};
