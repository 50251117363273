import { useMemo, useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Suspenseful from '../Suspenseful'
import AddClientCampaignForm from './AddClientCampaignForm'
import ProgramGroupsResource from '../../resources/ProgramGroupsResource'
import { isNew } from '../../lib/DataModel'

export function AddClientCampaignModal({contract, clientId, open, onClose, onSave}) {
  const [clientCampaign, setClientCampaign] = useState({type: 'clientCampaign'})
  const programGroupsResource = useMemo(() => new ProgramGroupsResource(clientId && {type: 'client', id: clientId}), [clientId])

  // Clear the form when the modal is closed
  useEffect(() => {
    if(!open) {
      setClientCampaign({type: 'clientCampaign'})
    }
  }, [open])

  return (
    <Dialog
      aria-labelledby="modal-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="modal-title" onClose={onClose} sx={{paddingBottom: '4px'}}>
        {isNew(clientCampaign) ? 'Add New' : 'Edit'} Campaign
      </DialogTitle>
      <DialogContent>
        <AddClientCampaignForm
          contract={contract}
          clientCampaign={clientCampaign}
          programGroupsResource={programGroupsResource}
          onSave={onSave}
        />
      </DialogContent>
    </Dialog>
  )
}

export default function AddClientCampaignModalWrapper({clientId, contract, open, onClose, onSave}) {
   return (
     <Suspenseful
       component={AddClientCampaignModal}
       clientId={clientId}
       contract={contract}
       open={open}
       onClose={onClose}
       onSave={onSave}
       fallback={
         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
           <CircularProgress/>
         </div>
       }
     />
   )
 }
