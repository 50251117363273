import TableTitle from './TableTitle'
import TableContainer from './TableContainer'
import TableContent from './TableContent'
import type { SxProps } from "@mui/system"
import type { Column } from 'components/PerformanceTable/utils'
import { DataManager } from './DataManager'
import { ReactNode } from 'react'

export type ColumnLookupTable = Record<string,string>
export type ColumnLookup = ColumnLookupTable | Promise<ColumnLookupTable>

export interface BaseColumn<CellType = string | number> {
  title: string
  align?: string
  value?: (a: Record<string,CellType>) => unknown
  render?: (a: CellType) => (string | ReactNode)
  summary: ((a: Record<string, CellType>[]) => CellType) | ({text: string}) | (string)
  field?: string
  visible?: boolean
  groupable?: boolean
  lookup?: ColumnLookup
  sx?: SxProps
}

export interface Options {
  hover?: boolean
  summaryRow?: boolean
  summaryCollapse?: boolean
  sortColumn?: string
  sortOrder?: 1 | -1
  columnsButton?: boolean
  refreshButton?: boolean
  paginated?: boolean
  pageSize?: number
}

export default function SimpleTable({
  columns,
  data,
  title,
  actions = [],
  rowActions = [],
  options = {},
  dataManagerRef,
  onChangeColumns,
  onChange,
  hasTabNavigation = false,
  sx = null
} : {
  columns: Column[],
  data: unknown,
  title?: string,
  actions: unknown[],
  rowActions?: unknown[],
  options: Options,
  dataManagerRef: React.Ref<DataManager>,
  onChangeColumns?: (columns: Column[]) => void,
  onChange?: (value: boolean) => void,
  hasTabNavigation?: boolean,
  sx?: SxProps,
}) {
  return (
    <TableContainer
      columns={columns}
      data={data}
      options={options}
      dataManagerRef={dataManagerRef}
      onChangeColumns={onChangeColumns}
      onChange={onChange}
      hasTabNavigation={hasTabNavigation}
      sx={sx}
    >
      <TableTitle title={title} columnsButton={options.columnsButton} refreshButton={options.refreshButton} actions={actions} />
      <TableContent
        title={title}
        rowActions={rowActions}
        options={options}
      />
    </TableContainer>
  )
}
