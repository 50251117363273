import { isValidElement } from 'react'

import moment from '../../lib/moment'

type ROW_TYPE = 'data' | 'summary'

const RENDERERS = {
  date: value => value._isAMomentObject ? value.format('M/D/Y') : '',
  number: value => typeof(value) === 'number' ?  value.toLocaleString() : '',
}

const DEFAULT_RENDERER = value => value

export function rawCell(row, column) {
  if(column.type === 'date') {
    return moment(row[column.field])
  }
  else if(typeof(column.value) === 'function') {
    return column.value(row)
  }
  else if(typeof(column.lookup) === 'object') {
    return column.lookup[row[column.field]]
  } else {
    return row[column.field]
  }
}

export function renderCell(row, column, renderContext) {
  return renderValue(rawCell(row, column), row, column, 'data', renderContext)
}

export function getRendererFor(column, rowType) {
  return (rowType === 'summary' && column.renderSummary) || column.render || RENDERERS[column.type] || DEFAULT_RENDERER
}


function renderValue(value, row, column, rowType: ROW_TYPE = 'data', renderContext = undefined) {
  const rendered = getRendererFor(column, rowType)(value, row, renderContext)

  if(rendered === null || rendered === undefined) {
    return ''
  }

  if(isValidElement(rendered)) {
    return rendered
  }

  if(typeof(rendered) === 'number') {
    return rendered.toLocaleString()
  }

  return `${rendered}`
}

function rawSummary(data, column) {
  if(column.summary === 'sum') {
    return data.map(row => rawCell(row, column)).reduce((a,b) => a + b, 0)
  } else if(typeof(column.summary) === 'function') {
    return column.summary(data)
  }
  return ''
}

export function renderSummary(data, column, renderContext) {
  if(typeof(column.summary) === 'object') {
    return `${column.summary.text}`
  }

  return renderValue(rawSummary(data, column), {}, column, 'summary', renderContext)
}

function stripSymbols(value) {
  if(typeof(value) === 'string') {
    return value.replace(/[%$,]/g, '');
  } else {
    return value
  }
}

export function compareCells(value1, value2) {
  value1 = stripSymbols(value1)
  value2 = stripSymbols(value2)

  if(value1 === null || value1 === undefined) {
    if(value2 === null || value2 === undefined) {
      return 0
    } else {
      return -1
    }
  }

  if(value2 === null || value2 === undefined) {
    return 1
  }

  if(isFinite(value1) && isFinite(value2)) {
    return value1 - value2
  }
  else {
    return `${value1}`.localeCompare(`${value2}`)
  }
}
