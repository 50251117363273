import { Box, Container, CssBaseline } from "@mui/material";

import TimezoneProvider from "../../../lib/TimezoneProvider";
import ViewportProvider from "../../../lib/ViewportProvider";
import Sidebar from "./Sidebar";

export default {
  title: "UI/Sidebar",
  component: Sidebar,
};

const Template = (args) => {
  return (
    <ViewportProvider>
      <TimezoneProvider>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CssBaseline />
          <Sidebar {...args} />

          <Box
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container maxWidth={"lg"}></Container>
          </Box>
        </Box>
      </TimezoneProvider>
    </ViewportProvider>
  );
};

export const Expanded = Template.bind({});
Expanded.args = {
  authenticityToken: "",
  currentUser: {
    type: "user",
    id: "127",
    attributes: {
      email: "foo@bar.com",
      teamAdmin: true,
      name: "Test User",
    },
  },
  currentTeam: {
    type: "team",
    id: "1",
    attributes: {
      name: "Cygnus Education",
      siteAdmin: true,
    },
    relationships: {
      client: {
        data: null,
      },
      vendor: {
        data: {
          type: "vendor",
          id: "1cf2714a-c097-4ad9-bdce-96bea8547f80",
        },
      },
    },
  },
  initiallyExpanded: true,
};
Expanded.parameters = {
  reactRouter: {
    routePath: "/clients",
  },
};

export const Collapsed = Template.bind({});
Collapsed.args = {
  ...Expanded.args,
  initiallyExpanded: false,
};
Collapsed.parameters = {
  ...Expanded.parameters,
};
