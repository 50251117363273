import { useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Dialog from '@mui/material/Dialog';

import { DialogTitle, DialogContent } from '../../dialogs';
import SimpleTable from '../../SimpleTable';
import { failOnHttpError } from '../../../lib/utils'
import { loadTableData } from '../../table-utils';
import { Form, FormTextField } from '../../Formik/forms';

import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import MainContainer from "components/layout/MainContainer/MainContainer";
import { useNotifications } from "lib/NotificationsProvider";

const COLUMNS = [
  { title: 'ID', field: 'id', type: 'numeric', width: '0px', visible: true },
  { title: 'Name', field: 'name', visible: true },
  { title: 'Site Admin?', field: 'siteAdmin', render: siteAdmin => siteAdmin ? <CheckIcon/> : null, visible: true },
]

const AddTeamModal = function(props) {
  const [team, setTeam] = useState(props.team);

  return (
    <Dialog aria-labelledby="modal-title" open={true} onClose={props.onClose}>
      <DialogTitle id="modal-title" onClose={props.onClose}>
        {team.id ? 'Edit' : 'Add'} team
      </DialogTitle>
      <DialogContent>
        <Form
          resource={team}
          setResource={setTeam}
          onSave={props.onSave}
          redirectAfterSave={false}
          promptOnNavigate={false}
          backwardsCompatibleMargins={true}
        >
          <FormTextField name="name" label="Name"/>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default function TeamTable() {
  const { addNotification } = useNotifications()
  const [teamForModal, setTeamForModal] = useState();
  const dataManagerRef = useRef(null);
  const navigate = useNavigate();

  const refreshTable = () => {
    dataManagerRef.current && dataManagerRef.current.reload();
  };

  const handleDelete = (_event, rowData) => {
    if(!confirm(`Are you sure you want to delete the team ${rowData['name']}?`)) {
      return;
    }

    fetch(`/api/v1/teams/${rowData.id}`, { method: 'DELETE' })
      .then(failOnHttpError)
      .then(() => {
        addNotification({variant: 'notice', message: 'Deleted team.'});
        refreshTable();
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to delete team.'});
      });
  };

  const handleEdit = (_event, rowData) => {
    setTeamForModal({type: 'team', id: rowData.id, attributes: {name: rowData.name}});
  };

  const handleManage = (_event, rowData) => {
    navigate(`/teams/${rowData.id}/users`)
  };

  const handleAdd = (_event, _rowData) => {
    setTeamForModal({type: 'team', attributes: {name: '', email: '', password: ''}});
  };

  const closeModal = () => {
    setTeamForModal(null);
  };

  const afterAddTeam = (_team) => {
    closeModal();
    refreshTable();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataFn = useCallback(loadTableData(q => q.findRecords('team'), addNotification), [addNotification])

  return (
    <MainContainer>
      <div style={{ maxWidth: "100%" }}>
        <SimpleTable
          dataManagerRef={dataManagerRef}
          columns={COLUMNS}
          padding="dense"
          data={dataFn}
          options={{
            paginated: true,
            pageSize: 10,
            columnsButton: true,
          }}
          title="Teams"
          rowActions={[
            {
              icon: () => <DeleteOutlineIcon/>,
              tooltip: 'Delete Team',
              onClick: handleDelete,
            },
            {
              icon: () => <EditIcon/>,
              tooltip: 'Edit team',
              onClick: handleEdit,
            },
            {
              icon: () => <PeopleIcon/>,
              tooltip: 'Manage team members',
              onClick: handleManage,
            },
          ]}
          actions={[
            {
              icon: () => <AddCircleIcon/>,
              isFreeAction: true,
              tooltip: 'Add new team',
              onClick: handleAdd,
            },
          ]}
        />
      </div>
      {teamForModal && (
        <AddTeamModal
          team={teamForModal}
          onClose={closeModal}
          onSave={afterAddTeam}
        />
      )}
    </MainContainer>
  )
}
