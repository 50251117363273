import _moment from 'moment-timezone'

let moment

if(window.overrideCurrentTime) {
  moment = function(...args) {
    if(args.length === 0) {
      return _moment(window.overrideCurrentTime)
    } else {
      return _moment(...args)
    }
  }
  Object.assign(moment, _moment)
  moment.tz = function(...args) {
    if(args.length === 1) {
      return _moment.tz(window.overrideCurrentTime, ...args)
    } else {
      return _moment.tz(...args)
    }
  }
  Object.assign(moment.tz, _moment.tz)
} else {
  moment = _moment
}

export default moment
