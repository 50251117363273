import { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { useTimezone } from '../../../lib/TimezoneProvider'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import SimpleTable from '../../SimpleTable'
import DatePickerPopover from '../../DatePickerPopover';
import Suspenseful from '../../Suspenseful'
import MTDPacingChart from '../../analytics/MTDPacingChart'
import MTDPacingTable from '../../analytics/MTDPacingTable'
import { ReportingModeSelector } from '../../UI/ReportingModeSelector/ReportingModeSelector'

import FilterInactiveImage from 'filter-inactive.png'
import FilterActiveImage from 'filter-active.png'

import { dateRangeToTimeRange, formatDateRange, pastDays, timeRangeToDateRange } from '../../../lib/time'
import { buildQueryString, failOnHttpError, toPercent, sum, roundEstimate, toDollars, getStorage, setStorage } from '../../../lib/utils'
import { camelize, underscore } from '@orbit/serializers'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import MainContainer from 'components/layout/MainContainer/MainContainer'
import COLORS from 'lib/colors'

const useStyles = makeStyles(theme => ({
  dateButton: {
    color: theme.palette.text.secondary,
  },
  dateButtonActive: {
    color: theme.palette.primary.main,
  },
  currentRange: {
    textDecoration: 'underline',
  },
  filterIcon: {
    width: 12,
    height: 8.5,
    marginLeft: 12,
  },
  widget: {
    padding: '12px 20px !important',
  },
  widgetOnTrack: {
  },
  widgetOffTrack: {
  },
  widgetIndeterminate: {
  },
  widgetCount: {
    fontSize: 18,
    fontWeight: 700,
    '$widgetOnTrack &': {
      color: theme.palette.success.main,
    },
    '$widgetOffTrack &': {
      color: theme.palette.error.main,
    },
    '$widgetIndeterminate &': {
      color: COLORS.slateGray,
    },
  },
  widgetTitle: {
    fontSize: 13,
    fontWeight: 600,
    color: '#000000AD',
    textAlign: 'left',
  },
  widgetSubtitle: {
    fontSize: 13,
    fontWeight: 600,
    color: '#00000080',
    textAlign: 'left',
  },
  widgetProgressContainer: {
    position: 'relative',
    display: 'flex',
  },
  widgetProgress: {
    '$widgetOnTrack &': {
      color: theme.palette.success.main,
    },
    '$widgetOffTrack &': {
      color: theme.palette.error.main,
    },
    '$widgetIndeterminate &': {
      color: COLORS.slateGray,
    },
    position: 'absolute',
    left: 0,
  },
  widgetProgressBackground: {
    '$widgetOnTrack &': {
      color: theme.palette.success.light,
    },
    '$widgetOffTrack &': {
      color: theme.palette.error.light,
    },
    '$widgetIndeterminate &': {
      color: '#e0e0e0',
    },
  },
  widgetProgressLabel: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 700,
    '$widgetOnTrack &': {
      color: theme.palette.success.main,
    },
    '$widgetOffTrack &': {
      color: theme.palette.error.main,
    },
    '$widgetIndeterminate &': {
      color: COLORS.slateGray,
    },
  },
}))

function DateRangeButton({dateRange, setDateRange}) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'dateRange' })
  const classes = useStyles()
  const { timezone } = useTimezone()

  return (
    <>
      <span className={classes.dateButton}>
        Showing:
      </span>
      <Button size="small" variant="text" {...bindTrigger(popupState)} className={clsx({[classes.dateButton]: true, [classes.dateButtonActive]: popupState.isOpen})} aria-label="Select Date Range">
        <span className={classes.currentRange}>
          {formatDateRange(dateRange, 'M/D/Y')}
        </span>
        <img alt='filter' src={popupState.isOpen ? FilterActiveImage : FilterInactiveImage} className={classes.filterIcon}/>
      </Button>
      <DatePickerPopover
        {...bindPopover(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onChange={(timeRange) => setDateRange(timeRangeToDateRange(timeRange))}
        value={dateRangeToTimeRange(dateRange, timezone)}
      />
    </>
  )
}

function StatsTable({url, groupingColumns, initialDateRange}) {
  const { timezone } = useTimezone()
  const [dateRange, setDateRange] = useState(() => initialDateRange || timeRangeToDateRange(pastDays(1, timezone)))
  const [reportingMode, setReportingMode] = useState(() => getStorage('Dashboard.reportingMode') || 'cohort')
  useEffect(() => {
    setStorage('Dashboard.reportingMode', reportingMode)
  }, [reportingMode])

  const getData = useCallback(() => {
    const timeRange = dateRangeToTimeRange(dateRange, timezone)
    return fetch(`${url}?${buildQueryString({timezone: timezone, start_time: timeRange && timeRange[0].format(), end_time: timeRange && timeRange[1].format(), group_by: groupingColumns.map(col => underscore(col.field)), reporting_mode: reportingMode})}`)
      .then(failOnHttpError)
      .then(response => response.json())
      .then(response => ({
        data: response.data.performance.map(row => Object.fromEntries(Object.entries(row).map(([key, value]) => [camelize(key), value])))
      }))
  }, [dateRange, groupingColumns, reportingMode, timezone, url])

  const actions = useMemo(() => [
    {
      component: ({ key = null } = {}) => <ReportingModeSelector key={key} sx={{ mr: 1 }} value={reportingMode} setValue={setReportingMode} />
    },
    {
      component: ({ key = null } = {}) => <DateRangeButton key={key} dateRange={dateRange} setDateRange={setDateRange}/>
    },
  ], [dateRange, reportingMode])

  const columns = useMemo(() => [
    ...groupingColumns,
    { title: 'Total Leads', align: 'right', field: 'leadCount', summary: 'sum', visible: true },
    { title: 'Good Leads', align: 'right', field: 'goodLeadCount', summary: 'sum', visible: true },
    { title: 'Bad Leads', align: 'right', value: row => row.leadCount - row.goodLeadCount, summary: 'sum', visible: true },
    { title: 'Good Lead Rate', align: 'right', value: row => row.goodLeadCount / row.leadCount, render: value => toPercent(value), summary: data => sum(data, 'goodLeadCount')/sum(data, 'leadCount'), visible: true },
    { title: 'Spend', align: 'right', value: row => row.totalCostInCents || 0, render: value => toDollars(value), summary: data => sum(data, 'totalCostInCents'), visible: true },
    { title: 'CPL', align: 'right', value: row => row.totalCostInCents/row.goodLeadCount, render: value => toDollars(value) || '—', summary: data => sum(data, 'totalCostInCents')/sum(data, 'goodLeadCount'), visible: true },
    { title: 'CPE', align: 'right', value: row => row.totalCostInCents/row.enrolledLeadCount, render: value => toDollars(value) || '—', summary: data => sum(data, 'totalCostInCents')/sum(data, 'enrolledLeadCount'), visible: true },
    { title: 'Enrolled Leads', field: 'enrolledLeadCount', align: 'right', summary: 'sum', visible: true },
    { title: 'Enroll Rate', align: 'right', value: row => row.enrolledLeadCount/row.goodLeadCount, render: value => toPercent(value), summary: data => sum(data, 'enrolledLeadCount')/sum(data, 'goodLeadCount'), visible: true },
  ], [groupingColumns])

  return <SimpleTable
    title="Leads Stats"
    data={getData}
    columns={columns}
    options={{
      summaryRow: true,
      hover: true,
      columnsButton: true,
      refreshButton: true,
      sortColumn: groupingColumns[0]?.field,
    }}
    actions={actions}
  />
}

// TODO: separate controller actions for pacing and performance
function GoodLeadsWidget({currentMonthPacingResource}) {
  const currentMonthPacing = currentMonthPacingResource.get()
  const count = useMemo(() => sum(currentMonthPacing, 'goodLeadCount'), [currentMonthPacing])
  const cap = useMemo(() => sum(currentMonthPacing, 'monthlyCap'), [currentMonthPacing])
  const projectedCount = useMemo(() => sum(currentMonthPacing, 'mtdProjectedCount'), [currentMonthPacing])
  const onTrack = useMemo(() => {
    if(currentMonthPacing.length === 0) {
      return null
    }
    if(currentMonthPacing.some(row => !row.monthlyCap && row.monthlyCap !== 0) || currentMonthPacing.every(row => row.monthlyCap === 0)) {
      return null
    }
    return projectedCount >= cap * 0.9 && projectedCount <= cap * 1.1
  }, [currentMonthPacing, projectedCount, cap])

  return (
    <GoodLeadsWidgetInternal count={count} cap={cap} projectedCount={projectedCount} onTrack={onTrack}/>
  )
}

function MTDGraphWidget({dailyCountsResource, currentMonthPacingResource}) {
  const currentMonthPacing = currentMonthPacingResource.get()
  const dailyCounts = dailyCountsResource.get()
  const monthlyCap = useMemo(() => sum(currentMonthPacing, 'monthlyCap'), [currentMonthPacing])
  const { year, month, currentDay } = dailyCountsResource

  return (
    <>
      <Typography variant="h4">
        MTD Pacing
      </Typography>

      <MTDPacingChart
        year={year}
        month={month}
        currentDay={currentDay}
        dailyCounts={dailyCounts}
        monthlyCap={monthlyCap}
        width="100%"
        height={240}
      />
    </>
  )
}

function GoodLeadsWidgetInternal({count, cap, projectedCount, onTrack}) {
  const classes = useStyles()

  const roundedCount = useMemo(() => roundEstimate(count), [count])
  const percent = useMemo(() => cap ? Math.round(100 * projectedCount/cap) : '—', [projectedCount, cap])
  const progressPercent = useMemo(() => Math.min(cap ? percent : 0, 100), [percent, cap])

  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-between" className={onTrack === true ? classes.widgetOnTrack : onTrack === false ? classes.widgetOffTrack : classes.widgetIndeterminate}>
      <Grid item>
        <div className={classes.widgetCount}>
          {roundedCount}
        </div>
      </Grid>
      <Grid item>
        <div className={classes.widgetTitle}>
          Good Leads
        </div>
        <div className={classes.widgetSubtitle}>
          MTD
        </div>
      </Grid>
      <Grid item>
        <div className={classes.widgetProgressContainer}>
          <CircularProgress
            variant="determinate"
            className={`${classes.widgetProgressBackground}`}
            size={50}
            thickness={2}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            value={progressPercent}
            className={`${classes.widgetProgress}`}
            size={50}
            thickness={2}
          />
          <div className={`${classes.widgetProgressLabel}`}>
            {percent}%
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default function Dashboard({groupingColumns, dashboardUrl, dailyCountsResource, currentMonthPacingResource, refreshCurrentMonthPacingResource, initialDateRange = null}) {
  const classes = useStyles()

  return (
    <MainContainer>
      <Grid container direction="row" spacing={2}>
        <Grid item lg={9} xs={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <StatsTable url={dashboardUrl} groupingColumns={groupingColumns} initialDateRange={initialDateRange} />
            </Grid>
            <Grid item>
              <MTDPacingTable groupingColumns={groupingColumns} currentMonthPacingResource={currentMonthPacingResource} refreshCurrentMonthPacingResource={refreshCurrentMonthPacingResource} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item lg={12} xs={6}>
              <Card>
                <CardContent className={classes.widget}>
                  <Suspenseful component={GoodLeadsWidget} currentMonthPacingResource={currentMonthPacingResource}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} xs={6}>
              <Card>
                <CardContent>
                  <Suspenseful component={MTDGraphWidget} currentMonthPacingResource={currentMonthPacingResource} dailyCountsResource={dailyCountsResource}/>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  )
}
