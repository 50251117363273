import { gql } from "@apollo/client";
import { LANDING_PAGE_FIELDS } from "fragments/LandingPageFields";
import { DEGREE_PROGRAM_FIELDS } from "fragments/DegreeProgramFields"
import { CLIENT_CAMPAIGN_FIELDS } from "fragments/ClientCampaignFields"
import { FIELD_FIELDS } from "fragments/FieldFields"

export const GET_LANDING_PAGE_QUERY = gql`
  ${LANDING_PAGE_FIELDS}
  query GetLandingPage($id: ID!) {
    landingPage(id: $id) {
      ...LandingPageFields
    }
  }
`

export const GET_CONTRACTS_QUERY = gql`
  ${FIELD_FIELDS}
  query GetContracts($clientIds: [ID!], $vendorId: ID!) {
    contracts(clientIds: $clientIds, vendorId: $vendorId) {
      client {
        id
        name
        internalName
        fields {
          ...FieldFields
        }
      }
    }
  }
`

export const GET_PROGRAM_GROUPS_QUERY = gql`
  ${DEGREE_PROGRAM_FIELDS}
  query GetProgramGroups($clientId: ID!) {
    programGroups(clientId: $clientId) {
      id
      description
      client {
        id
        internalName
        name
      }
      degreePrograms {
        ...DegreeProgramFields
      }
    }
  }
`

export const GET_CLIENT_CAMPAIGNS_QUERY = gql`
  ${CLIENT_CAMPAIGN_FIELDS}
  query GetClientCampaigns($programGroupIds: [ID!], $vendorId: ID!) {
    clientCampaigns(programGroupIds: $programGroupIds, vendorId: $vendorId) {
      ...ClientCampaignFields
    }
  }
`

export const GET_CLIENT_CAMPAIGNS_WITH_PROGRAM_GROUP_QUERY = gql`
  query GetClientCampaigns($programGroupIds: [ID!], $vendorId: ID!) {
    clientCampaigns(programGroupIds: $programGroupIds, vendorId: $vendorId) {
      id
      name
      campaignType
      programGroup {
        id
        description
      }
    }
  }
`

export const GET_CLIENTS_QUERY = gql`
  ${FIELD_FIELDS}
  query GetClients {
    clients {
      id
      internalName
      name
      fields {
        ...FieldFields
      }
    }
  }
`

export const GET_FILTERED_LEADS_CLIENTS_QUERY = gql`
  query GetFilteredLeadsClients {
    filteredLeadsClients {
      id
      name
      internalName
      programGroups {
        id
        degreePrograms {
          id
          name
        }
      }
    }
  }
`
