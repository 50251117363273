import { useCallback, useEffect, useRef, useState } from 'react'

export function useAfterEffect(callback, dependencies) {
  const [isFirst, setIsFirst] = useState(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => isFirst ? setIsFirst(false) : callback(), dependencies)
}

export function useDeepEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, dependencies.map(JSON.stringify))
}

export function useRefreshable(callback, dependencies) {
  const [_version, setVersion] = useState(0)
  const cache = useRef(null)
  if(!cache.current || cache.current.dependencies.some((dep, index) => dep !== dependencies[index])) {
    cache.current = { dependencies, value: callback(...dependencies) }
  }

  const refreshResource = useCallback(() => {
    setVersion(current => current + 1) // trigger a rerender
    cache.current = { dependencies, value: callback(...dependencies) }
    return cache.current.value
  }, [callback, dependencies])

  return [cache.current.value, refreshResource]
}
