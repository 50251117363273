import { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'

export const GET_CLIENT_METADATA = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      metadata {
        statuses {
          key
          value
        }
        dispositions {
          key
          value
        }
      }
    }
  }
`

// FIXME: this is duplicated from Client.default_statuses
const DEFAULT_STATUSES = {
  pending:              'Pending',
  filtered:             'Filtered',
  rejected:             'Rejected',
  error:                'Error',
  returned:             'Returned',
  new_lead:             'New lead',
  contacted:            'Contacted',
  interviewed:          'Interviewed',
  qualified:            'Qualified',
  application_started:  'Application Started',
  applied:              'Applied',
  admitted:             'Admitted',
  confirmed_acceptance: 'Confirmed Acceptance',
  enrolled:             'Enrolled',
  started:              'Started',
  matriculated:         'Matriculated',
  class_passed:         'Class Passed',
  graduated:            'Graduated'
}

// FIXME: this is duplicated from Client.default_dispositions
const DEFAULT_DISPOSITIONS = {
  not_qualified:  'Not Qualified',
  not_interested: 'Not Interested',
  closed_lost:    'Closed Lost',
  bad_lead:       'Bad Lead',
}

export default function useClientMetadata(clientId) {
  const query = useQuery(GET_CLIENT_METADATA, {
    variables: {
      id: clientId
    },
    skip: !clientId,
  })

  return useMemo(() => {
    const metadata = query?.data?.client?.metadata

    return {
      query,
      statuses: metadata ? Object.fromEntries(metadata.statuses.map(pair => [pair.key, pair.value])) : DEFAULT_STATUSES,
      dispositions: metadata ? Object.fromEntries(metadata.dispositions.map(pair => [pair.key, pair.value])) : DEFAULT_DISPOSITIONS,
    }
  }, [query])
}
