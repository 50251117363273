import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { useContract } from '../../../lib/ContractProvider';
import { useTimezone } from '../../../lib/TimezoneProvider';

import SimpleTable from '../../SimpleTable';
import UploadDialog from '../../UploadDialog';
import LeadDetailsDrawer from '../../LeadDetailsDrawer/LeadDetailsDrawer';
import { remote, getRemoteId } from '../../../lib/DataModel'
import { download, failOnHttpError, getStorage, groupBy, setStorage, stripHTML, titlecase } from '../../../lib/utils'
import { buildQueryString, loadTableData } from '../../table-utils';
import useClientMetadata from '../../../hooks/useClientMetadata'

import DeleteImage from 'delete-icon.png'
import DetailsImage from 'details-icon.png'

import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import { teamIsAdmin, teamIsVendor } from '../../../lib/auth-helpers'

import { getIn } from '../../Formik/forms'
import Box from "@mui/material/Box";
import { useNotifications } from "lib/NotificationsProvider";
import CygIconButton from "components/UI/Button/CygIconButton";
import { AkkioLoadingDialog } from "./AkkioMock";
import { useFeatures } from "providers/FeaturesProvider";
import PlainDate from "lib/PlainDate";
import moment from 'lib/moment';

export const COLUMNS = [
  { title: 'VEGA ID', field: 'publicId' },
  { title: 'Date', field: 'createdAt', type: 'date' },
  { title: 'Client', field: 'clientId', lookup: {}, subfield: 'degreeProgramId' },
  { title: 'Vendor', field: 'vendorId', lookup: {} },
  { title: 'Degree Program', field: 'degreeProgramId', lookup: {} },
  { title: 'External ID', field: 'externalId' },
  { title: 'SubID', field: 'subid' },
  { title: 'Source', field: 'source', render: source => source || '(uncategorized)' },
  { title: 'Campaign Type', field: 'campaignType', lookup: { exclusive: 'Exclusive', shared: 'Shared' }, render: campaignType => titlecase(campaignType) },
  { title: 'First name', field: 'firstName' },
  { title: 'Last name', field: 'lastName' },
  { title: 'Email', field: 'email' },
  { title: 'Phone Number', field: 'phone' },
  { title: 'Street Address', field: 'streetAddress' },
  { title: 'City', field: 'city' },
  { title: 'State', field: 'state' },
  { title: 'ZIP Code', field: 'zipCode' },
  { title: 'Status', field: 'status', lookup: {}},
  { title: 'Disposition', field: 'disposition', lookup: {}},
  { title: 'Status changed at', field: 'statusChangedAt', type: 'datetime' },
  { title: 'Billable', field: 'billable', render: (billable, _row) => {
    return (
    <Chip
      label={billable}
      color={billable === 'Yes' ? 'success' : 'error'}
    />
  )}, lookup: {
    true: 'Yes',
    false: 'No',
  }, filterExclusive: true},
  { title: 'Lead amount', field: 'leadAmountCents', render: leadAmountCents => {
    return leadAmountCents && `$${Math.floor(leadAmountCents/100)}.${(leadAmountCents%100).toString().padStart(2,0)}`
  }},
  { title: 'IP Address', field: 'remoteIp' },
  { title: 'User Agent', field: 'userAgent' },
  { title: 'Secondary TCPA', field: 'secondaryTcpa', lookup: {
    true: 'Yes',
    false: 'No',
  }},
  { title: 'Rejection reason', field: 'rejectionReason' },
  { title: 'Filtered reason', field: 'filteredReason' },
  { title: 'Raw request parameters', field: 'rawParams' },
  { title: 'Tracking parameters', field: 'trackingParams' },
  { title: 'Test', field: 'test', type: 'checkbox', render: test => (
    <Chip
      label={test ? 'Yes' : 'No'}
      color={test ? 'success' : 'error'}
    />
  )},
  { title: 'Landing Page ID', field: 'landingPageId' }
]

// FIXME: duplicated in PerformanceTable
const mapToName = function(records) {
  return records.reduce((hash, record) => { hash[getRemoteId(record)] = stripHTML(record.attributes.internalName || record.attributes.name || record.attributes.description); return hash; }, {});
};

function populateColumns({isAdmin, isVendor, vendorId, timezone, columnProps}) {
  const visibleColumns = getStorage('leads.visibleColumns') || ['createdAt', 'clientId', 'degreeProgramId', 'subid', 'source', 'campaignType', 'firstName', 'lastName', 'email', 'status', 'billable', 'rejectionReason', 'filteredReason']

  return COLUMNS
    .filter(column => isAdmin || !['clientId', 'landingPageId'].includes(column.field))
    .filter(column => !isVendor || column.field !== 'vendorId')
    .map(column => {
      if (column.field === 'createdAt') {
        return { ...column, defaultFilter: { operator: 'between', value: [PlainDate.from(moment.tz(timezone).format('YYYY-MM-DD')), PlainDate.from(moment.tz(timezone).format('YYYY-MM-DD'))] }}
      } else if (isAdmin && vendorId && column.field === 'vendorId') {
        return { ...column, defaultFilter: { operator: 'list', value: [`${vendorId}`] }}
      } else
        return column
    })
    .map(column => ({
      ...column,
      ...columnProps[column.field],
      visible: visibleColumns.includes(column.field),
    }))
    .sort((a,b) => a.visible ? (b.visible ? (visibleColumns.indexOf(a.field) - visibleColumns.indexOf(b.field)) : -1) : (b.visible ? 1 : 0))
}

export default function LeadTable({currentTeam, contract = null}) {
  const { addNotification } = useNotifications()
  const isAdmin = teamIsAdmin({currentTeam})
  const isVendor = teamIsVendor({currentTeam})

  const [clientIdToName, setClientIdToName] = useState({});
  const [vendorIdToName, setVendorIdToName] = useState({});
  const [degreeProgramIdToName, setDegreeProgramIdToName] = useState({});
  const [clientIdToDegreePrograms, setClientIdToDegreePrograms] = useState({});
  const [loading, setLoading] = useState(true);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showLeadDetails, setShowLeadDetails] = useState();
  const { timezone } = useTimezone()
  const vendorId = getRemoteId(getIn(currentTeam, 'relationships.vendor.data'))
  const dataManagerRef = useRef(null)
  const clientId = useMemo(() => contract ? getIn(contract, 'relationships.client.data.id') : getIn(currentTeam, 'relationships.client.data.id'), [contract, currentTeam])
  const { isFeatureFlagEnabled, getFeatureConfig } = useFeatures()
  const [akkio, setAkkio] = useState(0)
  const showAkkioMock = isFeatureFlagEnabled('akkio_mock')
  const akkioMockUrl = getFeatureConfig('akkio_mock', 'akkio_url', "https://app.akkio.com/flow/nfy3Lyj1zHBBNq6qmHwC")

  useEffect(() => {
    const clientPromise = isAdmin ? remote.query(q => q.findRecords('client')) : []
    const promises = [
      clientPromise,
      contract ? [] : remote.query(q => q.findRecords('vendor')),
      remote.query(q => contract ? q.findRelatedRecords(contract, 'degreePrograms') : q.findRecords('degreeProgram'), {
        sources: {
          remote: {
            settings: {
              params: {
                fields: {
                  'degree-program': ['name', 'client'].join(',')
                }
              }
            }
          }
        }
      }),
    ]
    Promise.all(promises).then(([clients, vendors, degreePrograms]) => {
      setClientIdToName(mapToName(clients));
      setVendorIdToName(mapToName(vendors));
      setDegreeProgramIdToName(mapToName(degreePrograms));
      setClientIdToDegreePrograms(groupBy(degreePrograms, 'relationships.client.data.id', { transform: degreeProgram => degreeProgram.id }))
      setLoading(false);
    }).catch(error => {
      console.log(error);
      addNotification({variant: 'alert', message: 'Error loading leads'})
      setLoading(false);
    })
  }, [addNotification, contract, isAdmin]);

  const { query: metadataQuery, statuses, dispositions } = useClientMetadata(isAdmin ? null : clientId)

  const columns = useMemo(
    () => populateColumns({
      isAdmin,
      isVendor,
      vendorId,
      timezone,
      columnProps: {
        clientId: { lookup: clientIdToName, subfieldMap: clientIdToDegreePrograms },
        vendorId: { lookup: vendorIdToName },
        degreeProgramId: { lookup: degreeProgramIdToName },
        status: { lookup: statuses },
        disposition: { lookup: dispositions },
      },
    }),
    [clientIdToDegreePrograms, clientIdToName, degreeProgramIdToName, dispositions, isAdmin, isVendor, statuses, timezone, vendorId, vendorIdToName]
  )

  const dataFn = useMemo(() => loadTableData(q => contract ? q.findRelatedRecords(contract, 'leads') : q.findRecords('lead'), addNotification, timezone), [contract, addNotification, timezone])

  const onChangeColumns = useCallback(columns => {
    setStorage('leads.visibleColumns', columns.filter(column => column.visible).map(column => column.field))
  }, [])

  const refreshTable = useCallback(() => {
    dataManagerRef.current && dataManagerRef.current.reload();
  }, [dataManagerRef])
  const handleDelete = useCallback((_event, rowData) => {
    if(!confirm(`Are you sure you want to delete the lead ${rowData['firstName']} ${rowData['lastName']}?`)) {
      return;
    }

    fetch(remote.requestProcessor.urlBuilder.resourceURL('lead', rowData.id), { method: 'DELETE' })
      .then(failOnHttpError)
      .then(() => {
        addNotification({variant: 'notice', message: 'Deleted lead.'});
        refreshTable();
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Failed to delete lead.'});
      });
  }, [addNotification, refreshTable])

  const handleExport = useCallback(_event => {
    if(!dataManagerRef.current) {
      return
    }
    const urlBuilder = remote.requestProcessor.urlBuilder
    const baseURL = contract ? urlBuilder.relatedResourceURL(contract.type, contract.id, 'leads') : urlBuilder.resourceURL('lead')
    download(`${baseURL}.csv?${buildQueryString(dataManagerRef.current.getDataOptions())}`, 'leads.csv')
  }, [contract])

  const handleImport = useCallback(_event => {
    setShowUploadDialog(true);
  }, [setShowUploadDialog])

  const handleShowDetails = useCallback((_event, rowData) => {
    setShowLeadDetails(rowData)
  }, [setShowLeadDetails])

  const handleHideDetails = useCallback((_event) => {
    setShowLeadDetails(null)
  }, [setShowLeadDetails])

  const handleAkkio = useCallback(() => {
    setAkkio(1)
  }, [])

  if(loading || metadataQuery.loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress/>
      </div>
    )
  } else {
    const rowActions = [{
      icon: DetailsImage,
      tooltip: 'Lead Details',
      onClick: handleShowDetails,
    }]
    const actions = [
      {
        icon: <DownloadIcon />,
        tooltip: 'Export to CSV',
        onClick: handleExport,
      },
    ]

    if(isAdmin) {
      rowActions.push({
        icon: DeleteImage,
        tooltip: 'Delete Lead',
        onClick: handleDelete,
      })
      actions.push({
        icon: <UploadIcon />,
        tooltip: 'Import CSV',
        onClick: handleImport,
      })
    }

    // TODO: the data prop changes every time LeadTable is rendered, which causes
    // the SweetTable to get unnecessarily rerendered.
    return (
      <>
        {akkio === 1 && (
          <AkkioLoadingDialog onClose={() => setAkkio(0)} onComplete={() => setAkkio(2)}/>
        )}
        {akkio === 2 && (
          <Box sx={{ width: "100%", height: "100%", py: 4, px: 3, position: 'relative' }}>
            <Box style={{ position: 'absolute', top: '24px', left: '18px', width: '98px', height: '65px', background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => setAkkio(0) }>
              &larr; Back
            </Box>
            <iframe style={{width: '100%', height: '100%', border: 'none', backgroundColor: 'white'}} src={akkioMockUrl}></iframe>
          </Box>
        )}
        <Box sx={{ maxWidth: "100%", py: 4, px: 3, display: akkio === 2 ? 'none' : null }}>
          <SimpleTable
            dataManagerRef={dataManagerRef}
            columns={columns}
            data={dataFn}
            options={{
              filtering: true,
              paginated: true,
              pageSize: 10,
              columnsButton: true,
              sortColumn: 'createdAt',
              sortOrder: -1
            }}
            title={showAkkioMock ? (
              <Box sx={{ mt: -1 }} >
                <Box>
                  Leads
                </Box>
                <Box>
                  <CygIconButton icon={<AnalyticsOutlinedIcon/>} text="Launch AI Dashboard" onClick={handleAkkio} sx={{ mt: 3, mb: -3 }} />
                </Box>
              </Box>
            )
              : 'Leads'
            }
            onChangeColumns={onChangeColumns}
            actions={actions}
            rowActions={rowActions}
          />
          {showUploadDialog && <UploadDialog onComplete={refreshTable} onClose={() => setShowUploadDialog(false)}/>}
          <LeadDetailsDrawer columns={columns} lead={showLeadDetails} onClose={handleHideDetails}/>
        </Box>
      </>
    );
  }
}

export function LeadTableForContract({currentTeam}) {
  const { contract } = useContract()

  return contract && (
    <LeadTable currentTeam={currentTeam} contract={contract}/>
  ) || null
}
