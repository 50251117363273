import { remote } from '../lib/DataModel'

import PromisedResource from './PromisedResource'

function getPromise(dateRange, parentRecord) {

  const options = {
    sources: {
      remote: {
        settings: {
          params: {
            start_time: dateRange[0].format(),
            end_time: dateRange[1].format(),
          }
        }
      }
    }
  }

  if(parentRecord) {
    return remote.query(q => q.findRelatedRecords(parentRecord, 'stateStats'), options)
  } else {
    return remote.query(q => q.findRecords('stateStat'), options)
  }
}

export default class StateStatsResource extends PromisedResource {
  constructor(dateRange, parentRecord) {
    super(getPromise(dateRange, parentRecord))
  }
}
