import React, { useContext, useState } from 'react'

const contractContext = React.createContext({ contract: null, setContract: null })

export default function ContractProvider({ initialContract = null, children }) {
  const [contract, setContract] = useState(initialContract)

  return (
    <contractContext.Provider value={{ contract, setContract }}>
      {children}
    </contractContext.Provider>
  )
}

export function useContract() {
  return useContext(contractContext)
}
