import MainContainer from 'components/layout/MainContainer/MainContainer'
import AllocationsTable from '../../AllocationsTable'
import BookendsProvider from 'lib/BookendsProvider'
import FooterBar from 'components/UI/FooterBar'
import { useParams } from 'react-router-dom'

export default function Allocations({ isClient = false, isVendor = false }) {
  const { clientId } = useParams()

  return (
    <BookendsProvider>
      <MainContainer>
        <AllocationsTable
          clientId={clientId}
          isClient={isClient}
          isVendor={isVendor}
          hasTabNavigation={!isVendor}
        />
      </MainContainer>

      <FooterBar/>
    </BookendsProvider>
  )
}
