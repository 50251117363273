import { Bucket, Task, TaskQueue } from '@orbit/core'
import { buildQuery, QueryExpression, QueryOrExpressions, RequestOptions } from '@orbit/data'
import { InitializedRecord, RecordKeyMap, RecordSchema, UninitializedRecord } from '@orbit/records'
import JSONAPISource, { JSONAPISerializers, JSONAPIResourceIdentitySerializer, QueryRequestProcessorResponse, ResourceDocument } from '@orbit/jsonapi'
import {
  buildInflector,
  buildSerializerClassFor,
  buildSerializerSettingsFor,
  StringSerializer,
} from '@orbit/serializers'
import MemorySource from '@orbit/memory'
import Coordinator, { RequestStrategy, SyncStrategy } from '@orbit/coordinator'
import { invert } from './utils'
import { airbrake } from 'shared'
import { saveWithPayload } from './orbit/save-with-payload'

const schema = new RecordSchema({
  models: {
    client: {
      attributes: {
        name: { type: "string" },
        internalName: { type: "string" },
        numPrograms: { type: "number" },
        logo: { type: "string" },
        complianceLogo: { type: "string" },
        tcpaDisclosure: { type: "string" },
        primaryColor: { type: "string" },
        secondaryColor: { type: "string" },
        lpEmailSender: { type: "string" },
        lpAreaCodes: { type: "string" },
        lpContinueUrl: { type: "string" },
        responseTime: { type: "string" },
        implicitTcpa: { type: "boolean" },
        complyedClientId: { type: "number" },
        aor: { type: "boolean" },
        contactName: { type: "string" },
        contactEmail: { type: "string" },
        contactAddress: { type: "string" },
        contactCity: { type: "string" },
        contactState: { type: "string" },
        contactZip: { type: "string" },
        contactPhone: { type: "string" },
        brandGuidelinesDocument: { type: "string" },
        brandGuidelinesDocumentFilename: { type: "string" },
        timeZone: { type: "string" },
        createdAt: { type: "datetime" },
        notes: { type: 'string' },
        vanityLeadsHostname: { type: 'string' },
        vanityLeadsPath: { type: 'string' },
      },
      relationships: {
        degreePrograms: { kind: 'hasMany', type: 'degreeProgram', inverse: 'client' },
        exclusionRules: { kind: 'hasMany', type: 'exclusionRule', inverse: 'appliesTo' },
        programGroups: { kind: 'hasMany', type: 'programGroup', inverse: 'client' },
        dailyCounts: { kind: 'hasMany', type: 'dailyCount', inverse: 'client' },
        stateStats: { kind: 'hasMany', type: 'stateStat', inverse: 'client' },
        contracts: { kind: 'hasMany', type: 'contract', inverse: 'client' },
        adCampaignSets: { kind: 'hasMany', type: 'adCampaignSet', inverse: 'client' },
        allocations: { kind: 'hasMany', type: 'allocation' },
        clientCampaigns: { kind: 'hasMany', type: 'clientCampaign' },
        fields: { kind: 'hasMany', type: 'field', inverse: 'client' },
        campuses: { kind: 'hasMany', type: 'campus', inverse: 'client' },
        leadsEndpoint: { kind: 'hasOne', type: 'endpoint' },
      },
    },
    endpoint: {
      attributes: {
        hostname: { type: 'string' },
        path: { type: 'string' },
        parameterNames: { type: 'object' },
      },
    },
    vendor: {
      attributes: {
        name: { type: 'string' },
        contactName: { type: 'string' },
        contactEmail: { type: 'string' },
        streetAddress: { type: 'string' },
        city: { type: 'string' },
        state: { type: 'string' },
        zipCode: { type: 'string' },
        phone: { type: 'string' },
      },
      keys: {
        remoteId: {},
      },
      relationships: {
        leads: { kind: 'hasMany', type: 'lead', inverse: 'vendor' },
        team: { kind: 'hasOne', type: 'team' },
        contracts: { kind: 'hasMany', type: 'contract', inverse: 'vendor' },
      },
    },
    dailyCap: {
      attributes: {
        year: { type: 'number' },
        month: { type: 'number' },
        day: { type: 'number' },
        limit: { type: 'number' },
      },
      relationships: {
        clientCampaignGroup: { kind: 'hasOne', type: 'clientCampaignGroup', inverse: 'dailyCaps' },
      },
    },
    // TODO: eliminate dailyCount pseudo-model in favor of dailyLeadCount, although we may still need dailyCount (or perhaps a different model) to handle projections
    // Also, note that the data returned by the pacing endpoint (used by CurrentMonthPacingResource) is a subset of the data returned by the daily-counts endpoint
    dailyCount: {
      attributes: {
        date: { type: 'string' },
        count: { type: 'number' },
        projection: { type: 'string' },
      },
      relationships: {
        clientCampaign: { kind: 'hasOne', type: 'clientCampaign' },
        programGroup: { kind: 'hasOne', type: 'programGroup' },
        vendor: { kind: 'hasOne', type: 'vendor' },
        client: { kind: 'hasOne', type: 'client', inverse: 'dailyCounts' },
      },
    },
    stateStat: {
      attributes: {
        state: { type: 'string' },
        status: { type: 'string' },
        count: { type: 'number' },
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'stateStats' },
      },
    },
    programGroup: {
      attributes: {
        description: { type: 'string' },
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'programGroups' },
        degreePrograms: { kind: 'hasMany', type: 'degreeProgram', inverse: 'programGroup' },
        clientCampaigns: { kind: 'hasMany', type: 'clientCampaign', inverse: 'programGroup' },
      }
    },
    degreeProgram: {
      attributes: {
        clientId: { type: 'number' },
        name: { type: 'string' },
        exclusionUrl: { type: 'string' },
        programCode: { type: 'string' },
        status: { type: 'string' },
        lpContinueUrl: { type: "string" },
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'degreePrograms' },
        steps: { kind: 'hasMany', type: 'step', inverse: 'questionnaire' },
        exclusionRules: { kind: 'hasMany', type: 'exclusionRule', inverse: 'appliesTo' },
        programGroup: { kind: 'hasOne', type: 'programGroup', inverse: 'degreePrograms' },
      }
    },
    step: {
      attributes: {
        position: { type: 'number' },
        title: { type: 'string' },
      },
      relationships: {
        questionnaire: { kind: 'hasOne', type: ['degreeProgram'], inverse: 'steps' },
        questions: { kind: 'hasMany', type: 'question', inverse: 'step' },
      },
    },
    question: {
      attributes: {
        position: { type: 'number' },
        key: { type: 'string' },
        text: { type: 'string' },
        options: { type: 'object' },
        dependentOnOperator: { type: 'string' },
        dependentOnValue: { type: 'object' },
      },
      relationships: {
        step: { kind: 'hasOne', type: 'step', inverse: 'questions' },
        dependentOnQuestion: { kind: 'hasOne', type: 'question', inverse: 'dependents' },
        dependents: { kind: 'hasOne', type: 'question', inverse: 'dependentOnQuestion' },
      },
    },
    exclusionRule: {
      attributes: {
        operator: { type: 'string' },
        value: { type: 'object' },
        position: { type: 'number' },
      },
      relationships: {
        appliesTo: { kind: 'hasOne', type: ['degreeProgram', 'client'], inverse: 'exclusionRules' },
        field: { kind: 'hasOne', type: 'field' },
      },
    },
    lead: {
      attributes: {
        externalId: { type: 'string' },
        firstName: { type: 'string' },
        lastName: { type: 'string' },
        email: { type: 'string' },
        phone: { type: 'string' },
        streetAddress: { type: 'string' },
        city: { type: 'string' },
        state: { type: 'string' },
        zipCode: { type: 'string' },
        status: { type: 'string' },
        disposition: { type: 'string' },
        statusChangedAt: { type: 'datetime' },
        billable: { type: 'boolean' },
        leadAmountCents: { type: 'number' },
        rejectionReason: { type: 'string' },
        rawParams: { type: 'object' },
        createdAt: { type: 'datetime' },
        updatedAt: { type: 'datetime' },
        subid: { type: 'string' },
        source: { type: 'string' },
        remoteIp: { type: 'string' },
        secondaryTcpa: { type: 'boolean' },
        trustedFormCertUrl: { type: 'string' },
        trustedFormCertClaimed: { type: 'boolean' },
        filteredReason: { type: 'object' },
        userAgent: { type: 'string' },
        trackingParams: { type: 'object' },
        clientId: { type: 'number' }, // TODO: make this a relationship
        vendorId: { type: 'number' }, // TODO: make this a relationship
        degreeProgramId: { type: 'number' }, // TODO: make this a relationship
        test: { type: 'boolean' },
        landingPageId: { type: 'string' },
        campaignType: { type: 'string' },
        complyedCreativeId: { type: 'number' },
        jornayaLeadidToken: { type: 'string' },
        publicId: { type: 'string' },
      },
      relationships: {
        vendor: { kind: 'hasOne', type: 'vendor', inverse: 'leads' },
      },
    },
    user: {
      attributes: {
        email: { type: 'string' },
        password: { type: 'string' },
        name: { type: 'string' },
        teamAdmin: { type: 'boolean' },
      },
      relationships: {
        team: { kind: 'hasOne', type: 'team', inverse: 'users' },
      },
    },
    team: {
      attributes: {
        name: { type: 'string' },
        siteAdmin: { type: 'boolean' },
      },
      relationships: {
        users: { kind: 'hasMany', type: 'user', inverse: 'team' },
        client: { kind: 'hasOne', type: 'client' },
        vendor: { kind: 'hasOne', type: 'vendor' },
      },
    },
    complyedClient: {
      attributes: {
        name: { type: 'string' },
      },
      relationships: {
        complyedCreatives: { kind: 'hasMany', type: 'complyedCreative', inverse: 'complyedClient' },
      }
    },
    complyedCreative: {
      attributes: {
        name: { type: 'string' },
      },
      relationships: {
        complyedClient: { kind: 'hasOne', type: 'complyedClient', inverse: 'complyedCreatives' },
      }
    },
    contract: {
      attributes: {
        vendorCode: { type: 'string' },
        payout: { type: 'string' },
        canPostLeads: { type: 'boolean' },
      },
      keys: {
        remoteId: {},
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'contracts' },
        vendor: { kind: 'hasOne', type: 'vendor', inverse: 'contracts' },
        clientCampaigns: { kind: 'hasMany', type: 'clientCampaign', inverse: 'contract' },
        allocations: { kind: 'hasMany', type: 'allocation' },
        clientCampaignGroups: { kind: 'hasMany', type: 'clientCampaignGroup', inverse: 'contract' },
        dailyCaps: { kind: 'hasMany', type: 'dailyCap' },
        dailyCounts: { kind: 'hasMany', type: 'dailyCount' },
        degreePrograms: { kind: 'hasMany', type: 'degreeProgram' },
        leads: { kind: 'hasMany', type: 'lead' },
        programGroups: { kind: 'hasMany', type: 'programGroup' },
        stateStats: { kind: 'hasMany', type: 'stateStat' },
        fields: { kind: 'hasMany', type: 'field' }, // through client
        adCampaigns: { kind: 'hasMany', type: 'adCampaign' },
        adSets: { kind: 'hasMany', type: 'adSet' },
      },
    },
    marketingPlatform: {
      attributes: {
        typeName: { type: 'string' },
        name: { type: 'string' },
      },
      relationships: {
        adAccounts: { kind: 'hasMany', type: 'adAccount', inverse: 'marketingPlatform' },
      },
    },
    adAccount: {
      attributes: {
        name: { type: 'string' },
        externalId: { type: 'string' },
      },
      relationships: {
        marketingPlatform: { kind: 'hasOne', type: 'marketingPlatform', inverse: 'adAccounts' },
        adCampaigns: { kind: 'hasMany', type: 'adCampaign', inverse: 'adAccount' },
        adCampaignSets: { kind: 'hasMany', type: 'adCampaignSet', inverse: 'adAccount' },
      },
    },
    adCampaign: {
      attributes: {
        name: { type: 'string' },
        externalId: { type: 'string' },
      },
      relationships: {
        adAccount: { kind: 'hasOne', type: 'adAccount', inverse: 'adCampaigns' },
        adCampaignSet: { kind: 'hasOne', type: 'adCampaignSet', inverse: 'adCampaigns' },
        adSets: { kind: 'hasMany', type: 'adSet', inverse: 'adCampaign' },
      },
    },
    adSet: {
      attributes: {
        name: { type: 'string' },
        externalId: { type: 'string' },
      },
      relationships: {
        adCampaign: { kind: 'hasOne', type: 'adCampaign', inverse: 'adSets' },
      },
    },
    adCampaignSet: {
      attributes: {
        adCampaignIds: { type: 'object' },
      },
      keys: {
        remoteId: {},
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'adCampaignSets' },
        adAccount: { kind: 'hasOne', type: 'adAccount', inverse: 'adCampaignSets' },
        adCampaigns: { kind: 'hasMany', type: 'adCampaign', inverse: 'adCampaignSet' },
      },
    },
    clientCampaign: {
      attributes: {
        name: { type: 'string' },
        campaignType: { type: 'string' },
        status: { type: 'string' },
        goodLeadCount: { type: 'number' },
        billableLeadCount: { type: 'number' },
        totalCostInCents: { type: 'number' },
        publicId: { type: 'string' }, // TODO: change all controllers & serializers to use public id as "id" field
        smartScore: { type: 'string' },
        smartScoreExplanation: { type: 'string' },
      },
      keys: {
        remoteId: {},
      },
      relationships: {
        programGroup: { kind: 'hasOne', type: 'programGroup', inverse: 'clientCampaigns' },
        allocations: { kind: 'hasMany', type: 'allocation', inverse: 'clientCampaign' },
        contract: { kind: 'hasOne', type: 'contract', inverse: 'clientCampaigns' },
      },
    },
    allocation: {
      attributes: {
        year: { type: 'number' },
        month: { type: 'number' },
        cplInCents: { type: 'number' },
        cap: { type: 'number' },
        vendorProjection: { type: 'number' },
      },
      keys: {
        remoteId: {},
      },
      relationships: {
        clientCampaign: { kind: 'hasOne', type: 'clientCampaign', inverse: 'allocations' },
      },
    },
    clientCampaignGroup: {
      attributes: {
        name: { type: 'string' },
        clientCampaignIds: { type: 'object' },
        campaignType: { type: 'string' },
      },
      keys: {
        remoteId: {}
      },
      relationships: {
        contract: { kind: 'hasOne', type: 'contract', inverse: 'clientCampaignGroups' },
        dailyCaps: { kind: 'hasMany', type: 'dailyCap', inverse: 'clientCampaignGroup' },
      }
    },
    field: {
      attributes: {
        fieldType: { type: 'string' },
        name: { type: 'string' },
        description: { type: 'string' },
        options: { type: 'object' },
        required: { type: 'boolean' },
      },
      keys: {
        remoteId: {}
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'fields' }
      },
    },
    // TODO: do we actually use orbit for landing pages anywhere?
    landingPage: {
      attributes: {
        name: { type: 'string' },
        publicId: { type: 'string' },
        path: { type: "string" },
        hostname: { type: "string" },
        shortName: { type: 'string' },
        blurb: { type: 'string' },
        video: { type: 'string' },
        cta: { type: 'string' },
        cta2: { type: 'string' },
        footerText: { type: 'string' },
        hasSecondCta: { type: 'boolean' },
        secondCtaHeader: { type: 'string' },
        secondCtaLeft: { type: 'string' },
        secondCtaRight: { type: 'string' },
        secondCta: { type: 'string' },
        secondCta2: { type: 'string' },
        hasAccredInfo: { type: 'boolean' },
        accredInfo: { type: 'string' },
        accredLogo: { type: 'string' },
        hasTestimonial: { type: 'boolean' },
        testimonial: { type: 'string' },
        testimonialName: { type: 'string' },
        testimonialFootnote: { type: 'string' },
        testimonialFooter: { type: 'string' },
        modalHeading: { type: "string" },
        modalSubheading: { type: "string" },
      },
      keys: {
        remoteId: {}
      },
      relationships: {
        primaryClient: { kind: 'hasOne', type: 'client' },
        template: { kind: 'hasOne', type: 'template' },
      },
    },
    template: {
      attributes: {
        name: { type: 'string' },
        multiClient: { type: 'boolean' },
      },
    },
    campus: {
      attributes: {
        name: { type: 'string' },
        displayName: { type: 'string' },
      },
      keys: {
        remoteId: {}
      },
      relationships: {
        client: { kind: 'hasOne', type: 'client', inverse: 'campuses' }
      },
    },
  }
});

const pluralizations = {
  campus: 'campuses',
}

const pluralize = buildInflector(pluralizations, s => `${s}s`)
const singularize = buildInflector(invert(pluralizations), s => s.replace(/s$/, ''))

/*
Object.defineProperty(TaskQueue.prototype, 'push', {
  value: async function (task) {
    this._pendingPush = (this._pendingPush || 0) + 1
    await this._reified;
    this._pendingPush--
    const processor = new TaskProcessor(this._performer, task);
    this._tasks.push(task);
    this._processors.push(processor);
    await this._persist();
    return this._settle(processor);
  }
})

Object.defineProperty(TaskQueue.prototype, 'empty', {
  get: function () {
    return !this._pendingPush && this.length === 0;
  }
})
*/

// Overrides the query() method to allow multiple query requests to run in parallel
// (by operating a separate TaskQueue for each query)
class ParallelJSONAPISource extends JSONAPISource {
  _requestQueues: TaskQueue[]

  async query(
    queryOrExpressions: QueryOrExpressions<QueryExpression, unknown>,
    options?: RequestOptions,
    id?: string
  ): Promise<QueryRequestProcessorResponse> {
    await this.activated;
    const query = buildQuery(
      queryOrExpressions,
      options,
      id,
      this.queryBuilder
    );
    const response = await this.pushToAvailableOrFreshRequestQueue({
      type: 'query',
      data: query
    });
    return options?.fullResponse ? (response as QueryRequestProcessorResponse) : ((response as ResourceDocument).data as QueryRequestProcessorResponse);
  }

  async pushToAvailableOrFreshRequestQueue(task: Task) {
    let requestQueue : TaskQueue

    if (!this._requestQueues) {
      this._requestQueues = [this._requestQueue]
    }
    for (let i = 0; i < this._requestQueues.length; ++i) {
      if (this._requestQueues[i].empty) {
        requestQueue = this._requestQueues[i]
        break
      }
    }
    // Create a new request queue
    // TODO: limit the number of simultaneous task queues
    requestQueue = new TaskQueue(this, {
      name: this._name ? `${this._name}-requests-${this._requestQueues.length + 1}` : undefined,
      bucket: this._bucket as Bucket<Task[]>,
      autoActivate: true,
    });
    this._requestQueues.push(requestQueue)

    return await requestQueue.push(task)
  }
}

const apiPath = (document.querySelector('meta[name="api-path"]') as HTMLMetaElement)?.content || '/api/v1'

const remote = new ParallelJSONAPISource({
  schema: schema,
  autoValidate: false,
  keyMap: new RecordKeyMap(),
  name: "remote",
  host: apiPath,
  serializerSettingsFor: buildSerializerSettingsFor({
    settingsByType: {
      [JSONAPISerializers.ResourceType]: {
        serializationOptions: { inflectors: ['pluralize', 'dasherize'] },
        inflectors: { pluralize },
        inverseInflectors: { pluralize: singularize },
      },
      [JSONAPISerializers.ResourceTypePath]: {
        serializationOptions: { inflectors: ['pluralize', 'dasherize'] },
        inflectors: { pluralize },
        inverseInflectors: { pluralize: singularize },
      },
      [JSONAPISerializers.ResourceField]: {
        serializationOptions: { inflectors: ['dasherize'] }
      }
    }
  }),
  serializerClassFor: buildSerializerClassFor({
    object: StringSerializer
  }),
  defaultFetchSettings: {
    timeout: 30000,
  },
})

remote.requestQueue.on('fail', (request, error) => {
  airbrake.notify({ error, request, message: "Orbit error: remote requestQueue failed" })
  remote.requestQueue.skip(error)
})

const store = new MemorySource({
  schema: schema,
  autoValidate: false
})

const coordinator = new Coordinator()
coordinator.addSource(store)
coordinator.addSource(remote)


// Query the remote server whenever the store is queried
coordinator.addStrategy(new RequestStrategy({
  source: 'memory',
  on: 'beforeQuery',
  target: 'remote',
  action: 'pull', // could alternatively be `query`
  blocking: true  // pessimistic mode
}));

// Sync all changes received from the remote server to the store
coordinator.addStrategy(new SyncStrategy({
  source: 'remote',
  target: 'memory',
  blocking: true // pessimistic mode
}));

store.syncQueue.on('fail', (request, error) => {
  airbrake.notify({ error, request, message: "Orbit error: store syncQueue failed" })
  store.syncQueue.skip(error)
})

coordinator.activate();


const getRemoteId = (resource: InitializedRecord) => {
  if(!resource) {
    return null
  }

  // TODO: Eliminate this clause once every model has been changed to have a remoteId key
  if (!schema.getModel(resource.type).keys) {
    return String(resource.id).match(/^[0-9]+$/) ? resource.id : undefined;
  }

  return remote.requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity).serialize(resource).id
}

const isNew = (resource : InitializedRecord) => !getRemoteId(resource)

const getRecordIdentity = (type: string, remoteId: string) => {
  const serializer = remote.requestProcessor.serializerFor(JSONAPISerializers.ResourceIdentity) as JSONAPIResourceIdentitySerializer
  return serializer.deserialize({ type: type, id: remoteId })
}

function newRecord(data : UninitializedRecord) : InitializedRecord {
  return remote.transformBuilder.$normalizeRecord(data)
}

export { saveWithPayload, remote, store, coordinator, schema, getRemoteId, isNew, getRecordIdentity, newRecord }
