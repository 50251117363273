import { ReactNode, Suspense } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'

import CircularProgress from '@mui/material/CircularProgress'
import { ErrorBoundary } from 'react-error-boundary'
import { useNotifications } from 'lib/NotificationsProvider';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
})

type SuspensefulProps<ChildProps> = {
  component: React.ComponentType<ChildProps>
  fallback?: ReactNode
  errorMessage?: string
  loaderClassName?: string | null,
}

export default function Suspenseful<ChildProps>({
  component,
  fallback = null,
  errorMessage = "Error loading resource",
  loaderClassName = null,
  ...props
}: SuspensefulProps<ChildProps> & ChildProps) {
  const classes = useStyles()
  const { addNotification } = useNotifications()
  const ChildComponent = component as React.ComponentType<Omit<ChildProps, keyof SuspensefulProps<ChildProps>>> // It would be better if we could restrict ChildProps to disallow keys of SuspensefulProps, but that doesn't seem possible

  const ErrorFallback = (props) => (
    <Box sx={{ m: 4 }}>
       <Box>Unexpected error</Box>
       <Box>{props.error.toString()}</Box>
    </Box>
 )

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={() => addNotification({variant: 'alert', message: errorMessage})}
    >
      <Suspense fallback={fallback || (
        <div className={clsx(classes.loader, loaderClassName)}>
          <CircularProgress/>
        </div>
      )}>
        <ChildComponent {...props}/>
      </Suspense>
    </ErrorBoundary>
  )
}
