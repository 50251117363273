import { useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import CheckIcon from '@mui/icons-material/Check'

import { useContract } from '../../../lib/ContractProvider'
import { sortBy, stripHTML } from '../../../lib/utils'
import ClientCampaignsResource from '../../../resources/ClientCampaignsResource'
import DegreeProgramsResource from '../../../resources/DegreeProgramsResource'
import FieldsResource from '../../../resources/FieldsResource'
import Suspenseful from '../../Suspenseful'
import { getIn } from '../../Formik/forms'
import { STATES } from '../../../lib/states'
import { store } from 'lib/DataModel'
import MainContainer from 'components/layout/MainContainer/MainContainer'
import { useHostname } from 'lib/HostnameProvider'

const useStyles = makeStyles(_theme => ({
  root: {
    '& code': {
      backgroundColor: '#eee',
      border: '1px solid #ddd',
      padding: '2px 0',
    },
    '& td': {
      verticalAlign: 'top',
    },
  },
  pre: {
    backgroundColor: '#eee',
    border: '1px solid #ddd',
    padding: '4px 8px',
  },
}))

const DEFAULT_DESCRIPTION = {
  first_name:     'First name',
  last_name:      'Last name',
  street_address: 'Street address',
  city:           'City',
  state:          'State',
  zip_code:       'ZIP code',
  email:          'Email',
  phone:          'Phone number (10 digits)',
}

function fieldRank(field) {
  const RANKINGS = [
  'first_name',
  'last_name',
  'phone',
  'email',
  'street_address',
  'city',
  'state',
  'zip_code',
  ]

  const index = RANKINGS.indexOf(getIn(field, 'attributes.fieldType'))
  return index < 0 ? RANKINGS.length : index
}

function getFields({ clientCampaigns, fields, degreePrograms, isPartnerLeads, parameterNames }) {
  const campaignIdOptions = sortBy(clientCampaigns, 'id').map(clientCampaign => ({value: getIn(clientCampaign, 'attributes.publicId'), text: getIn(clientCampaign, 'attributes.name')}))
  const programCodeOptions = degreePrograms.map(degreeProgram => ({value: getIn(degreeProgram, 'attributes.programCode'), text: stripHTML(getIn(degreeProgram, 'attributes.name'))}))
  const sortedFields = sortBy(fields, field => fieldRank(field) + (getIn(field, 'attributes.required') ? 100 : 200))

  const getParameterName = key => parameterNames[key] || key

  if(isPartnerLeads) {
    return [
      { attributes: { name: getParameterName('campaign_id'),  required: true, description: 'Campaign ID',  options: campaignIdOptions } },
      { attributes: { name: getParameterName('program_code'), required: true, description: 'Program Code', options: programCodeOptions } },
      { attributes: { name: getParameterName('source'), required: true, description: 'Broad traffic source', options: [
        { value: 'facebook', text: 'Facebook Ads' },
        { value: 'snapchat', text: 'Snapchat Ads' },
        { value: 'google', text: 'Google Ads' },
        { value: 'bing', text: 'Bing Ads / Microsoft Ads' },
        { value: 'tiktok', text: 'TikTok Ads' },
        { value: 'linkedin', text: 'LinkedIn Ads' },
        { value: 'email', text: 'Email marketing campaigns' },
        { value: 'owned', text: 'Affiliate-owned and operated sites' },
        { value: 'unknown', text: 'Source cannot be determined' },
      ]} },
      ...sortedFields,
      { attributes: { name: getParameterName('ipAddress'), required: false, description: 'IP address from which the lead submitted their information' } },
      { attributes: { name: getParameterName('userAgent'), required: false, description: 'User agent with which the lead submitted their information' } },
      { attributes: { name: getParameterName('subid'), required: false, description: 'Affiliate-specific identifier of traffic source (e.g. creative id)' } },
      { attributes: { name: getParameterName('test'), required: false, description: 'Set to indicate a test lead', options: [{value: 'true', text: 'Test lead'}, {value: 'false', text: 'Real lead (default)'}] } },
      { attributes: { name: getParameterName('jornaya_leadid_token'), required: false, description: 'Jornaya LeadiD token generated by Jornaya campaign javascript when a user views a landing page' } },
    ]
  } else {
    return [
      { attributes: { name: 'campaign_id',  required: true, description: 'Campaign ID',  options: campaignIdOptions } },
      { attributes: { name: 'program_code', required: true, description: 'Program Code', options: programCodeOptions } },
      ...sortedFields,
    ]
  }
}

function FieldOptions({field}) {
  const options = useMemo(() => (field.attributes.fieldType === 'state' ? STATES.map(({name, abbreviation}) => ({text: name, value: abbreviation})) : field.attributes.options) || [], [field])

  return (
    <div>
      {options.map(({value, text}, index) => (
        <div key={index}>
          <code>{value}</code> {text && `(${text})`}
        </div>
      ))}
    </div>
  )
}

function Field({field}) {
  return (
    <TableRow>
      <TableCell><code>{field.attributes.name}</code></TableCell>
      <TableCell>{field.attributes.required && <CheckIcon/>}</TableCell>
      <TableCell>{field.attributes.description || DEFAULT_DESCRIPTION[field.attributes.fieldType]}</TableCell>
      <TableCell>
        <FieldOptions field={field}/>
      </TableCell>
    </TableRow>
  )
}

function Integrations({ clientCampaignsResource, contract, fieldsResource, degreeProgramsResource }) {
  const classes = useStyles()
  const client = useMemo(() => store.cache.query(q => q.findRelatedRecord(contract, 'client')), [contract])
  const leadsEndpoint = useMemo(() => store.cache.query(q => q.findRelatedRecord(client, 'leadsEndpoint')), [client])
  const hasEndpoint = !!leadsEndpoint
  const { defaultLeadsHostname } = useHostname()
  const fields = useMemo(() => getFields({
    clientCampaigns: clientCampaignsResource.get(),
    fields: fieldsResource.get(),
    degreePrograms: degreeProgramsResource.get(),
    isPartnerLeads: hasEndpoint,
    parameterNames: getIn(leadsEndpoint, 'attributes.parameterNames'),
  }), [clientCampaignsResource, fieldsResource, degreeProgramsResource, hasEndpoint, leadsEndpoint])

  const leadsHostname = getIn(leadsEndpoint, 'attributes.hostname') || defaultLeadsHostname
  const leadsPath = getIn(leadsEndpoint, 'attributes.path') || '/leads'
  const url = `https://${leadsHostname}${leadsPath}`

  return (
    <MainContainer>
      <div className={classes.root}>
        <h1>Form posting instructions</h1>
        <p>
          Send your leads via HTTP POST to:
        </p>

        <pre className={classes.pre}>
          {url}
        </pre>

        {hasEndpoint ? (
          <>
            <p>
              The request body must be JSON, with the following headers:
            </p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Header</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><code>Content-type</code></TableCell>
                  <TableCell><code>application/json</code></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><code>X-Api-Key</code></TableCell>
                  <TableCell>Your API key</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <p>
              The request body must be JSON (with content-type <code>application/json</code>) for server-to-server submissions, or form data (with content-type <code>application/x-www-form-urlencoded</code>) for submissions coming directly from the browser.
            </p>
          </>
        )}

        <h2>Request parameters</h2>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Required?</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <Field field={field} key={index}/>
            ))}
          </TableBody>
        </Table>
      </div>
    </MainContainer>
  )
}

export default function IntegrationsWrapper() {
  const { contract } = useContract()
  const fieldsResource = useMemo(() => contract && new FieldsResource(contract), [contract])
  const clientCampaignsResource = useMemo(() => contract && new ClientCampaignsResource({contractOrClient: contract}), [contract])
  const degreeProgramsResource = useMemo(() => contract && new DegreeProgramsResource(contract), [contract])

  return fieldsResource && (
    <Suspenseful
      component={Integrations}
      contract={contract}
      clientCampaignsResource={clientCampaignsResource}
      degreeProgramsResource={degreeProgramsResource}
      fieldsResource={fieldsResource}
    />
  ) || null
}
