import moment from '../lib/moment'
import { getRecordIdentity, getRemoteId, remote, store } from '../lib/DataModel'
import { getIn } from '../components/Formik/forms'
import { buildQueryString, failOnHttpError, groupBy, notNullish, sum } from '../lib/utils'
import { camelize } from '@orbit/serializers'
import { Record as OrbitRecord } from '@orbit/records'

import PromisedResource from './PromisedResource'

interface PacingDataRow {
  clientCampaignId: number | string
  date: string
  mtdProjectedCount: number
  goodLeadCount: number
}

function getPacingData({ clientId, contract, isAdmin, timezone }): Promise<PacingDataRow[]> {
  const baseUrl = clientId ? `/api/v1/clients/${clientId}/pacing` : contract ? `/api/v1/vendor/contracts/${getRemoteId(contract)}/pacing` : isAdmin ? `/api/v1/pacing` : `/api/v1/client/pacing`
  const queryString = buildQueryString({ timezone })
  const url = queryString ? `${baseUrl}?${queryString}` : baseUrl

  return fetch(url)
    .then(failOnHttpError)
    .then(response => response.json())
    .then(response =>
      response.data.pacing.map(row => Object.fromEntries(Object.entries(row).map(([key, value]) => [camelize(key), value])))
    )
}

const getCurrentAllocations = (clientOrContract: OrbitRecord) => (
  remote.query(q =>
    (clientOrContract ? q.findRelatedRecords(clientOrContract, 'allocations') : q.findRecords('allocation'))
    .filter(
      {attribute: 'year', value: moment().year()},
      {attribute: 'month', value: moment().month()+1},
    )
  )
)

const getClientCampaigns = (clientOrContract: OrbitRecord) => (
  remote.query(q =>
    (clientOrContract ? q.findRelatedRecords(clientOrContract, 'clientCampaigns') : q.findRecords('clientCampaign')), {
      sources: {
        remote: {
          include: ['contract','contract.vendor', 'program_group']
        }
      }
    }
  )
)

function extractEntities(row) {
  const clientCampaign = store.cache.query(q => q.findRecord(getRecordIdentity('clientCampaign', String(row.clientCampaignId)))) as OrbitRecord | undefined
  if(!clientCampaign) {
    return null
  }
  const contract = store.cache.query(q => q.findRelatedRecord(clientCampaign, 'contract')) as OrbitRecord | undefined
  if(!contract) {
    return null
  }
  const vendor = store.cache.query(q => q.findRelatedRecord(contract, 'vendor'))

  return { row, clientCampaign, contract, vendor }
}

const getPromise = ({ clientId, contract, isAdmin, timezone }) => {
  const clientOrContract = clientId ? { type: 'client', id: clientId } : contract

  return Promise.all([getPacingData({ clientId, contract, isAdmin, timezone }), getCurrentAllocations(clientOrContract), getClientCampaigns(clientOrContract)])
    .then(([pacingData, allocations, _clientCampaigns]) => {
      const clientCampaignToRow = Object.fromEntries(
        pacingData
          .map(row => extractEntities(row))
          .filter(notNullish)
          .map(({ row, clientCampaign, contract, vendor }) => [
            row.clientCampaignId, {
              ...row,
              clientCampaignId: String(row.clientCampaignId),
              vendorId: getRemoteId(vendor),
              contractId: getRemoteId(contract),
              clientId: getIn(contract, 'relationships.client.data.id'),
              programGroupId: getIn(clientCampaign, 'relationships.programGroup.data.id'),
              campaignType: getIn(clientCampaign, 'attributes.campaignType'),
              name: getIn(clientCampaign, 'attributes.name'),
              monthlyCap: null
            }
          ])
      )
      groupBy(allocations, allocation => `${getRemoteId(allocation.relationships.clientCampaign.data)}`, {asEntries: true}).forEach(([keys, groupAllocations]) => {
        const [clientCampaignId] = keys.split('/')
        let row
        if(clientCampaignToRow[keys]) {
          row = clientCampaignToRow[keys]
        } else {
          const clientCampaign = store.cache.query(q => q.findRecord(getRecordIdentity('clientCampaign', clientCampaignId)))
          const contract = store.cache.query(q => q.findRelatedRecord(clientCampaign, 'contract'))
          const vendor = store.cache.query(q => q.findRelatedRecord(contract, 'vendor'))

          row = clientCampaignToRow[keys] = {
            clientCampaignId,
            acceptedLeadCount: 0,
            vendorId: getRemoteId(vendor),
            contractId: getRemoteId(contract),
            clientId: getIn(contract, 'relationships.client.data.id'),
            programGroupId: getIn(clientCampaign, 'relationships.programGroup.data.id'),
            campaignType: getIn(clientCampaign, 'attributes.campaignType'),
            name: getIn(clientCampaign, 'attributes.name'),
          }
        }
        row.monthlyCap = groupAllocations.some(allocation => !getIn(allocation, 'attributes.cap') && getIn(allocation, 'attributes.cap') !== 0) ? null : sum(groupAllocations, 'attributes.cap')
      })
      return Object.values(clientCampaignToRow)
    })
}

export default class CurrentMonthPacingResource extends PromisedResource {
  constructor({ clientId = null, contract = null, isAdmin = false, timezone = null } = {}) {
    super(getPromise({ clientId, contract, isAdmin, timezone }))
  }
}
