import { http, HttpResponse } from "msw";
import Container from "@mui/material/Container";
import Allocations from "./";
import ContractProvider from "../../../lib/ContractProvider";
import { reactRouterNestedAncestors, reactRouterParameters } from 'storybook-addon-remix-react-router'
import FeaturesProvider from "providers/FeaturesProvider";
import { remote } from "lib/DataModel";
import CurrentUserProvider from "lib/CurrentUserProvider";
import { JSONAPISerializers } from "@orbit/jsonapi"


export default {
  title: "Pages/Allocations",
  component: Allocations,
};

const Template = (args) => {
  const { contract, ...otherArgs } = args;
  const currentUser = remote.requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument).deserialize({data: { type: 'users', id: '1' }}).data
  const currentTeam = remote.requestProcessor.serializerFor(JSONAPISerializers.ResourceDocument).deserialize({data: { type: 'teams', id: '1', relationships: { client: { data: { type: 'client', id: '123' } }}}}).data

  return (
    <CurrentUserProvider currentUser={currentUser} currentTeam={currentTeam}>
      <ContractProvider initialContract={contract}>
        <Container maxWidth="lg">
          <FeaturesProvider features={{ smart_score: true }}>
            <Allocations {...otherArgs} />
          </FeaturesProvider>
        </Container>
      </ContractProvider>
    </CurrentUserProvider>
  );
};

export const Admin = Template.bind({});
Admin.parameters = {
  reactRouter: reactRouterParameters({
    location: {
      pathParams: { clientId: '123' },
    },
    routing: reactRouterNestedAncestors([
      {
        path: 'clients',
      },
      {
        path: ':clientId',
      },
      {
        path: 'allocations',
      }
    ]),
  }),
  msw: {
    handlers: [
      http.get("/api/v1/clients/123", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: {
            id: "123",
            type: "clients",
            attributes: {
              id: 134,
              name: "American InterContinental University",
              "created-at": "2020-11-05T16:35:35.962Z",
              "updated-at": "2021-10-05T13:29:17.510Z",
              "secondary-color": "#0065A4",
              "primary-color": "#474A59",
              "tcpa-disclosure":
              "By checking this box and providing my contact information, I am giving American InterContinental University, a member of the AIU system, consent to contact me by email or telephone, including by text message regarding educational services. I acknowledge these calls may be pre-recorded messages and/or generated using an automated dialing system. I understand that I am under no obligation to provide consent to purchase any goods or services. Msg and data rates may apply",
              "internal-name": "American InterContinental University",
              "lp-email-sender": null,
              "lp-area-codes": null,
              "lp-continue-url": null,
              "response-time": "five_minutes",
              "implicit-tcpa": false,
              "google-conversion-id": null,
              "complyed-client-id": "102",
              "campus-explorer-college-id": null,
              "num-programs": 3,
              aor: false,
              "contact-name": null,
              "contact-email": null,
              "contact-address": null,
              "contact-city": null,
              "contact-state": null,
              "contact-zip": null,
              "contact-phone": null,
              "time-zone": "America/New_York",
              notes: "test",
              logo: null,
              "compliance-logo": null,
              "brand-guidelines-document": null,
              "brand-guidelines-document-filename": null,
            },
          },
        });
      }),
      http.get("/api/v1/contracts", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "45",
            type: "contracts",
            relationships: {
              client: { data: { id: "123", type: "clients" } },
              vendor: { data: { id: "1", type: "vendors" } },
            },
          },
          ],
          included: [
          {
            id: "1",
            type: "vendors",
            attributes: {
              id: 1,
              name: "Cygnus Education",
              "created-at": "2021-01-25T01:26:50.313Z",
              "updated-at": "2021-01-25T01:26:50.313Z",
              "contact-name": null,
              "contact-email": null,
              "street-address": null,
              city: null,
              state: null,
              "zip-code": null,
              phone: null,
              "external-config": {},
            },
            relationships: { team: { data: { id: "1", type: "teams" } } },
          },
          ],
        });
      }),
      http.get("/api/v1/clients/123/contracts", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "45",
            type: "contracts",
            relationships: {
              client: { data: { id: "123", type: "clients" } },
              vendor: { data: { id: "1", type: "vendors" } },
            },
          },
          ],
          included: [
          {
            id: "1",
            type: "vendors",
            attributes: {
              id: 1,
              name: "Cygnus Education",
              "created-at": "2021-01-25T01:26:50.313Z",
              "updated-at": "2021-01-25T01:26:50.313Z",
              "contact-name": null,
              "contact-email": null,
              "street-address": null,
              city: null,
              state: null,
              "zip-code": null,
              phone: null,
              "external-config": {},
            },
            relationships: { team: { data: { id: "1", type: "teams" } } },
          },
          ],
        });
      }),
      http.get("/api/v1/clients/123/program-groups", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "96",
            type: "program-groups",
            attributes: {
              id: 96,
              "client-id": 123,
              description: "Health",
            },
            relationships: {
              client: { data: { id: "123", type: "clients" } },
            },
          },
            {
              id: "97",
              type: "program-groups",
              attributes: {
                id: 97,
                "client-id": 123,
                description: "Education",
              },
              relationships: {
                client: { data: { id: "123", type: "clients" } },
              },
            },
            {
              id: "98",
              type: "program-groups",
              attributes: {
                id: 98,
                "client-id": 123,
                description: "Liberal Arts",
              },
              relationships: {
                client: { data: { id: "123", type: "clients" } },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/clients/123/client-campaigns", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Health",
              "campaign-type": "exclusive",
              status: "active",
              "good-lead-count": 808,
              "billable-lead-count": 808,
              "smart-score": "green",
              "public-id": "00003820",
              "total-cost-in-cents": 6970000,
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "96",
                  type: "program-groups",
                },
              },
            },
          },
            {
              id: "34",
              type: "client-campaigns",
              attributes: {
                name: "Two Line Campaign Title Example",
                "campaign-type": "shared",
                status: "active",
                "good-lead-count": 111,
                "billable-lead-count": 111,
                "smart-score": "yellow",
                "public-id": "29300280",
                "total-cost-in-cents": 569000,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "97",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Education",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 634,
                "billable-lead-count": 634,
                "smart-score": "red",
                "public-id": "00003820",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Liberal Arts",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 105,
                "billable-lead-count": 105,
                "smart-score": null,
                "public-id": "29300280",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "98",
                    type: "program-groups",
                  },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/client-campaigns", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Health",
              "campaign-type": "exclusive",
              status: "active",
              "good-lead-count": 808,
              "billable-lead-count": 808,
              "smart-score": "green",
              "public-id": "00003820",
              "total-cost-in-cents": 6970000,
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "96",
                  type: "program-groups",
                },
              },
            },
          },
            {
              id: "34",
              type: "client-campaigns",
              attributes: {
                name: "Two Line Campaign Title Example",
                "campaign-type": "shared",
                status: "active",
                "good-lead-count": 111,
                "billable-lead-count": 111,
                "smart-score": "yellow",
                "public-id": "29300280",
                "total-cost-in-cents": 569000,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "97",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Education",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 634,
                "billable-lead-count": 634,
                "smart-score": "red",
                "public-id": "00003820",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Liberal Arts",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 105,
                "billable-lead-count": 105,
                "smart-score": null,
                "public-id": "29300280",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "98",
                    type: "program-groups",
                  },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/45/client-campaigns", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Health",
              "campaign-type": "exclusive",
              status: "active",
              "good-lead-count": 808,
              "billable-lead-count": 808,
              "smart-score": "green",
              "public-id": "00003820",
              "total-cost-in-cents": 6970000,
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "96",
                  type: "program-groups",
                },
              },
            },
          },
            {
              id: "34",
              type: "client-campaigns",
              attributes: {
                name: "Two Line Campaign Title Example",
                "campaign-type": "shared",
                status: "active",
                "good-lead-count": 111,
                "billable-lead-count": 111,
                "smart-score": "yellow",
                "public-id": "29300280",
                "total-cost-in-cents": 569000,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "97",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Education",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 634,
                "billable-lead-count": 634,
                "smart-score": "red",
                "public-id": "00003820",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Liberal Arts",
                "campaign-type": "exclusive",
                status: "active",
                "good-lead-count": 105,
                "billable-lead-count": 105,
                "smart-score": null,
                "public-id": "29300280",
                "total-cost-in-cents": 0,
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "98",
                    type: "program-groups",
                  },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/45/allocations", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "439",
            type: "allocations",
            attributes: {
              id: 439,
              "client-campaign-id": 33,
              year: 2022,
              month: 2,
              cap: 3000,
              "cpl-in-cents": 8800,
              "vendor-projection": null,
              alerted: false,
              "created-at": "2022-02-03T00:30:01.493Z",
              "updated-at": "2022-02-03T00:30:01.493Z",
            },
            relationships: {
              "client-campaign": {
                data: { id: "33", type: "client-campaigns" },
              },
            },
          },
            {
              id: "440",
              type: "allocations",
              attributes: {
                id: 440,
                "client-campaign-id": 34,
                year: 2022,
                month: 2,
                cap: 250,
                "cpl-in-cents": 6000,
                "vendor-projection": null,
                alerted: false,
                "created-at": "2022-02-03T00:30:01.501Z",
                "updated-at": "2022-02-03T00:30:01.501Z",
              },
              relationships: {
                "client-campaign": {
                  data: { id: "34", type: "client-campaigns" },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/allocations*", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "439",
            type: "allocations",
            attributes: {
              id: 439,
              "client-campaign-id": 33,
              year: 2022,
              month: 2,
              cap: 7,
              "cpl-in-cents": 700,
              "vendor-projection": null,
              alerted: false,
              "created-at": "2022-02-03T00:30:01.493Z",
              "updated-at": "2022-02-03T00:30:01.493Z",
            },
            relationships: {
              "client-campaign": {
                data: { id: "33", type: "client-campaigns" },
              },
            },
          },
            {
              id: "440",
              type: "allocations",
              attributes: {
                id: 440,
                "client-campaign-id": 34,
                year: 2022,
                month: 2,
                cap: 7,
                "cpl-in-cents": 700,
                "vendor-projection": null,
                alerted: false,
                "created-at": "2022-02-03T00:30:01.501Z",
                "updated-at": "2022-02-03T00:30:01.501Z",
              },
              relationships: {
                "client-campaign": {
                  data: { id: "34", type: "client-campaigns" },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/contracts/45/daily-caps", (_request, _params, _cookies) => {
        return HttpResponse.json({ data: [] });
      }),
    ],
  },
};

export const Vendor = Template.bind({});
Vendor.args = {
  ...Admin.args,
  isVendor: true,
  contract: {
    type: "contract",
    id: "dbe2b437-d8fc-4a9a-ad24-01cf170eb6a5",
    keys: {
      remoteId: "45",
    },
    relationships: {
      client: {
        data: {
          type: "client",
          id: "123",
        },
      },
      vendor: {
        data: {
          type: "vendor",
          id: "b472d1af-b00a-45fc-a4c8-59f8f449c2b3",
        },
      },
    },
  },
};
Vendor.parameters = {
  ...Admin.parameters,
  reactRouter: {},
};

export const Client = Template.bind({});
Client.args = {
  ...Admin.args,
  isClient: true,
};
Client.parameters = {
  ...Admin.parameters,
  reactRouter: {},
};
