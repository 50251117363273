import React from "react";

import { http, HttpResponse } from "msw";
import AddClientCampaignModal from "./";

export default {
  title: "Components/AddClientCampaignModal",
  component: AddClientCampaignModal,
};

const Template = (args) => <AddClientCampaignModal {...args} />;

export const Default = Template.bind({});
Default.args = {
  open: true,
  contract: {
    type: "contract",
    id: "dbe2b437-d8fc-4a9a-ad24-01cf170eb6a5",
    keys: {
      remoteId: "45",
    },
    relationships: {
      client: {
        data: {
          type: "client",
          id: "123",
        },
      },
      vendor: {
        data: {
          type: "vendor",
          id: "b472d1af-b00a-45fc-a4c8-59f8f449c2b3",
        },
      },
    },
  },
};
Default.parameters = {
  msw: {
    handlers: [
      http.get("/api/v1/contracts/*client-campaigns*", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "33",
            type: "client-campaigns",
            attributes: {
              name: "Health",
              "campaign-type": "exclusive",
              "billable-lead-count": 808,
              "smart-score": "green",
              "public-id": "00003820",
            },
            relationships: {
              contract: {
                data: {
                  id: "45",
                  type: "contracts",
                },
              },
              "program-group": {
                data: {
                  id: "96",
                  type: "program-groups",
                },
              },
            },
          },
            {
              id: "34",
              type: "client-campaigns",
              attributes: {
                name: "Two Line Campaign Title Example",
                "campaign-type": "shared",
                "billable-lead-count": 111,
                "smart-score": "yellow",
                "public-id": "29300280",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "97",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "135",
              type: "client-campaigns",
              attributes: {
                name: "Education",
                "campaign-type": "exclusive",
                "billable-lead-count": 634,
                "smart-score": "red",
                "public-id": "00003820",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "96",
                    type: "program-groups",
                  },
                },
              },
            },
            {
              id: "136",
              type: "client-campaigns",
              attributes: {
                name: "Liberal Arts",
                "campaign-type": "exclusive",
                "billable-lead-count": 105,
                "smart-score": null,
                "public-id": "29300280",
              },
              relationships: {
                contract: {
                  data: {
                    id: "45",
                    type: "contracts",
                  },
                },
                "program-group": {
                  data: {
                    id: "98",
                    type: "program-groups",
                  },
                },
              },
            },
          ],
        });
      }),
      http.get("/api/v1/*program-groups", (_request, _params, _cookies) => {
        return HttpResponse.json({
          data: [
          {
            id: "96",
            type: "program-groups",
            attributes: {
              id: 96,
              "client-id": 134,
              description: "Healthcare Management",
              "created-at": "2021-04-05T15:05:34.492Z",
              "updated-at": "2021-04-05T15:05:34.492Z",
            },
            relationships: {
              client: { data: { id: "134", type: "clients" } },
            },
          },
          {
            id: "97",
            type: "program-groups",
            attributes: {
              id: 97,
              "client-id": 134,
              description: "Master's Programs",
              "created-at": "2021-04-05T15:05:34.542Z",
              "updated-at": "2021-04-05T15:05:34.542Z",
            },
            relationships: {
              client: { data: { id: "134", type: "clients" } },
            },
          },
          ],
        });
      }),
    ],
  },
};
