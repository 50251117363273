import { useMemo } from 'react'
import DatePickerPopover from './DatePickerPopover'
import DateRangeIcon from '@mui/icons-material/DateRange';
import Typography from '@mui/material/Typography'

import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { getTimeAndDate, isMidnight } from 'lib/time';

const timeAndDateProps = {
  timeFormat: 'hA',
  dateFormat: 'MMM D',
  useReferentialNames: false,
}

export default function DatePickerInput({value, ...props}) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'dateRange' })
  const formattedValue = useMemo(() => value ? [getTimeAndDate(value[0], 'start', timeAndDateProps), getTimeAndDate(isMidnight(value?.[1]) ? value[1] : value[1].clone().add(1, 'second'), 'end', timeAndDateProps)].join(' - ') : 'Lifetime', [value])

  return (
    <>
      <Button variant='text' aria-label="Select Date Range" {...bindTrigger(popupState)} sx={{ p: '2px', m: '4px !important', borderRadius: '3px' }}>
        <Stack direction='row' gap={1} sx={{ alignItems: 'center' }}>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap', ml: .5 }}>
            {formattedValue}
          </Typography>
          <DateRangeIcon />
        </Stack>
      </Button>

      <DatePickerPopover
        {...bindPopover(popupState)}
        value={value}
        {...props}
      />
    </>
  )
}
